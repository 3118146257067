import CargoFormSchema from "../cargo/CargoFormSchema";
import CompanyFormSchema from "../company/CompanyFormSchema";
import FuncionarioFormSchema from "../pessoas/FuncionarioFormSchema";
import PoliticaFormSchema from "../politicas/PoliticaFormSchema";
import UserFormSchema from "../user/UserFormSchema";
import ProcessoFormSchema from "../processo/ProcessoFormSchema";
import SetorFormSchema from "../setor/SetorFormSchema";
import UserPerfilFormSchema from "../user-perfil/UserPerfilFormSchema";
import ResponsavelEmpresaFormSchema from "../responsavel-empresa/ResponsavelEmpresaFormSchema";
import QuestionarioFormSchema from "../questionario/QuestionarioFormSchema";
import CampanhaFormSchema from "../campanhas/CampanhaFormSchema";
import PerguntasFormSchema from "../perguntas/PerguntasFormSchema";
import IncidentesFormSchema from "../incidentes/IncidenteFormSchema";
import CursoFormSchema from "../cursos/CursoFormSchema";
import TreinamentoFormSchema from "../treinamento/TreinamentoFormSchema";
import RelatorioFormSchema from "../relatorio-page/RelatorioFormSchema";
import TipoProcessoFormSchema from "../tipo-processo/TipoProcessoFormSchema";
import TipoArmazenamentoFormSchema from "../tipo-armazenamento/TipoArmazenamentoFormSchema";
import SistemasFormSchema from "../sistemas/SistemasFormSchema";
import TipoSegurancaFormSchema from "../seguranca/TipoSegurancaFormSchema";
import FornecedoresParceirosFormSchema from "../fornecedores-parceiros/FornecedoresParceirosFormSchema";
import ContratosVigentesFormSchema from "../contratos-vigentes/ContratosVigentesFormSchema";
import ArquivoFormSchema from "../arquivos/ArquivoFormSchema";
import GestaoRiscoFormSchema from "../gestao-risco/GestaoRiscoFormSchema";
import CertificadoTemplateFormSchema from "../certificados/CertificadoTemplateFormSchema";
import TipoPoliticaFormSchema from "../tipo-politica/TipoPoliticaFormSchema";
import CategoriaFormSchema from "../categoria/CategoriaFormSchema";
import ProcedimentoFormSchema from "../procedimentos/ProcedimentoFormSchema";
import ContatoSuporteFormSchema from "../mensagem/ContatoSuporteFormSchema";
import DireitosDoTitularFormSchema from "../direito-dos-titulares/DireitosDoTitularFormSchema";
import BaseLegalFormSchema from "../base-legal/BaseLegalFormSchema";
import DadosSensiveisFormSchema from "../dados-pessoais/DadosSensiveisFormSchema";
import FonteDeDadosFormSchema from "../fonte-de-dados/FonteDeDadosFormSchema";
import ProrrogacaoFormSchema from "../prorrogacao/ProrrogacaoFormSchema";
import {EncaminharSolicitacaoFormSchema} from "../lai/solicitacao/EncaminharSolicitacaoFormSchema";
import ModeloProrrogacaoFormSchema from "../modelo-prorrogacao/ModeloProrrogacaoFormSchema";
import ComentarioFormSchema from "../lai/comentario/ComentarioFormSchema";
import TemplateBaseLegalFormSchema from "../templates/template-base-legal/TemplateBaseLegalFormSchema";
import TemplateDireitosDoTitularFormSchema from "../templates/template-direito-dos-titulares/TemplateDireitosDoTitularFormSchema";
import TemplateFonteDeDadosFormSchema from "../templates/template-fonte-de-dados/TemplateFonteDeDadosFormSchema";

const entitiesSchemas = {
  'bases-legais': BaseLegalFormSchema,
  'direitos-dos-titulares': DireitosDoTitularFormSchema,
  'contato-suportes': ContatoSuporteFormSchema,
  'procedimentos': ProcedimentoFormSchema,
  'categorias': CategoriaFormSchema,
  'contratos-vigentes': ContratosVigentesFormSchema,
  'fornecedores-parceiros': FornecedoresParceirosFormSchema,
  'cursos': CursoFormSchema,
  'treinamentos': TreinamentoFormSchema,
  'entidades': CompanyFormSchema,
  'users': UserFormSchema,
  'users-perfis': UserPerfilFormSchema,
  'cargos': CargoFormSchema,
  'policies': PoliticaFormSchema,
  'funcionarios': FuncionarioFormSchema,
  'processos': ProcessoFormSchema,
  'setores': SetorFormSchema,
  'responsaveis-empresas': ResponsavelEmpresaFormSchema,
  'questionarios': QuestionarioFormSchema,
  'campanhas': CampanhaFormSchema,
  'perguntas': PerguntasFormSchema,
  'incidentes': IncidentesFormSchema,
  'relatorios': RelatorioFormSchema,
  'tipo-processos': TipoProcessoFormSchema,
  'dados-sensiveis': DadosSensiveisFormSchema,
  'tipo-armazenamento': TipoArmazenamentoFormSchema,
  'sistemas': SistemasFormSchema,
  'tipo-seguranca': TipoSegurancaFormSchema,
  'arquivos': ArquivoFormSchema,
  'gestao-risco': GestaoRiscoFormSchema,
  'certificado-template': CertificadoTemplateFormSchema,
  'tipo-politica': TipoPoliticaFormSchema,
  'fonte-de-dados': FonteDeDadosFormSchema,
  'modelos-prorrogacoes': ModeloProrrogacaoFormSchema,
  'prorrogacoes': ProrrogacaoFormSchema,
  'encaminhar-solicitacoes' : EncaminharSolicitacaoFormSchema,
  'solicitacao-comentario': ComentarioFormSchema,
  'templates-bases-legais': TemplateBaseLegalFormSchema,
  'templates-direitos-dos-titulares': TemplateDireitosDoTitularFormSchema,
  'templates-fonte-de-dados':  TemplateFonteDeDadosFormSchema
};

export const DefaultFormSchema = {

  getTitle(isNew) {
    return isNew ? 'Novo' : 'Editar';
  },

  getUrlFindById: null,

  formatObjectToGet(formData) {
    return formData;
  },

  formatObjectToSave(formData) {
    return formData;
  },

  validate(formData, errors) {
    return errors;
  },

  getSchema() {
    return ({})
  },

  getUiSchema() {
    return {};
  },

  getFields() {
    return {
      TitleField: ({title, required}) => {
        return '';
      }
    };
  }
}

export const getEntityFormSchema = (entityName) => {
  const result = entitiesSchemas[entityName];
  if (!result) {
    throw new Error('Entity not found' + entityName);
  }
  return result;
}
