import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const TRANSMISSION_TYPES = [
  {id: 'FISICO', label: 'FISICO'},
  {id: 'DIGITAL', label: 'DIGITAL'},
  {id: 'AMBOS', label: 'AMBOS'}
];

const MeioTransmissaoSelector = ({
  required = false,
  defaultValue,
  title = "Meio de Transmissao",
  setValue = null,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="id"
    setValue={setValue}
    items={TRANSMISSION_TYPES}
    {...props}
  />

}

export default MeioTransmissaoSelector;
