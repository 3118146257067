import {Link} from "react-router-dom";

export const MenuBox = ({
  to,
  size = "lg",
  icon,
  name
}) => {
  return (
    <Link to={to}>
      <div className={`p-4 small-box btn-primary d-flex flex-column align-items-center h-75`} size={size}>
        <div>
          <i className={'fa ' + icon}/>
        </div>
        <p>{name}</p>
      </div>
    </Link>
  )
}
