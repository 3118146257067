import React from 'react'
import moment from 'moment'
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

function DateRangeSelector({
  onChange,
  defaultStartDate,
  defaultEndDate,
  timePicker = true
}) {

  moment.locale('pt-br')

  const ranges = {
    'Limpar o filtro': [
      moment('01.01.2020 00:00', 'L'),
      moment().toDate()
    ],
    Hoje: [
      moment().toDate(),
      moment().toDate()
    ],
    Ontem: [
      moment().subtract(1, 'days').toDate(),
      moment().subtract(1, 'days').toDate(),
    ],
    'Últimos 7 dias': [
      moment().subtract(7, 'days').toDate(),
      moment().toDate(),
    ],
    'Últimos 30 dias': [
      moment().subtract(29, 'days').toDate(),
      moment().toDate(),
    ],
    'Este mês': [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate(),
    ],
    'Último mês': [
      moment().subtract(1, 'months').startOf('month'),
      moment().subtract(1, 'months').endOf('month'),
    ],
  }

  const daysOfWeek = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab']

  const monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago',
    'Set', 'Out', 'Nov', 'Dez']

  return (
    <DateRangePicker
      onCallback={(startDate, endDate) => onChange && onChange(
        startDate.toDate(), endDate.toDate())}
      initialSettings={{
        timePicker: timePicker,
        ranges,
        locale: {
          format: 'DD/MM/YYYY',
          "applyLabel": "Confirmar",
          "cancelLabel": "Cancelar",
          customRangeLabel: 'Selecione a data',
          daysOfWeek,
          monthNames,
        },
        startDate: defaultStartDate,
        endDate: defaultEndDate
      }}
    >
      <input type="text" className="form-control"/>
    </DateRangePicker>
  )
}

export {DateRangeSelector}
