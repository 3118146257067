import {useEffect, useState} from "react";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {SmallBox} from "../../components";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const CertificadosCount = () => {

  const [total, setTotal] = useState('-');

  const loadTotal = async () => {
    try {
      const result = await HttpRequest.get('optima-lgpd/v1/qualificacoes/count');
      setTotal(result.total);
    } catch (e) {
      Notify.error('Falha ao contar políticas', e);
    }
  }

  useEffect(() => {
    loadTotal();
  }, [])

  return <SmallBox
    type="primary"
    icon="fa fa-graduation-cap"
    count={total} title="Treinamentos e Capacitações Disponíveis"
    titlebox={"Treinamento"}
    navigateTo={ROUTE_PATH.CERTIFICADO_LIST}/>

}

export default CertificadosCount;
