import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";

const TipoProcessoListSchema = () => {
  const endpoint = 'optima-lgpd/v1/tipo-processos';
  return {
    title: 'Tipo de Processos',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.TIPO_PROCESSO_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.TIPO_PROCESSO_FORM
        })
      ]
    },

  }
}

export default TipoProcessoListSchema;

