import {Page} from "../../components/page/Page";
import {Card, Col, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {getAllMenuByUser} from "../../shared/routes/Menus";
import {MenuBox} from "../../components/menu-box/MenuBox";

const MenuListPage = ({match}) => {

  const menusApp = getAllMenuByUser();
  const [menus, setMenus] = useState({children: []});

  useEffect(() => {
    if (menusApp.filter(x => x.path === match.path)[0] === undefined) {
      menusApp.forEach(z => {
          if (z.children.length > 0) {
            z.children.forEach(k => {
              if (k.path === match.path) {
                console.log(k);
                setMenus(k);
              }
            })
          }
        }
      );
    } else {
      const data = menusApp.filter(x => x.path === match.path)[0];
      setMenus(data);
    }
  }, [match.path]);

  console.log('data', menus)
  return (<Page title={menus.name}>
    <Card>
      <Card.Body>
        <Row className='justify-content-center'>
          {menus?.children?.map(x => {
            return (
              <Col md={2}>
                <MenuBox name={x.name} icon={x.icon} to={x.path} className='small-box btn-primary'>
                </MenuBox>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  </Page>)

}

export default MenuListPage;
