import SetorSelector from "../setor/component/SetorSelector";
import PoliticaSelector from "../politicas/component/PoliticaSelector";
import {FileSchemaForm} from "../../shared/common/FileSchemaForm";
import TipoProcessoSelector from "../tipo-processo/componenet/TipoProcessoSelector";
import TipoArmazenamentoSelector from "../tipo-armazenamento/componenet/TipoArmazenamentoSelector";
import TipoSistemaSelector from "../sistemas/component/TipoSistemaSelector";
import TipoSegurancaSelector from "../seguranca/component/TipoSegurancaSelector";
import MeioTransmissaoSelector, {TRANSMISSION_TYPES} from "./component/MeioTransmissaoSelector";
import Editor from "../../components/editor/Editor";
import DireitosDoTitularSelector from "../direito-dos-titulares/component/DireitosDoTitularSelector";
import BaseLegalSelector from "../base-legal/component/BaseLegalSelector";
import DadosPessoaisSelector from "../dados-pessoais/component/DadosPessoaisSelector";
import FonteDeDadosSelector from "../fonte-de-dados/component/FonteDeDadosSelector";
import ArquivoSelector from "../arquivos/component/ArquivoSelector";
import LocaisArmazenamentoSelector from "../../locais-de-armazenamento/component/LocaisArmazenamentoSelector";

const ProcessoFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Fluxo de Dados' : 'Editar Fluxo de Dados';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/processos/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/processos/${id || ''}`;
    },

    formatObjectToGet(entity) {
      if (!entity.meioTransmissao) {
        return entity;
      }
      entity.meioTransmissao = entity.meioTransmissao.map(x => {
        return TRANSMISSION_TYPES.filter(y => y.id === x)[0];
      });

      return entity;
    },

    formatObjectToSave(entity) {
      entity.processoType = 'PROCESSO';
      entity.meioTransmissao = entity.meioTransmissao?.map(x => x.label);
      return entity;
    },

    getSchema() {
      return {
        "definitions": {
          "Arquivos": FileSchemaForm,
        },
        title: "Fluxo de Dados",
        type: "object",
        required: [
          "nome",
          "setor",
          "durationInMounths"
        ],
        properties: {
          setor: {
            type: 'object',
            properties: {
              id: {type: "string", title: "Setor"},
            }
          },
          nome: {type: "string", title: "Nome"},
          fontes: {
            title: "Fontes",
            type: 'array',
            minItems: 0,
            items: {
              type: 'object',
              required: ['dadosPessoais', 'fonteDeDados'],
              properties: {
                fonteDeDados: {
                  type: "object",
                  properties: {
                    id: {type: "string", title: "Fonte de dados"},
                  }
                },
                dadosPessoais: {
                  type: 'array',
                  items: {
                    type: "object",
                    properties: {
                      id: {type: "string", title: "Dados Pessoais Coletados"},
                    }
                  }
                },
              }
            }
          },
          proposito: {type: "string", title: "Propósito"},
          baseLegal: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                id: {type: "string", title: "Base Legal"},
              }
            }
          },
          tempoArmazenamento: {
            type: "string",
            title: "Tempo de armazenamento dos dados"
          },
          // procedimento: {type: "string", title: "Procedimento"},
          direitosTitulares: {
            type: 'array',
            "items": {
              type: "object",
              properties: {
                id: {type: "string", title: "Direito Dos Titulares"},
              }
            }
          },
          setorCompartilhaDados: {
            type: 'array',
            "items": {
              type: 'object',
              properties: {
                id: {type: "string", title: "Setores"},
              }
            }
          },
          compartilhaComBrasil: {
            type: "boolean",
            title: "Compartilha Informações no Brasil"
          },
          empresasBrasil: {type: "string", title: "Quais Empresas no Brasil?"},
          compartilharForaDoBrasil: {
            type: "boolean",
            title: "Compartilha Informações Fora do Brasil"
          },
          empresasForaBrasil: {
            type: "string",
            title: "Quais Empresas Fora Do Brasil?"
          },
          transfereForaBrasil: {
            type: "boolean",
            title: "Transfere esses dados para fora do Brasil?"
          },
          detalhesTransferencia: {
            type: "string",
            title: "Detalhes da Transferência dos Dados"
          },
          dadosCriancas: {
            type: "boolean",
            title: "Envolve dados pessoais de crianças?"
          },
          controleQualidade: {
            type: "boolean",
            title: "Possui controle de qualidade?"
          },
          auditoria: {type: "boolean", title: "Possui controle de auditoria?"},
          durationInMounths: {
            type: "integer",
            title: "Validade em Meses",
            minimum: 1
          },
          arquivos: {
            type: "array",
            title: "Arquivos",
            minItems: 0,
            items: {
              "$ref": "#/definitions/Arquivos"
            }
          },
          politicas: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Políticas"},
              }
            }
          },
          // tipoDeProcessos: {
          //   type: 'array',
          //   items: {
          //     type: "object",
          //     properties: {
          //       id: {type: "string", title: "Tipo de Processo"},
          //     }
          //   }
          // },
          meioTransmissao: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Meio de Transmissão"},
              }
            }
          },
          locaisArmazenamento: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Locais de Armazenamento"},
              }
            }
          },
          tipoArmazenamento: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Tipo de Armazenamento"},
              }
            }
          },
          sistemasEnvolvidos: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Tipo de Sistema"},
              }
            }
          },
          tipoDeSegurancas: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Tipo de Segurança"},
              }
            }
          },
          processoText: {
            type: "string",
            title: "Descrição do Processo"
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "fontes": {
          items: {
            "fonteDeDados": {
              "ui:help": "Origem dos dados pessoais. Por exemplo: WhatsApp, E-mail, Telefone, Documento digitalizado.",
              "ui:options": {
                label: false
              },
              "ui:field": (props) => {
                return (
                  <FonteDeDadosSelector disabled={props.disabled}
                                        required={true}
                                        title={props.schema.title}
                                        value={props.formData}
                                        setValue={props.onChange}/>
                );
              }
            },
            "dadosPessoais": {
              "ui:help": "Relacione todos os dados pessoais que são ou que podem estar relacionados com o processo/atividade. Por exemplo: CPF, Nome, Placa de Veículo, Tipagem Sanguínea, Foto, Gravação de vídeo, Altura, etc.",
              "ui:options": {
                label: false
              },
              "ui:field": (props) => {
                return (
                  <DadosPessoaisSelector disabled={props.disabled}
                                         required={props.required}
                                         title={props.schema.title}
                                         value={props.formData}
                                         setValue={props.onChange}/>
                );
              }
            }
          }
        },

        "nome": {
          "ui:help": "Nome do processo ou atividade que está sendo mapeada",
        },
        "fonte": {
          "ui:help": "Origem dos dados pessoais. Por exemplo: WhatsApp, E-mail, Telefone, Documento digitalizado."
        },
        "proposito": {
          "ui:help": "Qual o motivo dessa atividade."
        },
        "tempoArmazenamento": {
          "ui:help": "O tempo de permanência dos dados pessoais ficam armazenados. Caso haja armazenamentos diferentes, especificar o tempo de cada um dele."
        },
        "procedimento": {
          "ui:help": "Existe um procedimento documentado dessa atividade que explica passo a passo como executar o processo/atividade? Se sim, informe o nome do procedimento."
        },
        "compartilhaComBrasil": {
          "ui:help": "Existe o compartilhamento dos dados para outras empresas, órgãos, instituições, parceiros? Relacione todos os compartilhamentos possíveis."
        },
        "compartilharForaDoBrasil": {
          "ui:help": "Relacione as empresas que possam compartilhar os dados internacionalmente, por exemplo: empresa, serviço em nuvem ou outros tipos."
        },
        "detalhesTransferencia": {
          "ui:help": "Qual o motivo para a transferência de dados pessoais para fora do Brasil?"
        },
        "controleQualidade": {
          "ui:help": "Existe um processo formal e devidamente homologado de Controle de Qualidade?"
        },
        "auditoria": {
          "ui:help": "Existe um processo formal e devidamente homologado de Auditoria?"
        },
        "durationInDays": {
          "ui:help": "Descreva por quanto tempo esse processo/atividade deve ser mantido. Ao final, será necessário uma reavaliação para verificar se houveram alterações no processo/atividade."
        },
        "baseLegal": {
          "ui:help": "De acordo com a LGPD, é necessário uma base legal para justificar o uso dos dados pessoais.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<BaseLegalSelector isMulti={true}
                                       disabled={props.disabled}
                                       required={props.required}
                                       title={props.schema.title}
                                       value={props.formData}
                                       setValue={props.onChange}/>
            );
          }
        },
        "direitosTitulares": {
          "ui:help": "De acordo com a LGPD, existem direitos que precisam ser atendidos para o titular de dados. Dependendo da base legal, alguns direitos são obrigatórios e outros não.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<DireitosDoTitularSelector isMulti={true}
                                               disabled={props.disabled}
                                               required={props.required}
                                               title={props.schema.title}
                                               value={props.formData}
                                               setValue={props.onChange}/>
            );
          }
        },
        "setor": {
          "ui:autofocus": true,
          "ui:help": "Qual setor realiza este processo.",
          "ui:options": {

            label: false
          },
          "ui:field": (props) => {
            return (<SetorSelector disabled={props.disabled}
                                   required={props.required}
                                   title={"Setor"}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
        "setorCompartilhaDados": {
          "ui:help": "Quais setores que possuem compartilhamento desses dados.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<SetorSelector disabled={props.disabled}
                                   isMulti={true}
                                   required={props.required}
                                   title={"Setores compartilhados"}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },

        "arquivos": {
          // "ui:options": {
          //   label: false
          // },
          "items": {
            "ui:field": (props) => {
              return (
                <ArquivoSelector disabled={props.disabled}
                                 required={props.required}
                                 title={props.schema.title}
                                 value={props.formData}
                                 setValue={props.onChange}/>
              );
            }
          }
        },
        "politicas": {
          "ui:help": "Informe as Políticas relacionadas com esse processo/atividade",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PoliticaSelector isMulti={true} disabled={props.disabled}
                                      required={props.required}
                                      title={props.schema.title}
                                      value={props.formData}
                                      setValue={props.onChange}/>
            );
          }
        },
        "tipoDeProcessos": {
          "ui:help": "O tipo de processo auxilia no agrupamento para processos da mesma categoria.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (
              <TipoProcessoSelector isMulti={true} disabled={props.disabled}
                                    required={props.required}
                                    title={props.schema.title}
                                    value={props.formData}
                                    setValue={props.onChange}/>
            );
          }
        },
        "locaisArmazenamento": {
          "ui:help": "Quais locais serão armazenados estes dados, exemplo: onedrive, dropbox, etc.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<LocaisArmazenamentoSelector isMulti={true}
                                               disabled={props.disabled}
                                               required={props.required}
                                               title={props.schema.title}
                                               value={props.formData}
                                               setValue={props.onChange}/>
            );
          }
        },
        "tipoArmazenamento": {
          "ui:help": "Se o armazenamento é em pastas, arquivos, armários, gavetas, banco de dados, etc.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<TipoArmazenamentoSelector isMulti={true}
                                               disabled={props.disabled}
                                               required={props.required}
                                               title={props.schema.title}
                                               value={props.formData}
                                               setValue={props.onChange}/>
            );
          }
        },
        "meioTransmissao": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<MeioTransmissaoSelector isMulti={true}
                                             disabled={props.disabled}
                                             required={props.required}
                                             title={props.schema.title}
                                             value={props.formData}
                                             setValue={props.onChange}/>
            );
          }
        },
        "sistemasEnvolvidos": {
          "ui:help": "Informar a ferramenta utilizada para o cadastro, armazenamento, consulta, edição. Pode ser por exemplo: CRM, ERP, Drive, Astrea, etc.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (
              <TipoSistemaSelector isMulti={true} disabled={props.disabled}
                                   required={props.required}
                                   title={props.schema.title}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
        "tipoDeSegurancas": {
          "ui:help": "Se há criptografia, controle de acesso físico, lógico, de ambiente, etc.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (
              <TipoSegurancaSelector isMulti={true} disabled={props.disabled}
                                     required={props.required}
                                     title={props.schema.title}
                                     value={props.formData}
                                     setValue={props.onChange}/>
            );
          }
        },
        "processoText": {
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title={props.schema.title}
                initialValue={props.formData}
                onChange={props.onChange}
                initProps={{
                  placeholder: "Descreva o processo do início ao fim, ou seja, como ela inicia, quais os passos da"
                    + " atividade/processo e como é finalizado. Seja o mais detalhado possível."
                }}
              />
            );
          }
        }
      }
    }
  }
}

export default ProcessoFormSchema;

