import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './shared/redux/store'
import {Provider} from "react-redux";
import {ThemeSelector} from "./thema/ThemeSelector";
import LocalServiceWorkerRegister from "./serviceWorkerRegistration";

window.localStorage['cflow_redirect'] = window.location.href;
const myStore = store();
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={myStore}>
    <ThemeSelector>
      <App/>
    </ThemeSelector>
  </Provider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);
LocalServiceWorkerRegister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
