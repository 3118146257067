import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const CONTAINS_DATA_KIDS = [
  {id: 'true', label: 'SIM'},
  {id: 'false', label: 'NÃO'},
];

const DadosCriancasSelector = ({
  required = false,
  defaultValue,
  title = "Possui dados pessoais de crianças?",
  setValue = null,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={CONTAINS_DATA_KIDS}
    {...props}
  />

}

export default DadosCriancasSelector;
