const SistemasFormSchema = () => {
  return {
    getTitle(isNew) {
      return isNew ? 'Novo Tipo de Sistema' : 'Editar Tipo de Sistema';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/sistemas/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/sistemas/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Tipo de Sistema",
        type: "object",
        required: ["nome"],
        properties: {
          nome: {type: "string", title: "Nome"},
        }
      };
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
      }
    }
  }
}

export default SistemasFormSchema;

