const TipoProcessoFormSchema = () => {
  return {
    getTitle(isNew) {
      return isNew ? 'Novo Tipo de Processo' : 'Editar Tipo de Processo';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/tipo-processos/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/tipo-processos/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Tipos de Processo",
        type: "object",
        required: ["nome"],
        properties: {
          nome: {type: "string", title: "Nome"},
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
        }
      }
    }
  }
}

export default TipoProcessoFormSchema;

