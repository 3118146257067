import {Button, Card, Col, Row, Tab} from "react-bootstrap";
import {dayMonthYearAtTime} from "../../../../shared/utils/dates";
import {formatByIdAndMatch, ROUTE_PATH} from "../../../../shared/routes/RoutesPath";
import {Link} from "react-router-dom";

export const ComentarioTab = ({eventKey, data}) => {

  return <Tab.Pane eventKey={eventKey} key={eventKey}>
    <Card>
      <Card.Body>
        <ComentarioList data={data} comentarios={data.comentarios}/>
      </Card.Body>
    </Card>
  </Tab.Pane>
}

export const ComentarioList = ({data, comentarios}) => {
  return <>
    <Row className="p-2 mt-3 border-bottom">
      <Col>
        <h5>Comentários</h5>
      </Col>
      <Col className="text-right">
        <Link to={formatByIdAndMatch({id: data.id}, ROUTE_PATH.SOLICITACAO_COMENTARIO)}>
          <Button title="Fazer um comentário">Comentar</Button>
        </Link>
      </Col>
    </Row>
    <Row className="p-2 mt-3 border-bottom bg-light">
      <Col>
        Descrição
      </Col>
      <Col sm={2}>
        Usuário
      </Col>
      <Col sm={2}>
        Data
      </Col>
      <Col sm={2}>
        Privado?
      </Col>
    </Row>
    {!comentarios && <h5 className="text-center mt-3  border-bottom p-2">Sem comentários</h5>}
    {
      comentarios?.map((x, i) => <ComentarioItem key={i} x={x}/>)
    }
  </>
}

const ComentarioItem = ({x}) => {
  return <Row className="p-2 mt-3 border-bottom">
    <Col>
      <p dangerouslySetInnerHTML={{__html: x.content}}/>
    </Col>
    <Col sm={2}>
      {x.createdBy}
    </Col>
    <Col sm={2}>
      {dayMonthYearAtTime(x.when)}
    </Col>
    <Col sm={2}>
      {x.private ? 'Privado' : 'Público'}
    </Col>
  </Row>
}

