import {Col, Container, Row} from "react-bootstrap";
import {Breadcrumbs} from "../breadcrumbs/Breadcrumbs";

export const Page = ({title, children}) => {

  return (
    <>
      <div className="content-header">
        <div className="container-fluid">
          <Row className="mb-2">
            <Col sm={6}>
              <h1 className="m-0">{title}</h1>
            </Col>
            <Col sm={6}>
              <Breadcrumbs/>
            </Col>
          </Row>
        </div>
      </div>

      <div className="content">
        <Container fluid={true}>
          {children}
        </Container>
      </div>

    </>
  )
}
