const UserFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Usuário' : 'Editar Usuário';
    },

    actionPostSave(history, entity) {
      history.replace(`/cadastros/list/users-perfis/form/${entity.id}`)
    },

    getUrlFindById(id) {
      return `optima-auth/v1/integration-users/${id}`;
    },

    getSaveUrl(id) {
      return `optima-auth/v1/users/${id || ''}`;
    },

    formatObjectToSave(formData) {
      return formData;
    },

    formatObjectToGet(formData) {
      return formData;
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
          "ui:placeholder": "Fernando da Silva",
        },
        "email": {
          "ui:help": "E-mail usado para acessar o sistema e receber informações",
          "ui:placeholder": "exemplo@email.com"
        },
      }
    },

    getSchema() {
      return {
        title: "Empresa",
        type: "object",
        required: ["name", "email"],
        properties: {
          name: {type: "string", title: "Nome"},
          email: {type: "string", title: "E-mail", "format": "email"},
        },
      };
    }

  }
}

export default UserFormSchema;

