import {Card, Col, Row, Tab} from "react-bootstrap";
import {dayMonthYearAtTime} from "../../../../shared/utils/dates";

const HistoricoItem = ({x}) => {
  return <Row className="p-2 mt-3 border-bottom">
    <Col>
      {x.description}
    </Col>
    <Col sm={2}>
      {x.createdBy}
    </Col>
    <Col sm={2}>
      {dayMonthYearAtTime(x.when)}
    </Col>
  </Row>
}
export const HistoricoList = ({historicos}) => {
  console.log('historicos', historicos);
  return <>
    <Row className="p-2 mt-3 border-bottom bg-light">
      <Col>
        Descrição
      </Col>
      <Col sm={2}>
        Usuário
      </Col>
      <Col sm={2}>
        Data
      </Col>
    </Row>
    {
      historicos?.map(x => <HistoricoItem key={x.modified} x={x}/>)
    }
  </>
}
