import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {useCallback} from "react";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const UserSelector = ({
  required = false,
  defaultValue,
  title = "Usuário",
  setValue = null,
  ...props
}) => {

  const getUrl = useCallback((filterValue) => {
    return `optima-auth/v1/users?sort=name,ASC&name=${filterValue}`
  }, []);

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    pathToCreate={ROUTE_PATH.USER_FORM}
    {...props}
  />

}

export default UserSelector;
