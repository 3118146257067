import {dayMonthYearAtTime} from "../../shared/utils/dates";
import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {OrganogramaButton} from "./component/OrganogramaButton";
import {DateTimeRangeColumnFilter} from "../../components/table/TableFilter";

const CargoListSchema = () => {

  const endpoint = 'optima-lgpd/v1/cargos';

  return {
    title: 'Lista de Cargos',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.CARGO_FORM,
        customButton: () => {
          return <OrganogramaButton to={ROUTE_PATH.ORGANOGRAMA_CARGO_PAGE} children={"Organograma"}/>
        },
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        {
          Header: "Criado em",
          accessor: "createdAt",
          Filter: DateTimeRangeColumnFilter,
          Cell: ({row}) => row.original.createdAt && dayMonthYearAtTime(row.original.createdAt)
        },
        OptionColumn({endpointToDelete: endpoint, editTo: ROUTE_PATH.CARGO_FORM})
      ]
    },

  }
}

export default CargoListSchema;

