import React, {useEffect, useState} from 'react'
import {Loading} from "../loading/Loading";
import {ListGroup} from "react-bootstrap";

export default function CustomSelect({
  isLoading = true,
  availables = [],
  selecteds = [],
  label = 'name',
  fieldId = 'id',
  setValue
}) {

  const [_avaliables, setAvaliables] = useState(availables);

  const addOrRemoveItem = (item) => {
    _avaliables.forEach(x => {
      if (x.id === item.id) {
        item.selected = !item.selected;
      }
    });
    setAvaliables([..._avaliables]);
    setValue && setValue(_avaliables.filter(x => x.selected));
  }

  useEffect(() => {

    const idsSelected = selecteds.map(x => x[fieldId]);

    availables.forEach(x => {
      const id = x[fieldId];
      x.selected = idsSelected.includes(id);
      x.id = x[fieldId];
      x.label = x[label];
    });
    setAvaliables(availables)
  }, [availables, selecteds, fieldId, label])

  return (
    <>
      <Loading isLoading={isLoading}/>
      <ListGroup>
        {
          _avaliables.map((x) => {
            const {id, label, selected} = x;
            return (
              <ListGroup.Item action={true} key={id + selected}
                              active={selected}
                              onClick={(e) => {
                                e.preventDefault();
                                addOrRemoveItem(x)
                              }}
              >
                <h5>{label}</h5>
              </ListGroup.Item>
            )
          })
        }
      </ListGroup>
    </>
  )
}
