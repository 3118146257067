import ArquivoSelector from "../arquivos/component/ArquivoSelector";
import Editor from "../../components/editor/Editor";

const CertificadoTemplateFormSchema = () => {
  return {

    getTitle() {
      return 'Editar template';
    },

    getUrlFindById() {
      return `optima-lgpd/v1/template-certificados/`;
    },

    getSaveUrl() {
      return `optima-lgpd/v1/template-certificados/1`;
    },

    getSchema() {
      return {
        title: "Cargo",
        type: "object",
        required: ["logo", "assinaturas", "template"],
        properties: {
          logo: {
            type: 'object',
            title: "Logo",
            properties: {
              id: {type: "string", title: "Logo"},
            }
          },
          assinaturas: {
            type: 'array',
            title: "Assinaturas",
            items: {
              type: 'object',
              properties: {
                id: {type: "string", title: "Assinaturas"},
              }
            }
          },
          template: {
            type: "string",
            title: "Modelo do template"
          }
        }
      };
    },

    getUiSchema: () => {
      return {
        "assinaturas": {
          "ui:field": (props) => {
            return (
              <ArquivoSelector  {...props}
                                disabled={props.disabled} isMulti={true}
                                required={props.required}
                                title={props.schema.title}
                                value={props.formData}
                                setValue={props.onChange}
              />
            );
          }
        },
        "logo": {
          "ui:field": (props) => {
            return (
              <ArquivoSelector  {...props}
                                disabled={props.disabled}
                                required={props.required}
                                title={props.schema.title}
                                value={props.formData}
                                setValue={props.onChange}
              />
            );
          }
        },
        "template": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title='Modelo do template'
                initialValue={props.formData}
                onChange={props.onChange}
              />
            );
          }
        }
      }
    }
  }
}

export default CertificadoTemplateFormSchema;

