import {BaseSelectorReadonlyToInputV2} from "../../../../components";

export const SOLICITACAO_STATUS = [
  {id: 'NOVO', label: 'Novo'},
  {id: 'EM_ANDAMENTO', label: 'Em Andamento '},
  {id: 'FINALIZADO', label: 'Finalizado '}
];

export const SolicitacaoStatus = ({status}) => {
  return <p>{SOLICITACAO_STATUS.filter(x => x.id === status)[0]?.label}</p>
}

const SolicitacaoStatusSelector = ({
  required = false,
  title = "Tipo de Pessoa",
  setValue = null,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={SOLICITACAO_STATUS}
    placeholder="Selecione..."
    {...props}
  />

}

export default SolicitacaoStatusSelector;
