import SetorSelector from "../setor/component/SetorSelector";
import ProcessosSelector from "../processo/component/ProcessoSelector";
import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";
import Editor from "../../components/editor/Editor";
import CategoriaSelector from "../categoria/componenet/CategoriaSelector";

const GestaoRiscoFormSchema = () => {
  return {
    getTitle(isNew) {
      return isNew ? 'Nova Gestão de Risco' : 'Editar Gestão de Risco';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/gestao-risco/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/gestao-risco/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Gestão de Risco",
        type: "object",
        required: [
          "descricao",
        ],
        properties: {
          descricao: {type: "string", title: "Descrição"},
          detalhamento: {type: "string", title: "Detalhamento"},
          setor: {
            type: 'object',
            properties: {
              id: {type: "string", title: "Setor"},
            }
          },
          processo: {
            type: 'object',
            properties: {
              id: {type: "string", title: "Setor"},
            }
          },
          risco: {
            title: "Risco",
            "type": "number",
            "enum": [
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10
            ]
          },
          impacto: {
            "title": "Impacto",
            "type": "number",
            "enum": [
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10
            ]
          },
          planoAcao: {type: "string", title: "Plano de ação para mitigação"},
          funcionario: {
            type: 'object',
            title: "Responsável",
            properties: {
              id: {type: "string", title: "Responsável"},
            }
          },
          dataPlanejamento: {
            "title": "Data de Planejamento",
            "type": "string",
            "format": "date"
          },
          dataRegistro: {
            "title": "Data de Registro",
            "type": "string",
            "format": "date"
          },
          status: {type: "string", title: "Status"},
          dataAplicacao: {
            "title": "Data de Aplicação",
            "type": "string",
            "format": "date"
          },
          riscoResidual: {type: "string", title: "Há Risco Residual?"},
          nivelPosTratamento: {type: "string", title: "Nível pós tratamento"},
          categorias: {
            type: 'object',
            properties: {
              id: {type: "string", title: "Responsável"},
            }
          },
        }
      }
    },

    getUiSchema: () => {
      return {
        "ui:order": [
          "descricao",
          "detalhamento",
          "dataRegistro",
          "setor",
          "processo",
          "risco",
          "impacto",
          "classificacao",
          "planoAcao",
          "categorias",
          "funcionario",
          "dataPlanejamento",
          "status",
          "dataAplicacao",
          "riscoResidual",
          "nivelPosTratamento",
          "*"

        ],
        "descricao": {
          "ui:autofocus": true,
          "ui:help": "Descrição breve da Gestão de Risco",
        },
        "risco": {
          "ui:help": "Nível de Risco",
        },
        "impacto": {
          "ui:help": "Nível de Impacto",
        },
        "detalhamento": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (
              <Editor
                title={props.schema.title}
                initialValue={props.formData}
                onChange={props.onChange}
                initProps={{placeholder: "Detalhe como será implementada a gestão de risco."}}
              />
            );
          }
        },
        "setor": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<SetorSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
        "processo": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<ProcessosSelector disabled={props.disabled}
                                       required={props.required}
                                       title={props.title}
                                       value={props.formData}
                                       setValue={props.onChange}/>
            );
          }
        },
        "planoAcao": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (
              <Editor
                title={props.schema.title}
                initialValue={props.formData}
                onChange={props.onChange}
                initProps={{placeholder: "Informe o plano para mitigar ou coibir o risco em questão."}}
              />
            );
          }
        },
        "categorias": {
          "ui:field": (props) => {
            return (<CategoriaSelector disabled={props.disabled}
                                       required={props.required}
                                       title={props.title}
                                       value={props.formData}
                                       setValue={props.onChange}/>)
          }
        },
        "funcionario": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<FuncionarioSelector disabled={props.disabled}
                                         required={props.required}
                                         title={props.schema.title}
                                         value={props.formData}
                                         setValue={props.onChange}/>
            );
          }
        },
        "riscoResidual": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title={props.schema.title}
                initialValue={props.formData}
                onChange={props.onChange}
              />
            );
          }
        },
        "nivelPosTratamento": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title={props.schema.title}
                initialValue={props.formData}
                onChange={props.onChange}
              />
            );
          }
        },
      }
    }
  }
}

export default GestaoRiscoFormSchema;

