import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";
import {dayMonthYearAtTime} from "../../shared/utils/dates";

const QuestionarioListSchema = () => {

  const endpoint = 'optima-lgpd/v1/questionarios';
  return {
    title: 'Lista de Questionários',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.QUESTIONARIO_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        {
          Header: "Criado em",
          Cell: ({row}) => row.original.createdAt && dayMonthYearAtTime(row.original.createdAt)
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.QUESTIONARIO_FORM
        })
      ]
    },

  }
}

export default QuestionarioListSchema;

