import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const FornecedoresParceirosListSchema = () => {
  const endpoint = 'optima-lgpd/v1/fornecedores-parceiros';
  return {
    title: 'Fornecedores e Parceiros',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.FORNECEDORES_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.FORNECEDORES_FORM
        })
      ]
    },

  }
}

export default FornecedoresParceirosListSchema;

