import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import OptionColumn from "../../../components/table/OptionColumn";

const TemplateBaseLegalListSchema = () => {
  const endpoint = 'optima-lgpd/v1/template-bases-legais';
  return {
    title: 'Template Base Legal',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.TEMPLATE_BASE_LEGAL_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.TEMPLATE_BASE_LEGAL_FORM
        })
      ]
    },

  }
}

export default TemplateBaseLegalListSchema;