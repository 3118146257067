import RegistrationTypeSelector, {REGISTRATION_TYPES} from "../company/component/RegistrationTypeSelector";
import TipoPrestadorSelector, {TRANSMISSION_TYPES} from "./component/TipoPrestadorSelector";
import Editor from "../../components/editor/Editor";
import {InputMaskedRegistrationNumber} from "../../components/form/InputMaskedRegistrationNumber";

const FornecedoresParceirosFormSchema = () => {
  return {
    getTitle(isNew) {
      return isNew ? 'Novos Fornecedores' : 'Editar Fornecedores';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/fornecedores-parceiros/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/fornecedores-parceiros/${id || ''}`;
    },

    formatObjectToSave(entity) {
      entity.tipoPessoa = entity.tipoPessoa?.id;
      entity.tipoPrestador = entity.tipoPrestador?.label;
      return entity;
    },

    formatObjectToGet(entity) {
      entity.tipoPessoa === 'CPF' ? entity.tipoPessoa = REGISTRATION_TYPES[0]
        : entity.tipoPessoa = REGISTRATION_TYPES[1];
      entity.tipoPrestador === 'PARCEIRO'
        ? entity.tipoPrestador = TRANSMISSION_TYPES[0]
        : entity.tipoPrestador = TRANSMISSION_TYPES[1];
      return entity;
    },

    getUiSchema: () => {
      return {
        "ui:order": [
          "tipoPrestador",
          "nome",
          "tipoPessoa",
          "registrationNumber",
          "*"

        ],
        "nome": {},
        "descricao": {},
        "email": {},
        "registrationNumber": {
          "ui:help": "CPF ou CNPJ da empresa 000.000.000-00 ou 00.000.000/0000-00",
          "ui:field": (props) => {
            return (<InputMaskedRegistrationNumber id="registrationNumber" title={props.schema.title} mask={props.schema.mask} onChangeText={props.onChange} value={props.formData}/>
            );
          }
        },
        "tipoPessoa": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<RegistrationTypeSelector disabled={props.disabled}
                                              required={props.required}
                                              value={props.formData}
                                              setValue={props.onChange}/>
            );
          }
        },
        "tipoPrestador": {
          "ui:autofocus": true,
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<TipoPrestadorSelector
                disabled={props.disabled}
                required={props.required}
                title={props.title}
                value={props.formData}
                setValue={props.onChange}/>
            );
          }
        },
        "comentarios": {
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title={props.schema.title}
                id='uuid'
                tagName='div'
                textareaName='textareaName'
                apiKey="zdgf7vzscx2xm8tatqlzwqe1jkmgonohclm550fgc6zmh778"
                initialValue={props.formData}
                onChange={props.onChange}
                onSave={props => {
                  this.content = props
                }}
                init={{
                  powerpaste_allow_local_images: true,
                  image_advtab: true,
                  height: window.screen.height - 350,
                  width: '100%',
                  menubar: false,
                  plugins: ['print preview paste searchreplace  autosave save directionality visualblocks visualchars fullscreen image link media table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists imagetools textpattern noneditable charmap quickbars'],
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons |  preview save print | insertfile image template | ltr rtl',
                }}
              />
            );
          }
        },
      }
    },

    getSchema() {
      return {
        title: "Empresa",
        type: "object",
        required: ["nome"],
        properties: {
          tipoPrestador: {
            type: 'object',
            required: ["label"],
            properties: {
              label: {type: "string", title: "Tipo Prestador"},
            }
          },
          nome: {type: "string", title: "Nome do Fornecedor"},
          tipoPessoa: {
            type: "object",
            title: "Tipo de Pessoa",
            required: ["id"],
            properties: {
              id: {
                type: 'string',
                "default": REGISTRATION_TYPES[1].id,
              },
              label: {
                type: 'string',
                "default": REGISTRATION_TYPES[1].label,
              }
            }
          },
          email: {type: "string", title: "E-mail", format: 'email'},
          descricao: {type: 'string', title: "Descrição"},
          alinhamentoLGPD: {
            type: "boolean",
            title: "Realizado alinhamento LGPD?"
          },
          contratoRevisado: {
            type: "boolean",
            title: "Contrato Revisado?"
          },
          prioridadeTratamento: {
            type: 'string',
            title: "Prioridade do Tratamento"
          },
          aptoAuditoria: {
            type: "boolean",
            title: "Apto para auditoria?"
          },
          prioridadeAuditoria: {
            type: 'string',
            title: "Prioridade da auditoria"
          },
          dataPlanejada: {
            "title": "Data Planejada",
            "type": "string",
            "format": "date"
          },
          dataRealizacao: {
            "title": "Data Realizada",
            "type": "string",
            "format": "date"
          },
          comentarios: {
            type: "string",
            title: "Comentários"
          },
          dataInicioContrato: {
            "title": "Data inicio do contrato",
            "type": "string",
            "format": "date"
          },
          dataFimContrato: {
            "title": "Data fim do contrato",
            "type": "string",
            "format": "date"
          },

        },
        "dependencies": {
          "tipoPessoa": {
            "oneOf": [
              {
                "properties": {
                  "tipoPessoa": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_TYPES[0].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "0",
                    title: 'CPF'
                  }
                },
              },
              {
                "properties": {
                  "tipoPessoa": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_TYPES[1].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "1",
                    title: "CNPJ"
                  }
                },
              }
            ]
          }
        }
      };
    }
  }
};

export default FornecedoresParceirosFormSchema;

