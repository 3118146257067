import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {TableButton} from "./TableButton";

const EntityBlockButton = ({
  entity = "",
  endpointToBlock = "",
  tableProps = null,
  row = null,
  ...props
}) => {

  const blockOrUnblockEntity = async (id, tableProps, type) => {
    try {
      if (type === 'block') {
        await HttpRequest.put(`${endpointToBlock}/${id}/block`)
        Notify.success(`${entity} Bloqueado!`)
      } else {
        await HttpRequest.put(`${endpointToBlock}/${id}/unblock`)
        Notify.success(`${entity} Desbloqueado!`)
      }
      console.log('tableProps', tableProps);
      tableProps.setGlobalFilter(new Date());
    } catch (e) {
      console.log(e);
      Notify.error(`Falha ao bloquear/desbloquear ${entity}: `, e);
    }
  };

  return (
    <TableButton {...props} variant={row.original?.active ? 'danger' : 'success'} text={row.original?.active ? 'Bloquear' : 'Desbloquear'}
                 title={row.original?.active ? `Bloquear` : `Desbloquear`}
                 onClick={() => blockOrUnblockEntity(row.original.id, tableProps, row.original?.active ? 'block' : 'unblock')}
                 icon={row.original.active ? 'fas fa-lock' : 'fas fa-unlock'}/>)

}

export default EntityBlockButton;
