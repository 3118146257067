import {Button} from "react-bootstrap";

export const EditButton = ({
  to,
  title = 'Editar'
}) => {
  return (
    <Button href={`#${to}`} size="sm" title={title} variant="primary" block={true}>
      {title}
    </Button>
  )
}
