import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {dayMonthYear} from "../../shared/utils/dates";
import OptionColumn from "../../components/table/OptionColumn";
import {DateColumnFilter, DateRangeColumnFilter, SelectColumnFilter} from "../../components/table/TableFilter";
import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";

const ResponsavelEmpresaListSchema = () => {

  const endpoint = 'optima-lgpd/v1/responsaveis-empresas';

  return {
    title: 'Lista de responsáveis',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.RESPONSAVEL_EMPRESA_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Responsável",
          accessor: "responsavel.id",
          CustomSelector: FuncionarioSelector,
          Filter: SelectColumnFilter,
          filter: 'includes',
          Cell: ({row}) => row.original?.responsavel?.nome || null
        },
        {
          Header: "Inicío da vigência",
          accessor: "inicioVigencia",
          Filter: DateColumnFilter,
          Cell: ({row}) => `${dayMonthYear(row.original?.inicioVigencia)}`
        },
        {
          Header: "Fim da vigência",
          accessor: "fimDaVigencia",
          Filter: DateColumnFilter,
          Cell: ({row}) => `${dayMonthYear(row.original?.fimDaVigencia)}`
        },

        OptionColumn({
          endpointToDelete: endpoint,
          showEdit: false
        })
      ]
    },

  }
}

export default ResponsavelEmpresaListSchema;

