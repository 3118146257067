import React, {useEffect, useRef, useState} from 'react'
import {Diagram} from "@blink-mind/renderer-react";
import {Model} from "@blink-mind/core";

import {Loading} from '../loading/Loading';
import {useSelector} from "react-redux";

function Mindmap({data, isLoading}) {
  const [model, setModel] = useState(null)
  const mindMapRef = useRef()

  const {currentUser} = useSelector(({authState}) => ({
    currentUser: authState.currentUser,
  }));

  const mountModelByProvidedData = () => {
    let topics = [{
      key: 'root',
      subKeys: [...new Set(data.map(x => x.setor.id))],
      blocks: [{type: "CONTENT", data: currentUser.company.name}]
    }]

    data.forEach(firstLevel => {
      if (topics.filter(x => x.key === firstLevel.setor.id).length === 0) {
        topics.push({
          "key": firstLevel.setor.id,
          "parentKey": 'root',
          "subKeys": data.filter(x => x.setor.id === firstLevel.setor.id).map(x => firstLevel.setor.id + x.id) || [],
          "style": null,
          "blocks": [{
            "type": "CONTENT",
            "data": firstLevel.setor.nome
          }]
        })
      }

      topics.push({
        "key": firstLevel.setor.id + firstLevel.id,
        "parentKey": firstLevel.setor.id,
        "subKeys": firstLevel.fontes?.map(x => firstLevel.id + x.fonteDeDados.id) || [],
        "collapse": false,
        "style": null,
        "blocks": [{
          "type": "CONTENT",
          "data": firstLevel.nome
        }]
      })

      firstLevel.fontes?.forEach(secondLevel => {
        topics.push({
          "key": firstLevel.id + secondLevel.fonteDeDados.id,
          "parentKey": firstLevel.setor.id + firstLevel.id,
          "subKeys": secondLevel.dadosPessoais.map(x => firstLevel.id + secondLevel.fonteDeDados.id + x.id) || [],
          "collapse": false,
          "style": null,
          "blocks": [{
            "type": "CONTENT",
            "data": secondLevel.fonteDeDados.nome
          }]
        })

        secondLevel.dadosPessoais.forEach(thirdLevel => {
          topics.push({
            "key": firstLevel.id + secondLevel.fonteDeDados.id + thirdLevel.id,
            "parentKey": firstLevel.id + secondLevel.fonteDeDados.id,
            "subKeys": [],
            "collapse": false,
            "style": null,
            "blocks": [{
              "type": "CONTENT",
              "data": thirdLevel.nome
            }]
          })
        })
      })
    })

    const modelCreated = Model.create({
      rootTopicKey: 'root',
      topics
    })

    setModel(modelCreated)
  }

  useEffect(() => {
    data && mountModelByProvidedData()
  }, [data])

  return (
    <Loading isLoading={!mindMapRef || isLoading}>
      <Diagram
        onChange={setModel}
        ref={mindMapRef}
        model={model}
      />
    </Loading>
  )
}

export default Mindmap
