import {dayMonthYearAtTime} from "../../shared/utils/dates";

const typeAuditing = (type) => {
  switch (type) {
    case "INITIAL":
      return "CRIAÇÃO";
    case "UPDATE":
      return "ATUALIZAÇÃO";
  }
}

const AuditoriaListSchema = () => {
  const endpoint = 'optima-lgpd/v1/auditorias';
  return {
    title: 'Tipos de Auditorias',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        showNewButton: false,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Autor",
          Filter: false,
          Cell: ({row}) =>  row.original.author
        },

        {
          Header: "Módulo",
          Filter: false,
          Cell: ({row}) => {
            let arrEntity = row.original?.entity?.split(".", 100);
            let strEntity = arrEntity && arrEntity[arrEntity?.length - 1];

            return <div>
              <p><strong>Nome:</strong> {strEntity || row.original.fragment}</p>
              <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><strong>Id:</strong> {row.original.id}</p>
            </div>;
          }
        },
        {
          Header: "Tipo",
          Filter: false,
          Cell: ({row}) => {
            return <p>{typeAuditing(row.original.type)}</p>
          }
        },
        {
          Header: "Submódulo",
          Filter: false,
          Cell: ({row}) => {
            let arrEntity = row.original?.subEntity?.split(".", 100);
            let strEntity = arrEntity && arrEntity[arrEntity?.length - 1];
            return strEntity || "Principal"
          }
        },
        {
          Header: "Data de alteração",
          Filter: false,
          Cell: ({row}) => {
            return dayMonthYearAtTime(row.original.commitDateInstant);
          }
        },
        {
          Header: "Lista de alterações do módulo",
          Filter: false,
          Cell: ({row}) => {
            return <ul>
              {
                row.original.changedProperties.map(x => {
                  return <li>{x}</li>
                })
              }
            </ul>
          }
        }
      ]
    },

  }
}

export default AuditoriaListSchema;

