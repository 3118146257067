import {Modal} from "react-bootstrap";

const MyModal = ({
  show,
  closeButton,
  title,
  body,
  footer,
  handleClose
}) => {

  return (<Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton={closeButton}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {body}
    </Modal.Body>

    <Modal.Footer>
      {footer}
    </Modal.Footer>
  </Modal>);
}

export default MyModal;
