import {SmallBox} from "../../../components";
import React, {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";
import {useHistory} from "react-router-dom";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {Col} from "react-bootstrap";

const DEFAULT_DIREITO_DO_TITULAR = [
  {nome: 'Confirmação da existência de tratamento'},
  {nome: 'Acesso aos dados'},
  {nome: 'Correção de dados incompletos, inexatos ou desatualizados'},
  {nome: 'Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD'},
  {nome: 'Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da Autoridade Nacional, observados os segredos comercial e industrial'},
  {nome: 'Eliminação dos dados pessoais tratados com o consentimento do(a) titular, exceto nas hipóteses previstas no art. 16 da Lei'},
  {nome: 'Informação das entidades públicas e privadas com as quais o Controlador realizou uso compartilhado de dados'},
  {nome: 'Informação sobre a possibilidade de não fornecer consentimento e sobre consequências da negativa'},
  {nome: 'Revogação do consentimento, nos termos do § 5.º do art. 8.º da Lei.'},
]

const DireitosDoTitularWizard = () => {

  const [total, setTotal] = useState(1);
  const history = useHistory();

  const loadTotal = async () => {
    try {
      const result = await HttpRequest.get('optima-lgpd/v1/direitos-dos-titulares/count');
      setTotal(result.total);
    } catch (e) {
      Notify.error('Falha ao contar os Direitos do Titular', e);
    }
  }

  useEffect(() => {
    loadTotal();
  }, []);

  const insertDefaultData = async (e) => {
    try {
      e.preventDefault();
      console.log('import data');
      await HttpRequest.post('optima-lgpd/v1/dados-iniciais/direito-do-titular', DEFAULT_DIREITO_DO_TITULAR);
      Notify.success('Direitos do Titular importados!');
      history.push(ROUTE_PATH.DIREITOS_DO_TITULAR_LIST);
    } catch (e) {
      Notify.error('Falha ao importar os Direitos do Titular', e);
    }

  };

  if (total > 0) {
    return <></>;
  }

  return <Col sm={3} md={3}>
    <SmallBox
      type="secondary"
      count={total} title="Ops, Vejo que você ainda não tem os Direitos do Titular"
      titlebox={"Inserir os Direitos do Titular padrão?"}
      onClick={insertDefaultData}
      navigateTo=""/>
  </Col>

}

export default DireitosDoTitularWizard;
