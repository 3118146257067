import {Card, Tab} from "react-bootstrap";
import {CustomForm} from "../../../../components";
import RespostaSolicitacaoFormSchema from "./RespostaSolicitacaoFormSchema";

const Schema = RespostaSolicitacaoFormSchema();

export const ResponderTab = ({eventKey, data}) => {
  return <Tab.Pane eventKey={eventKey} key={eventKey}>
    <Card>
      <Card.Body>
        <CustomForm
          entityName="responder-form"
          entitySchema={Schema}
        />
      </Card.Body>
    </Card>
  </Tab.Pane>
}

