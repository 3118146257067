import React, {useEffect, useState} from 'react'
import {Loading} from "../../../components/loading/Loading";
import PieChart from "../../../components/chart/PieChart";

function PersonByCargoChart({data}) {

  const [fluxoDadosPieChartData, setFluxoDadosPieChartData] = useState(null);

  const mountData = ({totalProcessos, totalProcessosExpirado = 0}) => {
    setFluxoDadosPieChartData({
      labels: ['Fluxo de Dados Válidos', 'Fluxo de Dados Expirados'],
      chartTitle: 'Quantidade de processos válidos e expirados',
      datasets: [
        {
          label: 'Quantidade de processos válidos',
          data: [(totalProcessos < 1 ? 1 : totalProcessos) - totalProcessosExpirado, totalProcessosExpirado],
          backgroundColor: [
            'rgba(0,154,255,0.4)',
            'rgba(255,100,58,0.4)',
          ],
          borderColor: [
            'rgb(0,154,255)',
            'rgb(255,100,58)',
          ],
        }
      ]
    });
  }

  useEffect(() => {
    if (data) {
      mountData(data);
    }
  }, [data])

  return (
    <Loading isLoading={!fluxoDadosPieChartData}>
      <PieChart {...fluxoDadosPieChartData}/>
    </Loading>
  )
}

export default PersonByCargoChart
