import {dayMonthYearAtTime} from "../../shared/utils/dates";
import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {DateRangeColumnFilter} from "../../components/table/TableFilter";

const ArquivosListSchema = () => {

  const endpoint = 'optima-lgpd/v1/galeria-arquivos';
  const newUrl = ROUTE_PATH.ARQUIVOS_FORM;

  return {
    title: 'Materiais de Capacitação',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: newUrl
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Titulo",
          accessor: "titulo"
        },
        {
          Header: "Criado em",
          accessor: "createdAt",
          Filter: DateRangeColumnFilter,
          Cell: ({row}) => row.original.createdAt && dayMonthYearAtTime(row.original.createdAt)
        },
        OptionColumn({
          endpointToDelete: endpoint,
          showVisualize: true,
          editTo: newUrl
        })
      ]
    },

  }
}

export default ArquivosListSchema;

