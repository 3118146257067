import {FileSchemaForm} from "../../shared/common/FileSchemaForm";
import {ArquivoUpload} from "../../components";

const ArquivoFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Material de Capacitação' : 'Editar Material de Capacitação';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/galeria-arquivos/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/galeria-arquivos/${id || ''}`;
    },

    // validate(formData, errors) {
    //   if (formData.arquivo.fullPath === undefined && formData.arquivo.nomeOriginal === undefined && formData.arquivo.tipo === undefined) {
    //     errors.arquivo.addError("Obrigatório conter um arquivo!")
    //   }
    //   return errors
    // },

    getSchema() {
      return {
        title: "Cargo",
        type: "object",
        required: ["titulo", "arquivo"],
        properties: {
          titulo: {type: "string", title: "Título"},
          arquivo: FileSchemaForm,
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
        },
        "arquivo": {
          "ui:field": (props) => {
            console.log('propspropsprops', props);
            return (
              <ArquivoUpload  {...props}
                              label={props.schema.title}
                              value={props.formData} setValue={props.onChange}
              />
            );
          }
        },
      }
    }
  }
}

export default ArquivoFormSchema;

