import React from 'react'
import GaugeChart from 'react-gauge-chart'

function SpeedometerChart({percent, nrOfLevels, arcPadding, cornerRadius, colors, textColor, hideText}) {

  return (
    <GaugeChart
      colors={colors}
      textColor={textColor}
      nrOfLevels={nrOfLevels}
      arcPadding={arcPadding}
      cornerRadius={cornerRadius}
      percent={percent > 0 ? percent / 100 : 0}
      hideText={hideText}
    />
  )
}

export default SpeedometerChart
