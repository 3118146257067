const TipoSegurancaFormSchema = () => {
  return {
    getTitle(isNew) {
      return isNew ? 'Novo Tipo de Segurança' : 'Editar Tipo de Segurança';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/tipo-segurancas/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/tipo-segurancas/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Segurança",
        type: "object",
        required: ["nome"],
        properties: {
          nome: {type: "string", title: "Nome"},
        }
      };
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        }
      }
    }
  }
}

export default TipoSegurancaFormSchema;

