import {FileSchemaForm} from "../../../../shared/common/FileSchemaForm";
import Editor from "../../../../components/editor/Editor";
import {ArquivoUpload} from "../../../../components";
import {DefaultFormSchema} from "../../../page-form/EntrityFormProvider";
import SolicitacaoCategoriaSelector from "../../solicitacao-categoria/SolicitacaoCategoriaSelector";

const RespostaSolicitacaoFormSchema = () => {
  return {
    ...DefaultFormSchema,
    getTitle() {
      return 'Resposta á Solicitação';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/solicitacoes/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/solicitacoes/${id}/responder`;
    },

    getSchema() {
      return {
        title: "Resposta da Solicitação",
        type: "object",
        properties: {
          conteudo: {
            type: 'string',
            title: 'Resposta'
          },
          categoria: {
            type: "object",
            properties: {
              id: {type: "string", title: "Categoria"},
            }
          },
          anexos: {
            type: "array",
            items: {
              FileSchemaForm
            }
          },
        }
      }
    },

    getUiSchema: () => {
      return {
        "conteudo": {
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title='Resposta'
                onChange={props.onChange}
                initialValue={props.formData}
              />
            )
          },
        },
        "anexos": {
          "items": {
            "ui:field": (props) => {
              return (
                <ArquivoUpload  {...props}
                                label={props.schema.title}
                                disabled={props.disabled}
                                value={props.formData} setValue={props.onChange}
                />
              );
            }
          }
        },
        "categoria": {
          "ui:field": (props) => {
            return (<SolicitacaoCategoriaSelector isMulti={false}
                                                  disabled={props.disabled}
                                                  required={props.required}
                                                  title={props.schema.title}
                                                  value={props.formData}
                                                  setValue={props.onChange}/>
            );
          }
        },
      }
    },
    }
}

export default RespostaSolicitacaoFormSchema;

