import React, {useCallback, useState} from 'react';
import {Form} from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';

const AgrupadoresSelector = ({
  required = false,
  rowId = 'id',
  setValue = null,
  value = null,
  title = 'Grupos',
  label = 'label',
  disabled = false,
}) => {

  let valueDefault = value ? [...value] : [];
  const getOptionLabel = useCallback((row) => row[label], [label])
  const getOptionValue = useCallback((row) => row[rowId], [rowId])
  const [items, setItems] = useState(valueDefault);

  const _onChange = (value) => {
    setValue && setValue(value);
  };

  const _onCreateOption = (inputValue) => {
    const item = {};
    item[label] = inputValue;
    item[rowId] = inputValue;
    items.push(item);
    setItems(items)
    _onChange(items);
  }

  return (
    <Form.Group>
      <Form.Label>{title}</Form.Label>
      <CreatableSelect
        isClearable
        isDisabled={disabled}
        required={required}
        value={value}
        onCreateOption={_onCreateOption}
        isMulti
        onChange={_onChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={items}
        placeholder="-- selecione ou digite para buscar/criar --"
      />
    </Form.Group>
  );
};

export default AgrupadoresSelector;
