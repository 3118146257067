import FornecedorSelector from "../fornecedores-parceiros/component/FornecedorSelector";

const ContratosVigentesFormSchema = () => {
  return {
    getTitle(isNew) {
      return isNew ? 'Novo Contrato' : 'Editar Contrato';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/contratos-vigentes/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/contratos-vigentes/${id || 'create'}`;
    },

    getSchema() {
      return {
        title: "Tipos de Processo",
        type: "object",
        required: [],
        properties: {
          fornecedor: {
            type: 'object',
            properties: {
              id: {type: "string", title: "Setor"},
            }
          },
          email: {
            type: "string",
            title: "E-mail"
          },
          dataInicio: {
            "title": "Data inicio do contrato",
            "type": "string",
            "format": "date"
          },
          dataFim: {
            "title": "Data fim do contrato",
            "type": "string",
            "format": "date"
          },
          resumoAtividade: {type: "string", title: "Resumo da atividade"},
        },
      };
    },

    getUiSchema: () => {
      return {
        "fornecedor": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<FornecedorSelector disabled={props.disabled}
                                        required={props.required}
                                        title={props.title}
                                        value={props.formData}
                                        setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default ContratosVigentesFormSchema;

