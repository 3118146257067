import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const TRANSMISSION_TYPES = [
  {id: 'PARCEIRO', label: 'PARCEIRO'},
  {id: 'FORNECEDOR', label: 'FORNECEDOR'},

];

const TipoPrestadorSelector = ({
  required = false,
  defaultValue,
  title = "Tipo de Prestador",
  setValue = null,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="id"
    setValue={setValue}
    items={TRANSMISSION_TYPES}
    {...props}
  />

}

export default TipoPrestadorSelector;
