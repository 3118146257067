import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";
import {dayMonthYear} from "../../shared/utils/dates";
import {DateRangeColumnFilter} from "../../components/table/TableFilter";

const CampanhaListSchema = () => {

  const endpoint = 'optima-lgpd/v1/campanhas';
  return {
    title: 'Lista de Campanhas',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.CAMPANHAS_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Título ",
          accessor: "nome"
        },
        {
          Header: "Duração",
          accessor: "duracao",
          Filter: DateRangeColumnFilter,
          Cell: ({row}) => `${dayMonthYear(row.original?.comeco)} até ${dayMonthYear(row.original?.fim)}`
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.CAMPANHAS_FORM
        })
      ]
    },

  }
}

export default CampanhaListSchema;

