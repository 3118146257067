const ContatoSuporteFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Chamado' : '';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/contato-suportes/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/contato-suportes/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Mensagem",
        type: "object",
        required: ["titulo", "mensagem"],
        properties: {
          titulo: {type: "string", title: "Titulo"},
          mensagem: {type: "string", title: "Mensagem"}
        }
      };
    },

    getUiSchema: () => {
      return {
        "titulo": {
          "ui:autofocus": true,
          "ui:help": "Título da Mensagem"
        },
        "mensagem": {
          "ui:autofocus": true,
          "ui:widget": "textarea",
          "ui:help": "Insira sua mensagem"
        }
      }
    }
  }
}

export default ContatoSuporteFormSchema;

