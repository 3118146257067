import {useEffect} from 'react';
import {HashRouter} from "react-router-dom";
import './App.scss'
import 'react-toastify/dist/ReactToastify.css';
import {BaseLayout} from "./components/layout/BaseLayout";
import {ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {getMe} from "./shared/auth/auth.reducer";
import Routes from "./components/route/Routes";
import {Loading} from "./components/loading/Loading";

function App() {

  const dispatch = useDispatch();
  const currentUser = useSelector(({authState}) => authState.currentUser);

  useEffect(() => {
    dispatch(getMe());
    console.log('APP INICIADO');
  }, [dispatch]);

  if (!currentUser) {
    return <Loading isLoading={true}/>
  }

  return (
    <HashRouter>
      <BaseLayout>
        <ToastContainer autoClose={2500}/>
        <Routes/>
      </BaseLayout>
    </HashRouter>
  );
}

export default App;
