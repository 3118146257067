import {Page} from "../../../components/page/Page";
import {Card, Col, Nav, Row, Tab} from "react-bootstrap";
import {AtualizacaoTab} from "./component/AtualizacaoTab";
import {useCallback, useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";
import {Loading} from "../../../components/loading/Loading";
import {DetalhesTab} from "./component/DetalhesTab";
import {ComentarioTab} from "./component/ComentarioTab";
import {ResponderTab} from "./component/ResponderTab";
import {RespostaTab} from "./component/RespostaTab";

const TAB_RESPONDER_ID = 'responder';
const TAB_RESPOSTA_ID = 'resposta';

const TABS = [
  {id: 'atualizacao', title: 'Atualizações', component: AtualizacaoTab},
  {id: 'detalhes', title: 'Dados da Solicitação', component: DetalhesTab},
  {id: 'comentarios', title: 'Comentários', component: ComentarioTab},
  {id: TAB_RESPONDER_ID, title: 'Responder', component: ResponderTab},
  {id: TAB_RESPOSTA_ID, title: 'Resposta', component: RespostaTab},
];

export const SolicitacaoPage = ({match, render}) => {

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const findById = useCallback(async (id) => {
    try {
      let data = await HttpRequest.get(`optima-lgpd/v1/solicitacoes/${id}`);
      setData(data);
    } catch (e) {
      Notify.error('Falha ao carregar dados da entidade', e);
    } finally {
      setIsLoading(false);
    }
  }, [])

  useEffect(() => {
    findById(match.params?.id);
  }, [match.params?.id, findById])

  const filterTab = (tab) => {
    if (tab.id === TAB_RESPONDER_ID) {
      return !data.respostaSolicitacao;
    }
    if (tab.id === TAB_RESPOSTA_ID) {
      return data.respostaSolicitacao;
    }
    return true;
  }

  return <Page title="Detalhes da Solicitação">
    {isLoading && <Loading/>}
    {!isLoading && <Card>
      <Card.Body>
        <Tab.Container defaultActiveKey={TABS[0].id}>
          <Row>
            <Col sm={2}>
              <Nav variant="tabs" className="flex-column">
                {TABS.filter(filterTab).map(x => <Nav.Item key={x.id}>
                  <Nav.Link eventKey={x.id}>{x.title}</Nav.Link>
                </Nav.Item>)}
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content className="ml-3 mt-3">
                {TABS.map(x => x.component({eventKey: x.id, key: x.id, data: data}))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card.Body>
    </Card>}
  </Page>
}
