import React from "react";

const LGPDTheme = React.lazy(() => import('./themes/LGPD'));

const TYPE_OF_THEME = {
  'LGPDTheme': 'LGPDTheme',
}

export const ThemeSelector = ({...props}) => {

  const getTheme = () => {
    return TYPE_OF_THEME.LGPDTheme;
  }

  const CHOSEN_THEME = getTheme();

  return (
    <>
      <React.Suspense fallback={<></>}>
        {(CHOSEN_THEME === TYPE_OF_THEME.LGPDTheme) && <LGPDTheme {...props}/>}
      </React.Suspense>

    </>
  )
}
