import UserSelector from "../user/component/UserSelector";
import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";

const SetorFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Setor' : 'Editar Setor';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/setores/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/setores/${id || ''}`;
    },

    getSchema() {
      return {
        type: "object",
        required: ["nome", "funcionarios"],
        properties: {
          nome: {type: "string", title: "Nome"},
          // responsavel: {
          //   type: 'object',
          //   properties: {
          //     id: {type: "string", title: "Setor"},
          //   }
          // },
          funcionarios: {
            type: 'array',
            title: 'Responsável',
            items: {
              type: 'object',
              properties: {
                id: {type: "string", title: "Funcionários"},
              }
            }
          }
        }
      }
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
          "ui:help": "Nome do setor ou área em execução"
        },
        "responsavel": {
          // "ui:options": {
          //   label: false
          // },
          "ui:field": (props) => {
            return (<UserSelector disabled={props.disabled} required={props.required}
                                  value={props.formData} setValue={props.onChange}/>
            );
          }
        },
        "funcionarios": {
          // "ui:options": {
          //   label: false
          // },
          "ui:field": (props) => {
            return (<FuncionarioSelector isMulti={true} disabled={props.disabled} required={props.required}
                                         title={props.schema.title}
                                         value={props.formData} setValue={props.onChange}/>
            );
          }
        }
      }
    }
  }
}

export default SetorFormSchema;

