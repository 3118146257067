import {Link} from "react-router-dom";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {NavItemCompany} from "./NavItemCompany";
import CompanyName from "../companyName/CompanyName";

export const Nav = () => {

  const logout = async () => {
    console.log('do logout');
    await HttpRequest.post('optima-auth/v1/logout');
    Notify.success("Deslogado!");
    setTimeout(() => {
      window.location = '/accounts'
    }, 300);
  }

  return (
    <nav className="main-header navbar navbar-expand">
      <ul className="navbar-nav">

        <li className="nav-item">
          <Link className="nav-link" to="" data-widget="pushmenu" onClick={e => e.preventDefault()} role="button"><i className="fas fa-bars"/></Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/home"><i className="fas fa-home"/></Link>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <CompanyName/>
      </ul>
      <ul className="navbar-nav ml-auto">

        <li className="nav-item">
          <Link to="/list/contato-suportes/" className="nav-link" role="button" title="Mensagem">
            <i className="fas fa-envelope-open-text"/>
          </Link>
        </li>

        <NavItemCompany/>

        <li className="nav-item">
          <Link to="#" onClick={logout} className="nav-link" role="button" title="Sair">
            <i className="fas fa-sign-out-alt"/>
          </Link>
        </li>

      </ul>
    </nav>)
}
