import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";
import {SelectColumnFilter} from "../../components/table/TableFilter";
import CursoSelector from "../cursos/component/PoliticaSelector";
import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";

const TreinamentoListSchema = () => {

  const endpoint = 'optima-lgpd/v1/treinamentos';

  return {
    title: 'Lista de Cursos Aplicados',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.TREINAMENTO_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Curso",
          accessor: "curso.id",
          Filter: SelectColumnFilter,
          CustomSelector: CursoSelector,
          sortable: false,
          export: false,
          Cell: ({row}) => row.original?.curso?.nome || null
        },
        {
          Header: "Pessoas",
          accessor: "funcionarios.id",
          Filter: SelectColumnFilter,
           CustomSelector: FuncionarioSelector,
          sortable: false,
          export: false,
          Cell: ({row}) => row.original?.funcionarios?.map(x => <li key={x?.id}>{x?.nome}</li>) || null

        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.TREINAMENTO_FORM,
        })
      ]
    }
  }
}

export default TreinamentoListSchema;
