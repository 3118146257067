import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";
import {SelectColumnFilter} from "../../components/table/TableFilter";
import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";

const SetorListSchema = () => {

  const endpoint = 'optima-lgpd/v1/setores';

  return {
    title: 'Lista de Setores/Repartição',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.SETOR_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        {
          endpoint: ROUTE_PATH.SETOR_FORM,
          Header: "Responsável",
          accessor: "funcionarios.id",
          Filter: SelectColumnFilter,
          CustomSelector: FuncionarioSelector,
          Cell: ({row}) => row.original?.funcionarios?.map(x => <li key={x?.id}>{x?.nome}</li>) || null
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.SETOR_FORM
        })
      ]
    },

  }
}

export default SetorListSchema;

