import React, {useCallback, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {DateRangeSelector} from "../select/DateRangeSelector";
import moment from "moment";
import {InputText} from "../index";

export function DefaultColumnFilter({column: {Header, filterValue, setFilter, id}}) {

  useEffect(() => {
    if (id === 'Opções') {
      return;
    }

    setFilter(null)
  }, [id]);

  const onChange = useCallback((e) => {
    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
  }, [setFilter])

  return (
    <InputText
      label={Header}
      labelRequired={true}
      showFormComponents={false}
      className="rounded-top "
      value={filterValue || ''}
      onChange={onChange}
      placeholder={`Filtrar resultados...`}
    />
  )
}

export function DateColumnFilter({column: {Header, setFilter, id}}) {

  useEffect(() => {
    setFilter(null)
  }, [id]);

  console.log(Header)
  return (
    <Form.Group>
      <Form.Label>{Header}</Form.Label>
      <Form.Control
        type="date"
        onChange={e => {
          console.log(e)
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
      />
    </Form.Group>
  )
}

export function DateRangeColumnFilter({column: {Header, setFilter, id}}) {

  useEffect(() => {
    setFilter(null)
  }, [id]);

  return (
    <Form.Group>
      <Form.Label>{Header}</Form.Label>
      <DateRangeSelector
        onChange={(startDate, endDate) => setFilter([ startDate?.toISOString() , endDate?.toISOString()])}
        defaultStartDate={moment('01.01.2020 00:00', 'L')}
      />
    </Form.Group>

  )
}

export function DateTimeRangeColumnFilter({column: {Header, setFilter, id}}) {

  useEffect(() => {
    setFilter(null)
  }, [id]);

  return (
    <Form.Group>
      <Form.Label>{Header}</Form.Label>
      <DateRangeSelector
        onChange={(startDate, endDate) => setFilter([ startDate?.toISOString() , endDate?.toISOString()])}
        defaultStartDate={moment('01.01.2020 00:00', 'L')}
      />
    </Form.Group>

  )
}


export function SelectColumnFilter({column: {Header, setFilter, render, CustomSelector}, ...p}) {

  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(null);
    setFilter(null);

  }, [p.headers])

  const setData = useCallback((data) => {
    setFilter(data ? data.id : null);
    setValue(data);
  }, [setFilter]);

  if (CustomSelector) {
    return render(CustomSelector, {
      title: Header,
      setValue: setData,
      value: value,
      showCreateButton: false,
      isMulti: false
    });
  }

  throw new Error('CustomSelector é um campo obrigatório para o table selector');

}
