export const TemplateBaseLegalFormSchema = () => {
  return {
    getTitle(isNew) {
      return isNew ? 'Novo Template de Base Legal' : 'Editar Template de Base Legal';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/template-bases-legais/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/template-bases-legais/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Template Base Legal",
        type: "object",
        required: ["nome"],
        properties: {
          nome: {type: "string", title: "Nome"},
        }
      };
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        }
      }
    }
  }
}

export default TemplateBaseLegalFormSchema;