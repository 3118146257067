import React, {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';

export const REGISTRATION_MASK = [
  {id: 'CPF', label: '999.999.999-99'},
  {id: 'CNPJ', label: '99.999.999/9999-99'}
];

export const InputMaskedRegistrationNumber = ({
  title,
  required,
  minLength,
  defaultValue,
  value,
  disabled,
  id,
  placeholder,
  onChange,
  onChangeText,
  type = 'text',
  mask = 0,
  ...otherProps
}) => {

  const [initialMask, setInitialMask] = useState(mask);

  const _onChange = (e) => {
    onChange && onChange(e);
    onChangeText && onChangeText(e.target.value);
  }

  useEffect(() => {
    if (initialMask !== mask) {
      onChangeText && onChangeText('');
      setInitialMask(mask);
    }
  }, [mask])

  return (
    <div>
        <span className="text-bold">
          {title}
        </span>
      <InputMask type={type} mask={REGISTRATION_MASK[mask].label}
                 value={value}
                 minLength={minLength}
                 required={required} defaultValue={defaultValue}
                 disabled={disabled}
                 className="form-control mt-1" id={id}
                 placeholder={placeholder}
                 onChange={_onChange}
                 {...otherProps}
      >

      </InputMask>
    </div>

  )
}
