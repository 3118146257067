import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const CompanyListSchema = () => {
  const endpoint = 'optima-auth/v1/companies';
  return {
    title: 'Lista de Entidade',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.COMPANY_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name"
        },
        {
          Header: "Domínio",
          accessor: "domain"
        },
        OptionColumn({
          showDelete: false,
          showBlock: true,
          entity: 'Entidades',
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.COMPANY_FORM
        })
      ]
    },

  }
}

export default CompanyListSchema;

