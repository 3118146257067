import {Page} from "./Page";

export const Page404 = () => {
  return (
    <Page title="Página não encontrada">
      <p>
        Opa!
        Está pagina não existe, ou você não tem acesso!
      </p>
    </Page>
  )
}
