import MyModal from "../modal/Modal";
import {Button, Col, Row} from "react-bootstrap";
import {TableButton} from "../button/TableButton";
import React from "react";

export const ModalConfirmRemove = ({open, isLoading, setOpen, onClick}) => {

  return <MyModal
    show={open}
    title="Confirmar remoção"
    body={
      <h5>Você deseja realmente remover ?</h5>
    }

    footer={
      <Row className="w-100 d-flex justify-content-between">
        <Col>
          <Button size="sm" disabled={isLoading} variant="light" type="reset" onClick={() => setOpen(false)}>
            <i className="fas fa-ban"></i>
            &nbsp;&nbsp;
            Cancelar
          </Button>
        </Col>
        <Col className="text-right">
          <TableButton classNameButton="text-center" disabled={isLoading} icon="fas fa-trash" variant="danger" title="Confirmar" onClick={onClick}/>
        </Col>
      </Row>
    }
  />
}
