import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";
import {Arquivo} from "../../components/arquivo/Arquivo";
import {OrganogramaButton} from "../cargo/component/OrganogramaButton";
import {SelectColumnFilter} from "../../components/table/TableFilter";
import SetorSelector from "../setor/component/SetorSelector";
import CargoSelector from "../cargo/component/CargoSelector";
import DadosCriancasSelector from "./component/DadosCriancasSelector";

const ProcessoListSchema = () => {

  const endpoint = 'optima-lgpd/v1/processos';

  return {
    title: 'Lista de Fluxo de Dados',

    getEndpoint: () => endpoint + '?processoType=PROCESSO&',

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.PROCESSO_FORM,
        customButton: () => {
          return <OrganogramaButton to={ROUTE_PATH.ORGANOGRAMA_PROCESSO_PAGE} children={"Mapa Mental de Dados Pessoais Coletados"}/>
        },
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        {
          Header: "Duração",
          accessor: "durationInMounths",
          Cell: ({row}) => row.original.durationInMounths > 1 ? <p>{row.original.durationInMounths} meses</p> : <p>{row.original.durationInMounths} mês</p>
        },
        {
          Header: "Arquivos",
          Filter: false,
          sortable: false,
          export: false,
          Cell: ({row}) => {
            return row.original.arquivos && row.original.arquivos.map(x => <li><Arquivo arquivo={x}/></li>)
          }
        },
        {
          Header: "Possui dados pessoais de crianças?",
          urlValue: "dadosCriancas",
          endpoint: endpoint,
          accessor: "dadosCriancas",
          Filter: SelectColumnFilter,
          CustomSelector: DadosCriancasSelector,
          Cell: ({row}) => {
            return row.original.dadosCriancas ? 'SIM' : 'NÃO'
          }
        },
        {
          Header: "Setor",
          accessor: "setor.id",
          Filter: SelectColumnFilter,
          CustomSelector: SetorSelector,
          filter: 'includes',
          Cell: ({row}) => row.original.setor ? row.original.setor.nome : null
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.PROCESSO_FORM,
          showDuplicateButton: true,
        })
      ]
    },

  }
}

export default ProcessoListSchema;

