import {Form, FormControl, InputGroup} from "react-bootstrap";

export const InputGroupText = ({
  label,
  onChange,
  id,
  leftAppend,
  rightAppend,
  onChangeText, type = 'text',
  ...otherProps
}) => {

  const _onChange = (e) => {
    onChange && onChange(e);
    onChangeText && onChangeText(e.target.value);
  }

  return (
    <Form.Group>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <InputGroup>
        {leftAppend}
        <FormControl  {...otherProps} id={id} type={type} onChange={_onChange}/>
        {rightAppend}
      </InputGroup>
    </Form.Group>
  )
}
