import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";

const ContatoSuporteListSchema = () => {
  const endpoint = 'optima-lgpd/v1/contato-suportes';
  return {
    title: 'Chamados do Suporte',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.FORM_CONTATO_SUPORTE_PAGE
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Título",
          accessor: "titulo"
        },
        OptionColumn({
          endpointToDelete: endpoint,
          showEdit: false
        })
      ]
    },

  }
}

export default ContatoSuporteListSchema;

