import {Link} from "react-router-dom";
import Logo from "../logo/Logo";
import {CurrentUser} from "./user/CurrentUser";
import {useEffect} from "react";

import $ from 'jquery';
import {SidebarItem} from "./SidebarItem";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
export const Sidebar = () => {

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 position-fixed">
      <div style={{overflow: "auto", height: "95vh"}}>
        <Link to="/" className="brand-link text-center">
          <Logo className="elevation-3" sidebar={true}/>
        </Link>
        <CurrentUser/>
        <nav className="mb-1">
          <ul className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false">
            <SidebarItem/>
          </ul>
        </nav>
      </div>
    </aside>
  )
}

