import {Row} from "react-bootstrap";
import {TableButton} from "../../components/button/TableButton";
import {dayMonthYear} from "../../shared/utils/dates";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../components/table/TableFilter";
import CursoSelector from "../cursos/component/PoliticaSelector";
import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";

const CampanhaListSchema = () => {

  const location = window.location
  console.log(location)

  const endpoint = 'optima-lgpd/v1/qualificacoes';
  return {
    title: 'Lista de Certificados',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        showNewButton: false
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Curso",
          accessor: "curso.id",
          Filter: SelectColumnFilter,
          CustomSelector: CursoSelector,
          sortable: false,
          export: false,
          Cell: ({row}) => row.original?.curso?.nome || null
        },
        {
          Header: "Pessoa",
          accessor: "funcionario.id",
          Filter: SelectColumnFilter,
          CustomSelector: FuncionarioSelector,
          sortable: false,
          export: false,
          Cell: ({row}) => row.original?.funcionario?.nome || null
        },
        {
          Header: "Duração",
          Filter: false,
          Cell: ({row}) => `${dayMonthYear(row.original?.startDate)} até ${dayMonthYear(row.original?.endDate)}`
        },
        {
          Header: "Criado em",
          accessor: "createdAt",
          Filter: DateRangeColumnFilter,
          Cell: ({row}) => dayMonthYear(row.original?.createdAt)
        },
        {
          Header: "Opções",
          sortable: false,
          export: false,
          collapse: true,
          Filter: false,
          Cell: ({row}) => {
            console.log(row)
            return (
              <Row>
                <TableButton disabled={!row.original.endDate} href={`${location.origin}/questionario#/certificado/${row.original.id}`} title="Visualizar"/>
              </Row>
            )
          }
        }
      ]
    },

  }
}

export default CampanhaListSchema;

