import KanbanBoard from "../../components/kanban/KanbanBoard";
import {useEffect, useState} from "react";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {Loading} from "../../components/loading/Loading";

const KanbanPage = () => {

  const [kanbanData, setKanbanData] = useState(null);
  const [boardData, setBoardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const STATUS = {
    PENDENTE: "PENDENTE",
    PROGRESSO: "EM_PROGRESSO",
    CONCLUIDO: "CONCLUIDA"
  }

  function createBoardData(kanbanData) {
    let lanes = Object.keys(STATUS)?.map((name, index) => {
        const laneData = kanbanData.filter(x => x.status === STATUS[name]);
        return {
          id: `lane${index}`,
          title: name,
          label: `${laneData?.length || 0} tarefas`,
          cards: laneData,
        }
      }
    )
    return {lanes};
  }

  async function findData() {
    try {
      const query = new URLSearchParams();
      query.append('page', 0);
      query.append('size', 1000);
      let data = await HttpRequest.get(`optima-lgpd/v1/tarefas?${query}`);
      setKanbanData(data?.content.map(
        x => ({
          id: x.id,
          title: x.titulo || '',
          description: x.descricao || '',
          status: x.status,
          version: x.version
        })));
    } catch (e) {
      Notify.error('Falha ao carregar tarefas', e);
    } finally {
      setIsLoading(false);
    }
  }

  async function saveCard(cardToSave) {
    try {
      let id = cardToSave.id;
      delete cardToSave.id;
      let response = await HttpRequest.post('optima-lgpd/v1/tarefas', cardToSave);
      let lanes = boardData.lanes.map(lanesMap => {
        if (STATUS[lanesMap.title] === cardToSave.status) {
          lanesMap.cards.map(card => {
            if (card.id === id) {
              card.id = response.id;
            }
          })
        }
        return lanesMap;
      })
      setBoardData({lanes});
    } catch (e) {
      Notify.error('Falha ao salvar tarefa', e);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateCardStatus(cardToUpdate) {
    try {
      await HttpRequest.put(
        `optima-lgpd/v1/tarefas/${cardToUpdate.id}/status/${cardToUpdate.status}`);
    } catch (e) {
      Notify.error('Falha ao salvar tarefa', e);
    } finally {
      setIsLoading(false);
      findData();
    }
  }

  async function updateCard(cardToUpdate) {
    try {
      await HttpRequest.put(
        `optima-lgpd/v1/tarefas/${cardToUpdate.id}`, cardToUpdate);
    } catch (e) {
      Notify.error('Falha ao salvar tarefa', e);
    } finally {
      setIsLoading(false);
      findData();
    }
  }

  async function deleteCard(cardId) {
    try {
      await HttpRequest.delete(`optima-lgpd/v1/tarefas/${cardId}`);
    } catch (e) {
      Notify.error('Falha ao remover tarefa', e);
    } finally {
      setIsLoading(false);
    }
  }

  function handleCardAdd(card, laneId) {
    let cardToSave;
    boardData.lanes.map(lane => {
      if (lane.id === laneId) {
        cardToSave = {
          ...card,
          descricao: card.description,
          titulo: card.title,
          status: STATUS[lane.title]
        };
        lane.cards.push(card);
        lane.label = `${lane.cards?.length || 0} tarefas`;
      }
      return lane;
    });
    saveCard(cardToSave);
  }

  function handleCardDelete(cardId, laneId) {
    boardData.lanes.map(lane => {
      if (lane.id === laneId) {
        lane.label = `${lane.cards?.length - 1 || 0} tarefas`;
      }
    });
    deleteCard(cardId);
  }

  function handleCardUpdate(lane, card) {
    const cardOld = boardData.lanes.filter(x => x.id === lane).map(x => x.cards)[0].filter(x => x.id === card.id)[0];
    const cardToUpdate = {
      id: card.id,
      descricao: card.description ? card.description : cardOld.description,
      titulo: card.title ? card.title : cardOld.title,
      status: cardOld.status,
      version: cardOld.version
    };
    updateCard(cardToUpdate);
  }

  function handleCardChangeLane(laneOriginId, laneDestinationId, cardId,
    lanePosition) {
    let cardToUpdate;
    let lanes = boardData.lanes.map(lane => {
      if (lane.id === laneOriginId) {
        lane.label = `${lane.cards?.length || 0} tarefas`;
      }
      if (lane.id === laneDestinationId) {
        let card = lane.cards.filter(cards => cards.id === cardId);
        cardToUpdate = {...card[0], status: STATUS[lane.title]};
        lane.label = `${lane.cards?.length || 0} tarefas`;
      }
      return lane;
    });
    setBoardData({lanes})
    updateCardStatus(cardToUpdate);
  }

  useEffect(() => {
    findData();
  }, [])

  useEffect(() => {
    if (!kanbanData) {
      return;
    }
    setBoardData(createBoardData(kanbanData));
  }, [kanbanData])

  if (!boardData) {
    return <Loading isLoading={isLoading}/>
  }
  return (<KanbanBoard draggable
                       editable
                       id="EditableBoard1"
                       onCardAdd={handleCardAdd}
                       onCardClick={function noRefCheck() {
                       }}
                       onDataChange={(a) => setBoardData(a)}
                       onCardUpdate={handleCardUpdate}
                       onCardDelete={handleCardDelete}
                       onCardMoveAcrossLanes={handleCardChangeLane}
                       data={boardData}/>)
}

export default KanbanPage;
