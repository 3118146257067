import RipdRichText from "./component/RipdRichText";

const RelatorioFormSchema = () => {
  return {
    save(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    },

    getTitle(isNew) {
      return isNew ? 'Novo Relatório' : 'Editar Relatório';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/ripd/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/ripd/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Relatório",
        type: "object",
        required: ["nome", "htmlText"],
        properties: {
          nome: {type: "string", title: "Nome"},
          htmlText: {
            type: "string",
            title: "Relatório"
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
          "ui:help": "Nome do Relatório, com finalidade há sua atividade.",
        },
        "htmlText": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (
              <RipdRichText
                {...props}
                title="Relatório de Impacto e Proteção de Dados"
                initialValue={props.formData}
              />
            );
          }
        }
      }
    }
  }
}

export default RelatorioFormSchema;

