import {useSelector} from "react-redux";

const CompanyName = () => {

  const {currentUser} = useSelector(({authState}) => ({
    currentUser: authState.currentUser,
  }));

  return (<>{currentUser?.company?.name}</>
  )

};
export default CompanyName;
