import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import {useEffect, useState} from "react";
import HttpRequest from "../../../../shared/common/http/HttpRequest";
import Notify from "../../../../shared/common/notify/Notify";
import {Loading} from "../../../../components/loading/Loading";
import './Organograma.css';
import {LinkButton} from "../../../../components";
import {Card} from "react-bootstrap";
import {Page} from "../../../../components/page/Page";

const OrganogramaCargoPage = () => {

  const [organogramaData, setOrganogramaData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const processa = (listCargo, user) => {

    const map = {};
    const resultado = {
      name: user,
      children: []
    };
    const processLevel2 = (cargo) => {

      let data = map[cargo.id];
      console.log(data)
      if (data) {
        return data;
      }

      data = {name: cargo.nome, children: []};
      map[cargo.id] = data;

      if (!cargo.cargo) {
        return data;
      }

      let pai = processLevel2(cargo.cargo);
      pai.children.push(data);

      return data;
    }

    listCargo?.forEach(x => {

      if (!x.cargo) { // nivel 1
        let data = processLevel2(x);
        resultado.children.push(data)
      } else { // nivel 2
        processLevel2(x);
      }
    });

    return resultado;
  };
  useEffect(() => {
    const findData = async () => {
      try {
        const query = new URLSearchParams();
        query.append('page', 0);
        query.append('size', 1000);
        let data = await HttpRequest.get(`optima-lgpd/v1/cargos?${query}`);
        let userMe = await HttpRequest.get(`optima-auth/v1/users/me?${query}`);
        setOrganogramaData(processa(data?.content, userMe?.company.name))
      } catch (e) {
        Notify.error('Falha ao carregar tarefas', e);
      } finally {
        setIsLoading(false);
      }
    }
    findData();
  }, [])

  const MyNodeComponent = ({node}) => {
    return (
      <div className="initechNode">{node.name}</div>
    );
  };

  return (
    <Page title="Organograma">
      <Card>
        <Card.Body>
          <LinkButton to={"/cadastros/list/cargos/"} className="btn-primary m-3" size="lg">Voltar</LinkButton>
          {organogramaData ? <OrgChart tree={organogramaData} NodeComponent={MyNodeComponent}/>
            : <Loading isLoading={isLoading}/>}
        </Card.Body>
      </Card>
    </Page>
  )

}

export default OrganogramaCargoPage;
