import {CreatableReadonlyToInputV2} from "../../../components";

const SolicitacaoCategoriaSelector = ({
  required = false,
  title = "Categoria",
  setValue = null,
  isMulti = true,
  path = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `optima-lgpd/v1/solicitacao-categorias?sort=nome,ASC&nome=${filterValue}`;
  }

  return <CreatableReadonlyToInputV2
    isMulti={isMulti}
    title={title}
    required={required}
    label="nome"
    getUrl={getUrl}
    setValue={setValue}
    path="optima-lgpd/v1/solicitacao-categorias"
    {...props}
  />

}

export default SolicitacaoCategoriaSelector;

