import OptionColumn from "../../components/table/OptionColumn";
import {Arquivo} from "../../components/arquivo/Arquivo";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const PoliticaListSchema = () => {
  const endpoint = 'optima-lgpd/v1/politicas';
  return {
    title: 'Lista de Políticas de Dados',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.POLICY_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        {
          Header: "Arquivos",
          sortable: false,
          export: false,
          Cell: ({row}) => {
            return row.original.arquivos && row.original.arquivos.map(x => <li><Arquivo arquivo={x}/></li>)
          }
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.POLICY_FORM
        })
      ]
    },
  }
}

export default PoliticaListSchema;

