export const FileSchemaForm = {
  type: "object",
  title: "Arquivo",

  // required: ["fullPath", "tipo", "nomeOriginal"],
  "properties": {
    "fullPath": {
      type: 'string',
    },
    "tipo": {
      type: 'string',
    },
    "nomeOriginal": {
      type: 'string',
    },
  }
}

export const MeioSchemaForm = {
  type: "object",
  required: ["label"],
  "properties": {
    "label": {
      type: 'string',
    },
  }
}
