import React from 'react';

export const baseTheme = {
  companyName: 'SEM COMPANY NAME NO THEMA',
  title: 'ComapanyName',
  companyLink: '#'
}

export const ThemeContext = React.createContext(baseTheme);

