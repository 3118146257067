import React, {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";
import {Loading} from "../../../components/loading/Loading";
import Editor from "../../../components/editor/Editor";

const RipdRichText = ({onChange, ...props}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [_initialValue, setInitValue] = useState(null);

  const findData = async () => {
    try {
      let data = await HttpRequest.get(`optima-lgpd/v1/ripd/html-content`);
      setInitValue(data.content);
      onChange && onChange(data.content);
      setIsLoading(false);
    } catch (e) {
      Notify.error('Falha ao gerar relatório', e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (_initialValue === null) {
      if (props.initialValue) {
        setInitValue(props.initialValue);
        setIsLoading(false);
      } else {
        findData();
      }
    }
  }, [props.initialValue]);

  if (isLoading) {
    return <Loading isLoading={isLoading}/>
  }
  return (<Editor initialValue={_initialValue} title={props.title} onChange={onChange}/>);
};

export default RipdRichText;
