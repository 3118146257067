import React from 'react'
import {Col, Row} from 'react-bootstrap'

function RiskTable({data}) {
  let dataColors = {
    high: ['#ffd11c', '#ff7755', '#ff7755'],
    medium: ['#ffd11c', '#ffd11c', '#ff7755'],
    low: ['#5599fe', '#01ae38', '#01ae38'],
  }

  return (
    <div>
      <Row className="align-items-center ">
        <Col className="text-center">Alto</Col>
        <Col xs={3} style={{backgroundColor: dataColors.high[0]}} className="p-4 mr-1 mb-1 text-center text-white">{data[2][0]}</Col>
        <Col xs={3} style={{backgroundColor: dataColors.high[1]}} className="p-4 mr-1 mb-1 text-center text-white">{data[2][1]}</Col>
        <Col xs={3} style={{backgroundColor: dataColors.high[2]}} className="p-4 mr-1 mb-1 text-center text-white">{data[2][2]}</Col>
      </Row>
      <Row className="align-items-center ">
        <Col className="text-center text-truncate">Médio</Col>
        <Col xs={3} style={{backgroundColor: dataColors.medium[0]}} className="p-4 mr-1 mb-1 text-center text-white">{data[1][0]}</Col>
        <Col xs={3} style={{backgroundColor: dataColors.medium[1]}} className="p-4 mr-1 mb-1 text-center text-white">{data[1][1]}</Col>
        <Col xs={3} style={{backgroundColor: dataColors.medium[2]}} className="p-4 mr-1 mb-1 text-center text-white">{data[1][2]}</Col>
      </Row>
      <Row className="align-items-center ">
        <Col className="text-center">Baixo</Col>

        <Col xs={3} style={{backgroundColor: dataColors.low[0]}} className="p-4 mr-1 mb-1 text-center text-white">{data[0][0]}</Col>
        <Col xs={3} style={{backgroundColor: dataColors.low[1]}} className="p-4 mr-1 mb-1 text-center text-white">{data[0][1]}</Col>
        <Col xs={3} style={{backgroundColor: dataColors.low[2]}} className="p-4 mr-1 mb-1 text-center text-white">{data[0][2]}</Col>
      </Row>
      <Row className="align-items-center ">
        <Col className="text-center"></Col>
        <Col xs={3} className="text-center">Pequeno</Col>
        <Col xs={3} className="text-center">Razoável</Col>
        <Col xs={3} className="text-center">Grande</Col>
      </Row>
    </div>
  )
}

export default RiskTable
