import Roles from "./Roles";
import {ROUTE_PATH} from "./RoutesPath";

const Menu = ({
  name, path, icon, isPrivate = true, children = [],
  roles = [], newTab = false,
}) => {
  return {name, path, icon, isPrivate, children, roles, newTab};
};

export const getAllMenuByUser = () => {
  return AllMenus;
}

export const getMenuByPath = (path) => {
  const menu = _getMenuByPath(path);
  if (menu && menu.path) {
    return menu;
  }
  return _getMenuByPath(path + '/');
}

const _getMenuByPath = (newPath) => {
  const path = newPath;
  for (let menu of AllMenus) {
    if (menu.path === path) {
      return menu;
    }

    for (let menuChildren of menu.children) {
      if (menuChildren.path === path) {
        return menuChildren;
      }
      for (let menuGrandson of menuChildren.children) {
        if (menuGrandson.path === path) {
          return menuGrandson
        }
      }
    }
  }
  if (path === '/') {
    return AllMenus[0];
  }
  return null;
}

export const AllMenus = [

  Menu({
    name: 'Início', path: ROUTE_PATH.HOME,
    icon: 'fa-tachometer-alt',
    roles: [Roles.USER, Roles.ADMIN]
  }),

  Menu({
    name: 'Alertas', path: ROUTE_PATH.ALERTAS,
    icon: 'fa-bell',
    roles: [Roles.ADMIN]
  }),

  Menu({
    name: 'Cadastros', path: '/cadastros',
    icon: 'fa-cogs', children: [
      Menu({name: 'DPO', path: ROUTE_PATH.RESPONSAVEL_EMPRESA_LIST, icon: 'fa-crosshairs'}),
      Menu({name: 'Pessoas', path: ROUTE_PATH.FUNCIONARIO_LIST, icon: 'fa-user'}),
      Menu({name: 'Entidades', path: ROUTE_PATH.COMPANY_LIST, icon: 'fa-building', roles: [Roles.SUPER]}),
      Menu({name: 'Setores', path: ROUTE_PATH.SETOR_LIST, icon: 'fa-grip-horizontal'}),
      Menu({name: 'Cargos', path: ROUTE_PATH.CARGO_LIST, icon: 'fa-user-tag'}),
      Menu({
        name: 'Variáveis', path: '/cadastros/list/variaveis',
        icon: 'fa-cogs', children: [
          //Menu({name: 'Categorias de Risco', path: ROUTE_PATH.CATEGORIA_LIST, icon: ''}),
          Menu({name: 'Base Legal', path: ROUTE_PATH.BASE_LEGAL_LIST, icon: 'fa-stream'}),
          Menu({name: 'Direitos Do Titular', path: ROUTE_PATH.DIREITOS_DO_TITULAR_LIST, icon: 'fa-stream'}),
          Menu({name: 'Tipos de Processos', path: ROUTE_PATH.TIPO_PROCESSO_LIST, icon: 'fa-stream'}),
          Menu({name: 'Tipos de Políticas', path: ROUTE_PATH.TIPO_POLITICA_LIST, icon: 'fa-stream'}),
          Menu({name: 'Tipos de Armazenamento', path: ROUTE_PATH.TIPO_ARMAZENAMENTO_LIST, icon: 'fa-stream'}),
          Menu({name: 'Tipos de Segurança', path: ROUTE_PATH.TIPO_SEGURANCA_LIST, icon: 'fa-stream'}),
          Menu({name: 'Tipos de Sistemas', path: ROUTE_PATH.SISTEMAS_LIST, icon: 'fa-stream'}),
          Menu({name: 'Tipos de Dados Sensíveis', path: ROUTE_PATH.DADOS_SENSIVEIS_LIST, icon: 'fa-stream'}),
          Menu({name: 'Fonte de Dados', path: ROUTE_PATH.FONTE_DE_DADOS_LIST, icon: 'fa-stream'}),
        ], roles: [Roles.ADMIN]
      })
    ], roles: [Roles.ADMIN]
  }),

  Menu({
    name: 'Treinamentos', path: '/treinamentos',
    icon: 'fa-book-open', children: [
      Menu({name: 'Mídias', path: ROUTE_PATH.ARQUIVOS_LIST, icon: 'fa-photo-video', roles: [Roles.USER]}),
      Menu({name: 'Modelo do Certificado', path: ROUTE_PATH.CERTIFICADO_TEMPLATE_FORM, icon: 'fa-object-group', roles: [Roles.USER]}),
      Menu({name: 'Cursos', path: ROUTE_PATH.CURSOS_LIST, icon: 'fa-chalkboard', roles: [Roles.USER]}),
      Menu({name: 'Aplicação dos Cursos', path: ROUTE_PATH.TREINAMENTO_LIST, icon: 'fa-chalkboard-teacher'}),
      Menu({name: 'Certificados', path: ROUTE_PATH.CERTIFICADO_LIST, icon: 'fa-certificate', roles: [Roles.USER]}),
    ], roles: [Roles.USER]
  }),

  Menu({
    name: 'Controles LGPD', path: '/controles',
    icon: 'fa-cogs', children: [

      //Menu({name: 'Riscos e Impactos', path: ROUTE_PATH.RISCO_IMPACTO_LIST, icon: 'fa-user-lock'}),
      Menu({name: 'Fluxo de Dados', path: ROUTE_PATH.PROCESSO_LIST, icon: 'fa-stream'}),
      Menu({name: 'Políticas de Dados', path: ROUTE_PATH.POLICY_LIST, icon: 'fa-user-lock'}),
      // Menu({name: 'Procedimentos', path: ROUTE_PATH.PROCEDIMENTOS_LIST, icon: 'fa-project-diagram'}),
      Menu({name: 'RIPD', path: ROUTE_PATH.RIPD_LIST, icon: 'fa-stamp', roles: [Roles.USER]}),
      Menu({name: 'Auditorias', path: ROUTE_PATH.AUDITORIAS_LIST, icon: 'fa-user-lock'}),
      Menu({name: 'Incidentes', path: ROUTE_PATH.INCIDENTES_LIST, icon: 'fa-exclamation-triangle', roles: [Roles.ADMIN]}),
      Menu({name: 'Gestão de Risco', path: ROUTE_PATH.GESTAO_RISCO_LIST, icon: 'fa-exclamation'}),
      Menu({
        name: 'Questionários', path: '/controles/list/questionario',
        icon: 'fa-address-book', children: [
          Menu({name: 'Perguntas', path: ROUTE_PATH.PERGUNTAS_LIST, icon: 'fa-question'}),
          Menu({name: 'Questionários', path: ROUTE_PATH.QUESTIONARIO_LIST, icon: 'fa-file-archive'}),
        ]
      }),
      Menu({
        name: 'Levantamento de Dados', path: '/controles/list/levantamentos',
        icon: 'fa-comment-dots', children: [
          Menu({name: 'Aplicação de Questionários', path: ROUTE_PATH.CAMPANHAS_LIST, icon: 'fa-comment-dots'}),
        ]
      }),
      Menu({name: 'Fornecedores e Parceiros', path: ROUTE_PATH.FORNECEDORES_LIST, icon: 'fa-building', roles: [Roles.SUPER]}),
      Menu({name: 'Contratos Vigentes', path: ROUTE_PATH.CONTRATOS_VIGENTES_LIST, icon: 'fa-file-signature'}),
    ], roles: [Roles.ADMIN]
  }),

  Menu({
    name: 'Templates', path: '/templates',
    icon: 'fa-clipboard', children: [
      Menu({name: 'Template Base Legal', path: ROUTE_PATH.TEMPLATE_BASE_LEGAL_LIST, icon: 'fa-gavel', roles: [Roles.SUPER]}),
      Menu({name: 'Template Direitos do Titular', path: ROUTE_PATH.TEMPLATE_DIREITOS_DO_TITULAR_LIST, icon: 'fa-file-contract', roles: [Roles.SUPER]}),
      Menu({name: 'Template Fonte de Dados', path: ROUTE_PATH.TEMPLATE_FONTE_DE_DADOS_LIST, icon: 'fa-database', roles: [Roles.SUPER]}),
    ], roles: [Roles.SUPER]
  }),

  Menu({
    name: 'LAI', path: '/lai-acesso', icon: 'fa-gavel', children: [
      Menu({name: 'Solicitações', path: ROUTE_PATH.SOLICITACAO_LIST, icon: 'fa-mail-bulk'}),
      Menu({name: 'Modelo de Prorrogação', path: ROUTE_PATH.MODELO_PRORROGACAO_LIST, icon: 'fa-object-group'}),
    ], roles: [Roles.LAI]
  }),

  Menu({name: 'Usuários', path: ROUTE_PATH.USER_LIST, icon: 'fa-users', roles: [Roles.ADMIN]}),

  Menu({name: 'Planos de Ação', path: ROUTE_PATH.KANBAN_BOARD, icon: 'fa-tasks', roles: [Roles.USER]}),

  Menu({name: 'Portal Compliance', path: ROUTE_PATH.PORTAL_COMPLIANCE, icon: 'fa-tasks', newTab: true, roles: [Roles.USER]}),
  Menu({name: 'Portal LAI', path: ROUTE_PATH.PORTAL_LAI, icon: 'fa-pager', newTab: true,roles: [Roles.LAI]}),
  //Menu({name: 'Prorrogação', path: ROUTE_PATH.PRORROGACAO_FORM, icon: 'fa-tasks'}),

  // Menu({
  //   name: 'Prorrogação', path: '#',
  //   icon: 'fa-tasks', children: [
  //     Menu({name: 'Prorrogação', path: ROUTE_PATH.PRORROGACAO_FORM, icon: 'fa-tasks'}),
  //     Menu({name: 'Prorrogação', path: ROUTE_PATH.PRORROGACAO_LIST, icon: 'fa-tasks'}),
  //   ]
  // }),

];
