import Editor from "../../../components/editor/Editor";

const ComentarioFormSchema = () => {

  return {

    getTitle() {
      return 'Novo Comentário';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/solicitacoes/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/solicitacoes/${id || ''}/comentar`;
    },

    getSchema() {
      return {
        title: "Comentario",
        type: "object",
        properties: {
          private: {type: 'boolean', title: 'Privado', default: true},
          content: {
            type: "string",
            title: "Comentário"
          }
        }
      };
    },

    getUiSchema: () => {
      return {
        content: {
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title='Comentário'
                onChange={props.onChange}
                initialValue={props.formData}
              />
            )
          }
        },
      }
    }
  }
}

export default ComentarioFormSchema;
