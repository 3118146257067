import {useHistory, useParams} from "react-router-dom";
import {Form} from "@rjsf/bootstrap-4";
import {Button, Card, Col, Row} from "react-bootstrap";
import {AppButton} from "../index";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import React, {useEffect, useState} from "react";
import {saveFormData} from "../../modules/page-form/PageFormService";
import {ArrayFieldTemplate} from "../arrayfieldtemplate/ArrayFieldTemplate";

const replaceMessage = (text) => (text
  // .replace('.questionario.id is a required property', 'Questionário deve ser preenchido')
  // .replace('.questionario should be object', 'Questionário deve ser preenchido')
  // //-- setores --//
  // .replace('.responsavel should be object', 'Usuário deve ser preenchido')
  // .replace('responsavel should be object', 'Usuário deve ser preenchido')
  // .replace('.funcionarios is a required property', 'Funcionários deve ser preenchido')
  // .replace('.setores is a required property', 'Setores deve ser preenchido')
  // .replace('.responsavel.id is a required property', 'Usuário deve ser preenchido')
  // //-- Pessoas --//
  // .replace('.cargo.id is a required property', 'Cargo deve ser preenchido')
  // .replace('.cargo should be object', 'Cargo deve ser preenchido')
  // //-- Entidade --//
  // .replace('.registrationNumber should match pattern "\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}"', 'CPF deve corresponder a mascara padrão 000.000.000-00')
  // .replace('.registrationNumber should match pattern "\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}-\\d{2}"', 'CNPJ deve corresponder a mascara padrão 00.000.000/0000-00')
  // // .replace('should match pattern "\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}-\\d{2}"', '')
  // // .replace('should match pattern "\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}"', '')
  // .replace('should be array', 'deve ser preenchido')
  //
  // //-- Fluxo de dados --//
  // .replace('.setor.id is a required property', 'Setores deve ser preenchido')
  // .replace('.processoText is a required property', 'Descrição do Processo deve ser preenchido')
  // //-- Politica/Procedimentos --//
  // .replace('.tipoPolitica.id is a required property', 'Tipo de Política deve ser preenchido')
  // .replace('.politicaText is a required property', 'Política deve ser preenchido')
  // //-- Gestão de Risco --//
  // .replace('.processo.id is a required property', 'Processos deve ser preenchido')
  // .replace('.funcionario.id is a required property', 'Pessoas deve ser preenchido')
  // .replace('.categorias.id is a required property', 'Categoria deve ser preenchido')
  // //-- Perguntas --//
  // .replace('.escolhas should NOT have fewer than 2 items', 'Deve conter no minímo 2 opções de resposta')
  //
  .replace('is a required property', 'Este campo é obrigatório')
  .replace('responsavel:', 'Usuário')
  .replace('should be string', 'deve ser um texto')
  .replace('should NOT have fewer than 2 items', 'Deve conter no minímo 2 opções')
  .replace('should be object', 'Este campo é obrigatório')
  .replace('should be integer', 'O numero deve ser inteiro')
  .replace('should be >= ', 'Deve ser maior ou igual a ')

)

const replaceProperty = (text) => {
  return text
  .replace('.funcionarios', 'Usuário')
  .replace('.responsavel', 'Pessoas')
  .replace('.setor', 'Setor')
  .replace('.tipoPrestador', 'Tipo de Prestador')
  .replace('.escolhas', 'Escolhas')
  .replace('.cargo', 'Cargo')
  .replace('.arquivo', 'Arquivo')
  .replace('.assinaturas', 'Assinaturas')
  .replace('.logo', 'Logo')
  .replace('.titulo', 'Título')
  .replace('.curso', 'Curso')
  .replace('.durationInMounths', 'Duração em Meses')
};

const transformErrors = (errors) => {
  let data = errors.map(error => {
    // console.group('Tradução');

    let message = replaceMessage(error.message);
    let stack = replaceProperty(replaceMessage(error.stack));

    // console.log('Message: Traduzindo ', error.message, ' -> ', message);
    // console.log('Stack: Traduzindo ', error.stack, ' -> ', stack);
    // console.groupEnd();

    error.message = message;
    error.stack = stack;
    return error;
  });
  return data;
}

const CustomForm = ({entityName, defaultValue, entitySchema, disabled, originFormData}) => {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(defaultValue);
  let _formdata = defaultValue;
  let {id} = useParams();

  const doPost = async (entity) => {
    try {
      const _id = entity.id || id;
      setIsLoading(true);
      setFormData(entity);
      const postUrl = entitySchema.getSaveUrl(_id, entity);

      if (_id) {
        return doPut(postUrl, entity)
      }

      const newEntity = await HttpRequest.post(postUrl, entity);

      Notify.success('Registro criado com sucesso!');
      entitySchema.actionPostSave ? entitySchema.actionPostSave(history, newEntity) : history.goBack()
    } catch (e) {
      Notify.error('Falha ao salvar', e);
    } finally {
      setIsLoading(false);
    }
  }

  const doPut = async (url, entity) => {
    try {
      await HttpRequest.put(url, entity);
      Notify.success('Registro alterado com sucesso!');
      if (entitySchema.goNewPage) {
        entitySchema.goNewPage(history, entity);
        return;
      }
      history.goBack();
    } catch (e) {
      Notify.error('Falha ao salvar', e);
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = (evt) => {
    let eventToSave = evt.formData;
    eventToSave = removeOptionalEmptyValues(eventToSave);
    if (entitySchema.formatObjectToSave) {
      eventToSave = entitySchema.formatObjectToSave(eventToSave, originFormData);
    }
    doPost(eventToSave);
  }

  const removeOptionalEmptyValues = (data) => {
    for (let field in data) {
      let value = data[field];
      if (!value) {
        continue;
      }
      if (typeof value !== 'object') {
        continue;
      }
      let isEmpty = Object.keys(value).length === 0;
      if (isEmpty) {
        data[field] = undefined;
      }
    }
    return data;
  }

  const onChange = (e) => {
    const data = removeOptionalEmptyValues(e.formData);
    _formdata = e.formData;
    saveFormData(entityName, data);
  }

  function Object_assign(target, ...sources) {
    sources.forEach(source => {
      Object.keys(source).forEach(key => {
        const s_val = source[key]
        const t_val = target[key]

        if (s_val && !t_val) {
          target[key] = s_val;
        } else if (t_val && s_val && typeof s_val === 'object') {
          target[key] = {...t_val, ...s_val}
        } else if (s_val) {
          target[key] = s_val
        } else if (!s_val && s_val !== t_val) {
          target[key] = s_val;
        }
      })
    })
    return target
  }

  const putCustomField = (data) => {
    const newForm = Object_assign({...formData, ..._formdata}, data);
    setFormData({...newForm});
  }

  const schema = entitySchema.getSchema();

  useEffect(() => {
    let fields = schema.properties
    for (let key in fields) {
      if (fields[key]?.format === 'date') {
        let minDate = document.getElementById('root_' + key)
        minDate?.setAttribute("min", '1970-01-01')
      }
    }
  }, [schema.properties])

  return <Form disabled={disabled || isLoading}
               schema={schema}
               showErrorList={false}
               transformErrors={transformErrors}
               formContext={{putCustomField}}
               formData={formData}
               validate={entitySchema.validate}
               ArrayFieldTemplate={ArrayFieldTemplate}
               uiSchema={entitySchema.getUiSchema()}
               fields={entitySchema.getFields()}
               onChange={onChange}
               onSubmit={onSubmit}>
    <Card.Footer style={{margin: '-1.25rem'}}>
      <Row>
        <Col>
          <Button size="sm" disabled={disabled || isLoading} variant="light" type="reset" onClick={history.goBack}>Cancelar</Button>
        </Col>
        <Col className="text-right">
          <AppButton type="submit" isLoading={disabled || isLoading}>
            <i className="fas fa-save"></i>
            &nbsp;&nbsp;
            Salvar
          </AppButton>
        </Col>
      </Row>
    </Card.Footer>
  </Form>;

}

export default CustomForm;
