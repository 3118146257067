import React, {useEffect, useState} from 'react'
import HorizontalBarChart from "../../../components/chart/HorizontalBarChart";

function PessoaCargoSetoresChart({data}) {

  const [personHorizontalBarChartData, setPersonHorizontalBarChartData] = useState(null);

  const handleEditPersonChartYAxesLabels = (index) => {
    return personHorizontalBarChartData.labels[index]
  }

  const mountPersonHorizontalBarChartData = (data) => {
    setPersonHorizontalBarChartData({
      labels: ['Pessoas', 'Setores', 'Cargos'],
      chartTitle: "Pessoas / Cargos / Setores",
      datasets: [
        {
          data: [data.totalPessoas, data.totalCargos, data.totalSetores],
          borderColor: 'rgb(0,255,255)',
          backgroundColor: 'rgba(0,255,255,0.4)',
        }
      ]
    })
  }

  useEffect(() => {
    if (data) {
      mountPersonHorizontalBarChartData(data);
    }
  }, [data])

  return (
    <HorizontalBarChart
      {...personHorizontalBarChartData}
      callbackToEditYAxesLabels={handleEditPersonChartYAxesLabels}
    />
  )
}

export default PessoaCargoSetoresChart
