import FuncionarioSelector from "../../pessoas/component/FuncionarioSelector";

export const EncaminharSolicitacaoFormSchema = () => {

  return {
    getTitle() {
      return 'Encaminhar solicitação ao responsável';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/solicitacoes/${id}`;
    },

    getSaveUrl(id, entity) {
      return `optima-lgpd/v1/solicitacoes/${id}/encaminhar/funcionarios/${entity.responsavel.id}`;
    },

    getSchema() {
      return {
        title: "Encaminhar Solicitação",
        type: "object",
        required: ["responsavel"],
        properties: {
          responsavel: {
            type: "object",
            title: "Responsável",
            properties: {
              id: {type: "string"}
            }
          },
        },
      };
    },

    getUiSchema: () => {
      return {
        "responsavel": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (<FuncionarioSelector disabled={props.disabled} required={props.required}
                                         title={props.schema.title}
                                         value={props.formData} setValue={props.onChange}/>
            );
          }
        },
      }
    },
  }
}


