import SetorSelector from "../setor/component/SetorSelector";
import PoliticaSelector from "../politicas/component/PoliticaSelector";
import TipoProcessoSelector from "../tipo-processo/componenet/TipoProcessoSelector";
import TipoArmazenamentoSelector from "../tipo-armazenamento/componenet/TipoArmazenamentoSelector";
import TipoSistemaSelector from "../sistemas/component/TipoSistemaSelector";
import TipoSegurancaSelector from "../seguranca/component/TipoSegurancaSelector";
import MeioTransmissaoSelector, {TRANSMISSION_TYPES} from "../processo/component/MeioTransmissaoSelector";
import Editor from "../../components/editor/Editor";

const ProcedimentoFormSchema = () => {
  return {
    save(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    },

    getTitle(isNew) {
      return isNew ? 'Novo Procedimento' : 'Editar Procedimento';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/processos/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/processos/${id || ''}`;
    },

    formatObjectToGet(entity) {
      if (!entity.meioTransmissao) {
        return;
      }
      entity.meioTransmissao = entity.meioTransmissao.map(x => {
        return TRANSMISSION_TYPES.filter(y => y.id === x)[0];
      });

      return entity;
    },

    formatObjectToSave(entity) {
      entity.processoType = 'PROCEDIMENTO';
      entity.meioTransmissao = entity.meioTransmissao?.map(x => x.label);
      return entity;
    },

    getSchema() {
      return {
        title: "Procedimentos",
        type: "object",
        required: [
          "nome",
          "processoText",
          "setor"
        ],
        properties: {
          nome: {type: "string", title: "Nome"},
          tipoDeProcessos: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Tipo de Processo"},
              }
            }
          },
          proposito: {type: "string", title: "Propósito"},
          procedimento: {type: "string", title: "Procedimento"},
          setor: {
            type: 'object',
            properties: {
              id: {type: "string", title: "Setor"},
            }
          },
          politicas: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Políticas"},
              }
            }
          },
          meioTransmissao: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Meio de Transmissão"},
              }
            }
          },
          tipoArmazenamento: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Tipo de Armazenamento"},
              }
            }
          },
          sistemasEnvolvidos: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Tipo de Sistema"},
              }
            }
          },
          tipoDeSegurancas: {
            type: 'array',
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Tipo de Segurança"},
              }
            }
          },
          processoText: {
            type: "string",
            title: "Descrição do Processo"
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "ui:order": [
          "nome",
          "tipoDeProcessos",
          "setor",
          "processoText",
          "proposito",
          "procedimento",
          "tipoArmazenamento",
          "meioTransmissao",
          "sistemasEnvolvidos",
          "tipoDeSegurancas",
          "politicas",
        ],
        "nome": {
          "ui:autofocus": true,
          "ui:help": "Nome do procedimento ou atividade que está sendo mapeada",
        },
        "proposito": {
          "ui:help": "Qual o motivo dessa atividade."
        },
        "procedimento": {
          "ui:help": "Existe um procedimento documentado dessa atividade que explica passo a passo como \n"
            + "executar o processo/atividade? Se sim, informe o nome do procedimento."
        },
        "setor": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<SetorSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
        "tipoDeProcessos": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<TipoProcessoSelector disabled={props.disabled}
                                          required={props.required}
                                          title={props.title}
                                          value={props.formData}
                                          setValue={props.onChange}/>
            );
          }
        },
        "politicas": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PoliticaSelector isMulti={true} disabled={props.disabled}
                                      required={props.required}
                                      title={props.title}
                                      value={props.formData}
                                      setValue={props.onChange}/>
            );
          }
        },
        "tipoArmazenamento": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<TipoArmazenamentoSelector isMulti={true}
                                               disabled={props.disabled}
                                               required={props.required}
                                               title={props.title}
                                               value={props.formData}
                                               setValue={props.onChange}/>
            );
          }
        },
        "meioTransmissao": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            console.log(props);
            return (<MeioTransmissaoSelector isMulti={true}
                                             disabled={props.disabled}
                                             required={props.required}
                                             title={props.title}
                                             value={props.formData}
                                             setValue={props.onChange}/>
            );
          }
        },
        "sistemasEnvolvidos": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (
              <TipoSistemaSelector isMulti={true} disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
        "tipoDeSegurancas": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (
              <TipoSegurancaSelector isMulti={true} disabled={props.disabled}
                                     required={props.required}
                                     title={props.title}
                                     value={props.formData}
                                     setValue={props.onChange}/>
            );
          }
        },
        "processoText": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title={props.schema.title}
                initialValue={props.formData}
                onChange={props.onChange}
                initProps={{
                  placeholder: "Descreva o processo do início ao fim, ou seja, como ela inicia, quais os passos da\n"
                    + "atividade/processo e como é finalizado. Seja o mais detalhado possível."
                }}
              />
            );
          }
        }
      }
    }
  }
}

export default ProcedimentoFormSchema;

