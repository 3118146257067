import {SmallBox} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";

const FuncionarioCount = () => {

  const [total, setTotal] = useState('-');

  const loadTotal = async () => {
    try {
      const result = await HttpRequest.get('optima-lgpd/v1/funcionarios/count');
      setTotal(result.total);
    } catch (e) {
      Notify.error('Falha ao contar pessoas', e);
    }
  }

  useEffect(() => {
    loadTotal();
  }, [])

  return <SmallBox
    type="primary"
    icon="fa fa-user"
    count={total} title="Pessoas Disponíveis"
    titlebox={"Pessoas"}
    navigateTo={ROUTE_PATH.FUNCIONARIO_LIST}/>

}

export default FuncionarioCount;
