import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const ArquivoSelector = ({
  required = false,
  isMulti = false,
  defaultValue,
  title = "Arquivos",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `optima-lgpd/v1/galeria-arquivos?sort=titulo,ASC&titulo=${filterValue}`;
  }


  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="titulo"
    getUrl={getUrl}
    setValue={setValue}
    isMulti={isMulti}
    pathToCreate={ROUTE_PATH.ARQUIVOS_FORM}
    {...props}
  />

}

export default ArquivoSelector;
