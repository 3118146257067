import {useState} from "react";
import Notify from "../../../shared/common/notify/Notify";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import {Card, Form, InputGroup} from "react-bootstrap";
import {AppButton, InputGroupText} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {useHistory} from "react-router-dom";

const UserDetailsPasswordForm = () => {

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword1, setNewPassword1] = useState();
  const [newPassword2, setNewPassword2] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (newPassword1 !== newPassword2) {
      Notify.warning('Senhas não conferem');
      return false;
    }

    setIsLoading(true);
    try {
      await HttpRequest.post('optima-auth/v1/users/change-password', {currentPassword: currentPassword, newPassword: newPassword1});
      Notify.success('Senha atualizada');
      history.push(ROUTE_PATH.HOME);
    } catch (e) {
      Notify.error('Falha ao atualizar senha', e);
      setIsLoading(false);
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <Card className="card-primary">
        <Card.Header>
          <Card.Title>Senha</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroupText required id="currentPassword" label="Senha atual" type="password"
                          placeholder="Senha atual" onChangeText={setCurrentPassword}
                          rightAppend={<InputGroup.Text><i className="fas fa-lock"/></InputGroup.Text>}
          />
          <InputGroupText minLength={6} required id="newPassword" label="Nova senha" type="password"
                          placeholder="Nova senha" onChangeText={setNewPassword1}
                          rightAppend={<InputGroup.Text><i className="fas fa-lock"/></InputGroup.Text>}
          />
          <InputGroupText minLength={6} required id="newPassword2" label="Confirmação de senha" type="password"
                          placeholder="Digite a nova senha novamente" onChangeText={setNewPassword2}
                          rightAppend={<InputGroup.Text><i className="fas fa-lock"/></InputGroup.Text>}
          />
        </Card.Body>
        <Card.Footer className="text-right">
          <AppButton type="submit" isLoading={isLoading}/>
        </Card.Footer>
      </Card>
    </Form>

  )

};
export default UserDetailsPasswordForm;
