export const Arquivo = ({
  arquivo
}) => {

  if (!arquivo) {
    return '';
  }

  if (!arquivo.arquivo) {
    return '';
  }
  let arq = arquivo.arquivo;
  return (<a href={arq.publicUrl}>
      {arq.nomeOriginal}
    </a>
  )
}
