import {Page} from "../../components/page/Page";
import {useEffect, useState} from "react";
import {DefaultListSchema, getEntityListSchema} from "./EntrityListProvider";
import ListHeader from "./component/ListHeader";
import {Card, Col, Row} from "react-bootstrap";
import CustomTable from "./component/CustomTable";

const ListPage = ({match}) => {

  const [entitySchema, setEntitySchema] = useState({
    header: {
      showNewButton: true,
      linkNewUrl: '#'
    },
    schema: {
      title: '-',
      getEndpoint: () => null,
      getColumns: () => []
    }
  });

  useEffect(() => {
    const entityName = match.params?.entity;
    if (!entityName) {
      console.log('Sem entidade');
      return;
    }
    const entityListSchema = getEntityListSchema(entityName);
    const schema = {...DefaultListSchema, ...entityListSchema()};
    const header = schema.getHeader();
    setEntitySchema({schema, header});
  }, [match.params.entity])

  return (<Page title={entitySchema.schema.title}>
    <Card>
      <Card.Body>
        <ListHeader headerConfig={entitySchema.header}/>
        <Row>
          <Col>
            <CustomTable entitySchema={entitySchema.schema}/>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Page>)

}

export default ListPage;
