import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {TableButton} from "./TableButton";

const ResendEmailButton = ({
  entity = "",
  endpointToBlock = "",
  tableProps = null,
  row = null,
  ...props
}) => {

  const resendActivationEmail = async (entity, tableProps,) => {
    try {
      await HttpRequest.post(
          `optima-auth/v1/accounts/reset-password/init`,
          {email: entity.email})
      Notify.success(`Email enviado para: ${entity.email}`)
      tableProps.setGlobalFilter(new Date());
    } catch (e) {
      console.log(e);
      Notify.error(`Falha ao enviar email ${entity.email}: `, e);
    }
  };

  return (
      <TableButton {...props} variant={'primary'}
                   text={'Resetar senha'}
                   title={'Resetar senha '}
                   onClick={() => resendActivationEmail(row.original,
                       tableProps)}
                   icon={'fas fa-key'}/>)

}

export default ResendEmailButton;
