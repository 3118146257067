import React from 'react';
import {ArcElement, Chart as ChartJS, Legend, Title, Tooltip} from 'chart.js';
import {Pie} from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

function PieChart({labels, datasets = [], chartTitle = 'iasjd'}) {
  const data = {
    labels,
    datasets
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: chartTitle
      }
    }
  }

  return <Pie data={data} options={options}/>;
}

export default PieChart
