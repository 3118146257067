import OptionColumn from "../../../components/table/OptionColumn";
import {dayMonthYear} from "../../../shared/utils/dates";
import {SelectColumnFilter} from "../../../components/table/TableFilter";
import SolicitacaoStatusSelector, {SolicitacaoStatus} from "./component/SolicitacaoStatusSelector";

const SolicitacoesDeInformacaoListSchema = () => {

  return {
    title: 'Lista de Solicitações',

    getEndpoint: () => `optima-lgpd/v1/solicitacoes`,

    getHeader: () => {

      return {
        showNewButton: false
      }
    },

    getColumns: () => {
      return [
        {
          Header: "ID",
          accessor: "id",
          Filter: false,
        },
        {
          Header: "Título",
          accessor: "titulo",
        },
        {
          Header: "Solicitante",
          accessor: "nome",
        },
        {
          Header: "Status",
          accessor: "status",
          Filter: SelectColumnFilter,
          CustomSelector: SolicitacaoStatusSelector,
          Cell: ({row}) => <SolicitacaoStatus status={row.original.status}/>
        },
        {
          Header: "Data da Solicitação",
          Cell: ({row}) => row?.original.createdAt && dayMonthYear(row?.original.createdAt)
        },
        OptionColumn({
          showEdit: false,
          showDelete: false,
          showSolicitation: true,
        }),
      ]
    },
  }
}

export default SolicitacoesDeInformacaoListSchema;

