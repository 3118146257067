import {SmallBox} from "../../../components";
import React, {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";
import {useHistory} from "react-router-dom";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {Col} from "react-bootstrap";

const DEFAULT_BASE_LEGAL = [
  {nome: 'Confirmação da existência de tratamento'},
  {nome: 'Consentimento'},
  {nome: 'Cumprimento de obrigação legal ou regulatória pelo controlador'},
  {nome: 'Pela administração pública, para o tratamento e uso compartilhado de dados necessários à execução de políticas públicas previstas em leis e regulamentos ou respaldadas em contratos, convênios ou instrumentos congêneres'},
  {nome: 'Para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais'},
  {nome: 'Execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados'},
  {nome: 'Exercício regular de direitos'},
  {nome: 'Proteção da vida ou da incolumidade física do titular ou de terceiro'},
  {nome: 'Tutela da saúde, exclusivamente, em procedimento realizado por profissionais de saúde, serviços de saúde ou autoridade sanitária'},
  {nome: 'Legítimo interesse'},
  {nome: 'Proteção do crédito'},
]

const BaseLegalDoTitularWizard = () => {

  const [total, setTotal] = useState(1);
  const history = useHistory();

  const loadTotal = async () => {
    try {
      const result = await HttpRequest.get('optima-lgpd/v1/bases-legais/count');
      setTotal(result.total);
    } catch (e) {
      Notify.error('Falha ao contar as Bases Legais', e);
    }
  }

  useEffect(() => {
    loadTotal();
  }, []);

  const insertDefaultData = async (e) => {
    try {
      e.preventDefault();
      console.log('import data');
      await HttpRequest.post('optima-lgpd/v1/dados-iniciais/base-legal', DEFAULT_BASE_LEGAL);
      Notify.success('Bases Legais importadas!');
      history.push(ROUTE_PATH.BASE_LEGAL_LIST);
    } catch (e) {
      Notify.error('Falha ao importar as Bases Legais', e);
    }

  };

  if (total > 0) {
    return <></>;
  }

  return <Col sm={3} md={3}>
    <SmallBox
      type="primary"
      count={total} title="Ops, Vejo que você ainda não tem a Base Legal do Titular"
      titlebox={"Inserir a Base Legal do Titular?"}
      onClick={insertDefaultData}
      navigateTo=""/>
  </Col>

}

export default BaseLegalDoTitularWizard;
