import 'admin-lte/dist/js/adminlte'
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars'
import './BaseLayout.scss'

import {Nav} from "./Nav";
import {Sidebar} from "../sidebar/Sidebar";
import {Footer} from "./Footer";

export const BaseLayout = ({children}) => {
  return (
    <>
      <Nav/>
      <Sidebar/>
      <div className="content-wrapper">
        {children}
      </div>
      <Footer/>
    </>
  )
}
