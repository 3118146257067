import CurrentUserPage from "../../modules/user/CurrentUserPage";
import {Page404} from "../../components/page/Page404";
import DashboardPage from "../../modules/dashboard/DashboardPage";
import ListPage from "../../modules/page-list/ListPage";
import FormPage from "../../modules/page-form/FormPage";
import KanbanPage from "../../modules/kanban-page/KanbanPage";
import MenuListPage from "../../modules/menu-list/MenuListPage";
import {ROUTE_PATH} from "./RoutesPath";
import OrganogramaCargoPage from "../../modules/cargo/component/organograma-cargo-page/OrganogramaCargoPage";
import OrganogramaProcessoPage from "../../modules/processo/component/organograma-processo-page/OrganogramaProcessoPage";
import {SolicitacaoPage} from "../../modules/lai/solicitacao/SolicitacaoPage";

export const APP_ROUTES = [
  {
    path: ROUTE_PATH.DETALHES_SOLICITACAO,
    component: SolicitacaoPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.LIST_LAI_PAGE,
    component: ListPage,
    roles: ['ROLE_LAI']
  },
  {
    path: ROUTE_PATH.FORM_LAI_PAGE,
    component: FormPage,
    roles: ['ROLE_LAI']
  },
  {
    path: ROUTE_PATH.FORM_ID_LAI_PAGE,
    component: FormPage,
    roles: ['ROLE_LAI']
  },
  {
    path: ROUTE_PATH.LIST_CADASTROS_VARIAVEIS_PAGE,
    component: ListPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_CADASTROS_VARIAVEIS_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_ID_CADASTROS_VARIAVEIS_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.QUESTIONARIO_MENU_LIST,
    component: MenuListPage
  },
  {
    path: ROUTE_PATH.CADASTROS_MENU_LIST,
    component: MenuListPage
  },
  {
    path: ROUTE_PATH.TEMPLATES_MENU_LIST,
    component: MenuListPage
  },
  {
    path: ROUTE_PATH.TREINAMENTO_MENU_LIST,
    component: MenuListPage
  },
  {
    path: ROUTE_PATH.VARIAVEIS_MENU_LIST,
    component: MenuListPage
  },
  {
    path: ROUTE_PATH.CONTROLES_MENU_LIST,
    component: MenuListPage
  },
  {
    path: ROUTE_PATH.LEVANTAMENTO_MENU_LIST,
    component: MenuListPage
  },
  {
    path: ROUTE_PATH.LIST_TREINAMENTOS_PAGE,
    component: ListPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_TREINAMENTOS_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_ID_TREINAMENTOS_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.LIST_PAGE,
    component: ListPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.KANBAN_BOARD,
    component: KanbanPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.ORGANOGRAMA_CARGO_PAGE,
    component: OrganogramaCargoPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.ORGANOGRAMA_PROCESSO_PAGE,
    component: OrganogramaProcessoPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.LIST_CADASTROS_PAGE,
    component: ListPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_CADASTROS_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_ID_CADASTROS_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.LIST_TEMPLATES_PAGE,
    component: ListPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_TEMPLATES_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_ID_TEMPLATES_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.LIST_CONTROLES_PAGE,
    component: ListPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_CONTROLES_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_ID_CONTROLES_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },

  {
    path: ROUTE_PATH.LIST_LEVANTAMENT0_DADOS_PAGE,
    component: ListPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_LEVANTAMENTO_DADOS_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_ID_LEVANTAMENTO_DADOS_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },

  {
    path: ROUTE_PATH.LIST_QUESTIONARIO_PAGE,
    component: ListPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_QUESTIONARIO_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_ID_QUESTIONARIO_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.FORM_ID_PAGE,
    component: FormPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.HOME,
    component: DashboardPage,
    roles: ['ROLE_USER']
  },
  {
    path: ROUTE_PATH.USER_PROFILE,
    component: CurrentUserPage,
    roles: ['ROLE_USER']
  },
  {
    path: '*',
    component: Page404
  }

]





