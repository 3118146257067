import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const REGISTRATION_TYPES = [
  {id: 'CPF', label: 'Pessoa Física'},
  {id: 'CNPJ', label: 'Pessoa Jurídica '}
];

const RegistrationTypeSelector = ({
  required = false,
  title = "Tipo de Pessoa",
  setValue = null,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={REGISTRATION_TYPES}
    {...props}
  />
}

export default RegistrationTypeSelector;
