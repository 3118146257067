import React from 'react'
import Board from 'react-trello'

function KanbanBoard(props) {

  return (<Board style={{backgroundColor: '#f4f6f9'}} {...props} />)

}

export default KanbanBoard;
