import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {SelectColumnFilter} from "../../components/table/TableFilter";
import CargoSelector from "../cargo/component/CargoSelector";
import SetorSelector from "../setor/component/SetorSelector";

const FuncionarioListSchema = () => {

  const endpoint = 'optima-lgpd/v1/funcionarios';
  return {
    title: 'Lista de Pessoas',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.FUNCIONARIO_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "E-mail",
          accessor: "email"
        },
        {
          Header: "Nome",
          accessor: "nome"
        },
        {
          Header: "Cargo",
          urlValue: "cargos",
          endpoint: endpoint,
          accessor: "cargo.id",
          Filter: SelectColumnFilter,
          CustomSelector: CargoSelector,
          Cell: ({row}) => row.original.cargo?.nome || null
        },{
          Header: "Setor",
          urlValue: "setor",
          endpoint: endpoint,
          accessor: "setor.id",
          Filter: SelectColumnFilter,
          CustomSelector: SetorSelector,
          Cell: ({row}) => row.original.setor?.nome || null
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.FUNCIONARIO_FORM
        })
      ]
    },

  }
}

export default FuncionarioListSchema;

