import {Card, Col, Row, Tab} from "react-bootstrap";
import {AnexosPedido} from "./AnexosPedido";

export const DetalhesTab = ({eventKey, data}) => {

  return <Tab.Pane eventKey={eventKey} key={eventKey}>
    <Card>
      <Card.Body>
        <DetalhesDaSolicitacao data={data}/>
      </Card.Body>
    </Card>
  </Tab.Pane>
}

const CustomRow = ({title, docType, data, html, dataNumber}) => {
  return <Row className="p-2 mt-3 border-bottom">
    <Col>
      {title}
    </Col>
    <Col>
      <p>
        {data}
        {docType && docType + ' : ' + dataNumber}
      </p>
    </Col>
  </Row>
}

function returnRecebimento(data) {
  if (data.recebimentoResposta === 1) {
    return 'E-mail'
  }
  if (data.recebimentoResposta === 2) {
    return 'Correspondência Física (com custo)'
  }
  if (data.recebimentoResposta === 3) {
    return 'Buscar/Consultar pessoalmente'
  }
}

const DetalhesDaSolicitacao = (
  {
    data
  }
) => {
  return <>
    <form>
      <fieldset style={{border: "1px solid #dee2e6", borderRadius: "2px"}}>
        <legend className="pl-2 pr-2" style={{
          border: "2px",
          width: "auto",
          textAlign: "center"
        }}>
          Dados do requerente
        </legend>
        <div className="m-4">
          <CustomRow title="Nome" data={data.nome}/>
          <CustomRow title="E-mail" data={data.email}/>
          <CustomRow title="Documento" docType={data.documentoIdentificacao?.id} dataNumber={data.documentoIdentificacao?.value}/>
          <CustomRow title="Endereço" data={data.endereco.rua}/>
          <CustomRow title="Número" data={data.endereco.numero}/>
          <CustomRow title="Complemento" data={data.endereco.complemento}/>
          <CustomRow title="Bairro" data={data.endereco.bairro}/>
          <CustomRow title="CEP" data={data.endereco.cep}/>
          <CustomRow title="UF" data={data.endereco?.estado?.label}/>
          <CustomRow title="Município" data={data.endereco?.municipio?.label}/>
          <CustomRow title="Telefone" data={data.contato?.telefone}/>
          <CustomRow title="Celular" data={data.contato?.celular}/>
          <CustomRow title="Fax" data={data.contato?.fax}/>
          <CustomRow title="Forma preferencial de recebimento de resposta" data={returnRecebimento(data)}/>
        </div>
      </fieldset>
    </form>

    <EspecificacaoPedido data={data}/>
    <AnexosPedido arquivos={data.arquivos}/>

  </>
}

const EspecificacaoPedido = (
  {
    data
  }
) => {
  return <form>
    <fieldset style={{border: "1px solid #dee2e6", borderRadius: "2px"}}>
      <legend className="pl-2 pr-2" style={{
        border: "2px",
        width: "auto",
        textAlign: "center"
      }}>
        Especificações do Pedido
      </legend>
      <div className="m-4">
        <Row className="p-2 mt-3 border-bottom">
          <Col>
            <p dangerouslySetInnerHTML={{__html: data?.especificacaoPedido}}/>
          </Col>
        </Row>
      </div>
    </fieldset>
  </form>
}
