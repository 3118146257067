import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const PerguntasSelector = ({
  required = false,
  isMulti = false,
  defaultValue,
  title = "Perguntas",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `optima-lgpd/v1/perguntas?sort=pergunta,ASC&pergunta=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="pergunta"
    getUrl={getUrl}
    setValue={setValue}
    isMulti={isMulti}
    pathToCreate={ROUTE_PATH.PERGUNTAS_FORM}
    {...props}
  />

}

export default PerguntasSelector;
