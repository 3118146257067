import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";
import QuestionarioSelector from "../questionario/component/QuestionarioSelector";
import SetorSelector from "../setor/component/SetorSelector";
import moment from "moment";

const CampanhaFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Nova Campanha' : 'Editar Campanha';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/campanhas/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/campanhas/${id || ''}`;
    },

    validate(formData, errors) {
      if (moment(formData.comeco).isBefore(formData.fim) === false) {
        errors.fim.addError("Data final deve ser maior que a data inicial!")
      }
      return errors
    },

    getSchema() {
      return {
        type: "object",
        required: ["nome", "comeco", "fim", "questionario", "setores", "funcionarios"],
        properties: {
          nome: {type: "string", title: "Nome"},
          comeco: {type: "string", "format": "date", title: "Data de Inicio"},
          fim: {type: "string", "format": "date", title: "Data fim da Campanha"},
          questionario: {
            type: 'object',
            properties: {
              id: {type: "string",},
            }
          },
          setores: {
            type: 'array',
            items: {
              id: {type: "string",},
            }
          },
          funcionarios: {
            type: 'array',
            title: 'Pessoas',
            items: {
              id: {type: "string",},
            }
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
          "ui:placeholder": "Nome da Campanha",
        },
        "funcionarios": {
          "ui:field": (props) => {
            return (<FuncionarioSelector disabled={props.disabled} isMulti={true} required={props.required}
                                         title={props.schema.title}
                                         value={props.formData} setValue={props.onChange}/>
            );
          }
        },
        "questionario": {
          "ui:field": (props) => {
            return (<QuestionarioSelector disabled={props.disabled} required={props.required}
                                          title={props.title}
                                          value={props.formData} setValue={props.onChange}/>
            );
          }
        },
        "setores": {
          "ui:field": (props) => {
            return (<SetorSelector disabled={props.disabled} isMulti={true} required={props.required}
                                   title={props.title}
                                   value={props.formData} setValue={props.onChange}/>
            );
          }
        }
      }
    }
  }
}

export default CampanhaFormSchema;

