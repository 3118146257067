import React from "react";
import {Button} from "react-bootstrap";

export const ArrayFieldTemplate = (props) => {
  return (
    <div className={props.className}>
      {props.items &&
      props.items.map(element => (
        <div key={element.key} className={element.className}>
          <div>{element.children}</div>
          {element.hasMoveDown && (
            <p
              onClick={element.onReorderClick(
                element.index,
                element.index + 1
              )}>
            </p>
          )}
          {element.hasMoveUp && (
            <p
              onClick={element.onReorderClick(
                element.index,
                element.index - 1
              )}>
            </p>
          )}
          <Button onClick={element.onDropIndexClick(element.index)} className={"m-1"} variant={"danger"}>
            Remover
          </Button>
          <hr/>
        </div>
      ))}

      {props.canAdd && (
        <div className="row m-1">
          <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
            <Button onClick={props.onAddClick} type="button">
              {"Adicionar " + props.title}
            </Button>
          </p>
        </div>
      )}
    </div>
  );
}
