import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const PoliticaSelector = ({
  required = false,
  defaultValue,
  title = "Política",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `optima-lgpd/v1/politicas?sort=nome,ASC&nome=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="nome"
    getUrl={getUrl}
    setValue={setValue}
    pathToCreate={ROUTE_PATH.POLICY_FORM}
    {...props}
  />

}

export default PoliticaSelector;
