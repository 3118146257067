import {TipoAlerta} from "./TipoEnum";

export const TipoAlertaText = ({tipo}) => {

  const getTipoText = () => {
    switch (tipo) {
      case TipoAlerta.SEM_DPO:
        return 'Está empresa ainda não tem um DPO configurado!';
      default:
        return 'Tipo não mapeado: ' + tipo;
    }
  }

  return <p>{getTipoText()}</p>

}
