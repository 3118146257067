import {TipoAlertaText} from "./component/TipoAlertaText";
import OptionColumn from "../../components/table/OptionColumn";
import {TipoAlertaAcao} from "./component/TipoAlertaAcao";

const AlertaListSchema = () => {

  const endpoint = 'optima-lgpd/v1/alertas';

  return {
    title: 'Lista de Alertas',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        showNewButton: false
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Tipo",
          accessor: "tipo",
          Cell: ({row}) => <TipoAlertaText tipo={row.original.tipo}/>
        },
        OptionColumn({
            showEdit: false, showDelete: false,
            children: ({row}) => <TipoAlertaAcao tipo={row.original.tipo}/>
          }
        )
      ]
    },

  }
}

export default AlertaListSchema;

