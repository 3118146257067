import {Col, Row} from "react-bootstrap";
import {ReactTable} from "../../../components";
import {useCallback, useEffect, useState} from "react";
import {doHttpGetListTable} from "../ListHttpService";
import Notify from "../../../shared/common/notify/Notify";

const defaultState = {
  content: [],
  pageCount: 0,
};
const CustomTable = ({entitySchema}) => {

  const [data, setData] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const cols = entitySchema.getColumns()
    setColumns(cols)
    setData(defaultState);
  }, [entitySchema]);

  const fetchDate = useCallback(async (props) => {
    try {
      setIsLoading(true);
      const data = await doHttpGetListTable(entitySchema, props);
      if (data) {
        setData(data);
      } else {
        setData(defaultState);
      }
    } catch (e) {
      Notify.error('Falha ao carregar dados', e);
    } finally {
      setIsLoading(false);
    }
  }, [entitySchema]);

  return (
    <Row>
      <Col sm={12} md={12}>
        <ReactTable
          pageCount={data.pageCount}
          loading={isLoading}
          fetchData={fetchDate}
          data={data.content}
          tableColumns={columns}
        />
      </Col>
    </Row>);
}

export default CustomTable;
