import {Col, Row} from "react-bootstrap";

export const AnexosPedido = ({arquivos, title = 'Anexos da Solicitação'}) => {
  return <form>
    <fieldset style={{border: "1px solid #dee2e6", borderRadius: "2px"}}>
      <legend className="pl-2 pr-2" style={{
        border: "2px",
        width: "auto",
        textAlign: "center"
      }}>
        {title}
      </legend>
      <div className="m-4">
        <Row className="p-2 mt-3 border-bottom">
          <Col>
            {!arquivos && <h5 className="text-center">Sem Anexos</h5>}
            <ul>
              {arquivos?.map((z, w) => {
                return <li key={w}>
                  <a target="_blank" href={z.publicUrl}> {z.nomeOriginal} </a>
                </li>
              })}
            </ul>
          </Col>
        </Row>
      </div>
    </fieldset>
  </form>
}
