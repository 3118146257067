import {combineReducers} from 'redux';
import authState from "../auth/auth.reducer";

const rootReducer = combineReducers({
  authState,
  // integrationUserState,
  // authenticationState,
  // employerState,
  // customGridState,
  // certificateState,
  // customFormState,
  // userConfigurationState,
  // routing: routerReducer
  // movementItemsPage,
  // assetList,
  // productTypePage,
  // checkpointPage,
  // activation,
  // index,
  // login,
  // reader,
  // picture,
  // commissionerDetail,
  // productTypeList
});

export default rootReducer;
