import {CreatableReadonlyToInputV2} from "../../../components";

const BaseLegalSelector = ({
  required = false,
  title = "Base Legal",
  setValue = null,
  isMulti = true,
  path = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `optima-lgpd/v1/bases-legais?sort=nome,ASC&nome=${filterValue
      ? filterValue : ""}`;
  }

  return <CreatableReadonlyToInputV2
    isMulti={isMulti}
    title={title}
    required={required}
    label="nome"
    getUrl={getUrl}
    setValue={setValue}
    path="optima-lgpd/v1/bases-legais"
    {...props}
  />

}

export default BaseLegalSelector;

