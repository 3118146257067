import {ThemeContext} from "../../thema/ThemeProvider";

export default function Logo() {
  return (
    <ThemeContext.Consumer>
      {theme => (<>
        <span className="brand-text font-weight-light">{theme.companyName}</span>
      </>)}
    </ThemeContext.Consumer>)
}
