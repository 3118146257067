import {useEffect, useState} from "react";
import {Alert, Card, Col, Row} from "react-bootstrap";

import HttpRequest from "../../../../shared/common/http/HttpRequest";
import Notify from "../../../../shared/common/notify/Notify";
import {Page} from "../../../../components/page/Page";
import Mindmap from '../../../../components/chart/MindMap';

const OrganogramaProcessoPage = () => {
  const [organogramaData, setOrganogramaData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const findData = async () => {
    try {
      let data = await HttpRequest.get(`/optima-lgpd/v1/processos?processoType=PROCESSO&size=100`);
      setOrganogramaData(data.content)
    } catch (e) {
      Notify.error('Falha ao carregar os processos', e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    findData();
  }, [])

  return (
    <Page title="Mapa Mental de Dados Pessoais Coletados">
      <Card>
        <Card.Body className="h-25">
          <Alert variant="dark"><span style={{fontWeight: 'bold'}}>Dica: &nbsp;</span> Dentro do mapa mental use Ctrl + Scroll para dar zoom</Alert>
          <Row>
            <Col style={{height: '90vh'}} md={12}>
              <Mindmap data={organogramaData} isLoading={isLoading}/>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Page>
  )

}

export default OrganogramaProcessoPage;
