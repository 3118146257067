import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";

const DadosSensiveisListSchema = () => {
  const endpoint = 'optima-lgpd/v1/dados-pessoais-coletados';
  return {
    title: 'Dados Sensíveis',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.DADOS_SENSIVEIS_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.DADOS_SENSIVEIS_FORM
        })
      ]
    },

  }
}

export default DadosSensiveisListSchema;

