import {SmallBox} from "../../../components";
import React, {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";
import {useHistory} from "react-router-dom";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {Col} from "react-bootstrap";

const DEFAULT_FONTE_DE_DADOS = [
  {nome: 'Telefone'},
  {nome: 'E-mail'},
  {nome: 'WhatsApp'},
]

const FonteDeDadosWizard = () => {

  const [total, setTotal] = useState(1);
  const history = useHistory();

  const loadTotal = async () => {
    try {
      const result = await HttpRequest.get('optima-lgpd/v1/fonte-dados-sensiveis/count');
      setTotal(result.total);
    } catch (e) {
      Notify.error('Falha ao contar fonte de dados', e);
    }
  }

  useEffect(() => {
    loadTotal();
  }, []);

  const insertDefaultData = async (e) => {
    try {
      e.preventDefault();
      console.log('import data');
      await HttpRequest.post('optima-lgpd/v1/dados-iniciais/fonte-de-dados', DEFAULT_FONTE_DE_DADOS);
      Notify.success('Fonte de Dados importada!');
      history.push(ROUTE_PATH.FONTE_DE_DADOS_LIST);
    } catch (e) {
      Notify.error('Falha ao importar fonte de dados', e);
    }

  };

  if (total > 0) {
    return <></>;
  }

  return <Col sm={3} md={3}>
    <SmallBox
      type="primary"
      count={total} title="Ops, Vejo que você ainda não tem fonte de dados"
      titlebox={"Inserir fonte de dados padrão?"}
      onClick={insertDefaultData}
      navigateTo=""/>
  </Col>

}

export default FonteDeDadosWizard;
