import {Button, ButtonGroup, Col, Row} from "react-bootstrap";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {TableButton} from "../button/TableButton";
import * as PropTypes from "prop-types";
import {BlockButton} from "../button/BlockButton";
import EntityBlockButton from "../button/EntityBlockButton";
import {saveDuplicateData} from "../../modules/page-form/PageFormService";
import {formatByIdAndMatch, ROUTE_PATH} from "../../shared/routes/RoutesPath";
import ResendEmailButton from "../button/ResendEmailButton";
import MyModal from "../modal/Modal";
import React, {useState} from "react";
import {AppButton} from "../button/AppButton";
import {ModalConfirmRemove} from "../ModalConfirmRemove/ModalConfirmRemove";

BlockButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string
};
const OptionColumn = ({
  endpointToDelete,
  editTo,
  children,
  showEdit = true,
  showDelete = true,
  showBlock = false,
  showVisualize = false,
  showResendEmailButton = false,
  entity = "",
  showDuplicateButton = false,
  showSolicitation = false,
  showProfileButton
}) => {

  const removeEntity = async (id, tableProps, setOpen) => {
    try {
      await HttpRequest.delete(`${endpointToDelete}/${id}`)
      console.log('tableProps', tableProps);
      tableProps.setGlobalFilter(new Date());
    } catch (e) {
      Notify.error('Falha ao remover linha', e);
    } finally {
      setOpen(false)
    }
  };

  return {
    Header: "Opções",
    sortable: false,
    export: false,
    collapse: true,
    filter: false,
    // minWidth: 15, // minWidth is only used as a limit for resizing
    // width: 15, // width is used for both the flex-basis and flex-grow
    // maxWidth: 120,
    Cell: (props) => {
      const [open, setOpen] = useState(false);
      const [isLoading, setIsLoading] = useState(false);

      const {row} = props;
      return (
        <Row>
          <Col className="text-center">
            <ButtonGroup size="sm">
              {showEdit && <TableButton icon="fas fa-pencil-alt" to={editTo + row.original.id} title="Editar"/>}
              {showSolicitation && <TableButton icon="fas fa-eye" to={formatByIdAndMatch({id: row.original.id}, ROUTE_PATH.DETALHES_SOLICITACAO)}
                                                title="Detalhes"/>}
              {showVisualize && <TableButton href={row.original?.arquivo?.publicUrl} target={"_blank"} title="Visualizar"/>}
              {showDuplicateButton && <TableButton icon="far fa-clone" to={editTo} title="Duplicar" onClick={() => saveDuplicateData(row?.original)}/>}
              {children && children(props)}

              {showBlock && <EntityBlockButton entity={entity} tableProps={props} endpointToBlock={endpointToDelete} row={row}/>}

              {showResendEmailButton && <ResendEmailButton entity={entity} tableProps={props} endpointToBlock={endpointToDelete} row={row}/>}

              {showProfileButton && <TableButton icon="fas fa-eye" variant="secondary" title="Perfis" to={`${ROUTE_PATH.USER_PERFIL}${row.original.id}`}/>}


              {showDelete &&
                <TableButton icon="fas fa-trash" title="Remover" text='Remover está linha' variant="danger" loading={open} onClick={() => setOpen(true)}/>}

              <ModalConfirmRemove open={open} onClick={() => removeEntity(row.original.id, props, setOpen)} setOpen={setOpen} isLoading={isLoading}/>

            </ButtonGroup>
          </Col>
        </Row>
      )
    }
  }
}

export default OptionColumn;
