import PerguntasSelector from "../perguntas/component/PerguntasSelector";

const QuestionarioFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Questionário' : 'Editar Questionário';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/questionarios/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/questionarios/${id || ''}`;
    },

    getSchema() {
      return {
        type: "object",
        required: ["nome", "perguntas"],
        properties: {
          nome: {type: "string", title: "Título"},
          perguntas: {
            type: 'array',
            required: ["id"],
            items: {
              id: {type: "string",},
            }
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
          "ui:placeholder": "Título do questionário",
        },
        "perguntas": {
          "ui:field": (props) => {
            return (<PerguntasSelector isMulti={true} disabled={props.disabled} required={props.required}
                                       title={props.title}
                                       value={props.formData} setValue={props.onChange}/>
            );
          }
        },
      }
    }

  }
}

export default QuestionarioFormSchema;
