import AgrupadoresSelector from "./component/AgrupadoresSelector.";

const PerguntasFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Nova Pergunta' : 'Editar Pergunta';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/perguntas/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/perguntas/${id || ''}`;
    },

    getSchema() {
      return {
        definitions: {
          "EscolhaSimOuNao": {
            type: "object",
            required: ["valor", "pesoDoRisco", "pesoDoImpacto"],
            "properties": {
              "valor": {
                type: 'string',
                title: 'Opção para resposta',
              },
              "pesoDoRisco": {
                type: 'number',
                minimum: 0,
                maximum: 10,
                title: 'Peso do Risco',
              },
              "pesoDoImpacto": {
                type: 'number',
                minimum: 0,
                maximum: 10,
                title: 'Peso do Impacto',
              },
            }
          },
        },
        type: "object",
        required: ["pergunta"],
        properties: {
          "pergunta": {
            "type": "string",
            title: "Pergunta?",
            "default": ""
          },
          "notaDeAjuda": {
            "type": "string",
            title: "Nota de ajuda para auxiliar na atuação do risco!"

          },
          "groups": {
            "type": "array",
            title: "Agrupadores"
          },
          "tipo": {
            "type": "string",
            title: "Tipo de pergunta?",
            enumNames: [
              'Sim ou Não',
              'Opções Customizadas',
            ],
            default: 'VERDADEIRO_FALSE',
            "enum": [
              'VERDADEIRO_FALSE',
              'OPCAO_CUSTOMIZADA',
            ]
          },
        },
        "dependencies": {
          "tipo": {
            type: "object",
            "oneOf": [
              {
                required: ["escolhas", "tipo"],
                "properties": {
                  "tipo": {
                    "enum": ['VERDADEIRO_FALSE']
                  },
                  "escolhas": {
                    "type": "array",
                    "minItems": 2,
                    "default": [
                      {valor: 'Sim', pesoRisco: 1, pesoImpacto: 1},
                      {valor: 'Não', pesoRisco: 0, pesoImpacto: 0}],
                    "items": {
                      "$ref": "#/definitions/EscolhaSimOuNao"
                    }
                  }
                },
              },
              {
                required: ["escolhas", "tipo"],
                "properties": {
                  "tipo": {
                    "enum": ['OPCAO_CUSTOMIZADA']
                  },
                  "escolhas": {
                    "type": "array",
                    "minItems": 2,
                    default: [],
                    "items": {
                      "$ref": "#/definitions/EscolhaSimOuNao"
                    }
                  }
                },
              },
            ]
          }
        },
      };

    },

    getUiSchema: () => {
      return {
        "pergunta": {
          "ui:placeholder": "Informe a pergunta a ser respondida."
        },
        "groups": {
          "ui:field": (props) => {
            return (<AgrupadoresSelector isMulti={true} disabled={props.disabled}
                                         required={props.required}
                                         title={props.title}
                                         value={props.formData}
                                         setValue={props.onChange}/>)
          }
        }

      }
    }

  }
}

export default PerguntasFormSchema;

