const IncidenteFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Incidente' : 'Editar Incidente';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/incidentes/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/incidentes/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Incidente",
        type: "object",
        required: ["mensagem", "titulo", "data"],
        properties: {
          titulo: {
            type: "string",
            title: "Título"
          },
          data: {
            type: "string",
            "format": "date",
            title: "Data do Incidente"
          },
          mensagem: {
            type: "string",
            title: "Mensagem"
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        titulo: {
          "ui:autofocus": true,
          "ui:placeholder": "Título do incidente",
        },
        "mensagem": {
          "ui:widget": "textarea",
          "ui:placeholder": "Descreva o incidente com a maior quantidade de detalhes possíveis para melhor entendimento.",
        }
      }
    }
  }
}

export default IncidenteFormSchema;

