import {Card, Col, Row, Tab} from "react-bootstrap";
import {dayMonthYear, dayMonthYearAtTime, timeago} from "../../../../shared/utils/dates";
import moment from "moment";
import {TableButton} from "../../../../components/button/TableButton";
import {formatByIdAndMatch, ROUTE_PATH} from "../../../../shared/routes/RoutesPath";
import {HistoricoList} from "./HistoricoList";
import {SolicitacaoStatus} from "./SolicitacaoStatusSelector";

export const AtualizacaoTab = ({eventKey, data}) => {

  return <Tab.Pane eventKey={eventKey} key={eventKey}>
    <Card>
      <Card.Body>
        <StatusSolicitacao data={data}/>
      </Card.Body>
    </Card>
  </Tab.Pane>
}

const StatusSolicitacao = ({data}) => {
  let deadlineIsAfter = moment(new Date()).isAfter(data.prazo);

  console.log(data)
  return <form>
    <fieldset style={{border: "1px solid #dee2e6", borderRadius: "2px"}}>
      <legend className="pl-2 pr-2" style={{
        border: "2px", width: "auto",
        textAlign: "center"
      }}>
        Acompanhamento
      </legend>
      <div className="m-4">
        <Row className="p-2 border-bottom">
          <Col>
            Status
          </Col>
          <Col sm={7}>
            <SolicitacaoStatus status={data.status}/>
          </Col>
        </Row>
        <Row className="p-2 mt-3 border-bottom">
          <Col>
            <p>Resposta</p>
          </Col>
          <Col sm={7}>
            <p>{data.respostaSolicitacao && (data.respostaSolicitacao.createdBy + ' - ' + dayMonthYearAtTime(data.respostaSolicitacao?.createdAt))}</p>
          </Col>
        </Row>
        <Row className="p-2 mt-3 border-bottom">
          <Col>
            <p>Categoria</p>
          </Col>
          <p>{data.respostaSolicitacao && data.respostaSolicitacao.categoria && data.respostaSolicitacao.categoria.nome}</p>
        </Row>
        <Row className="p-2 mt-3 border-bottom">
          <Col>
            Prazo
          </Col>
          <Col sm={7}>
            <Row>
              <Col>
                {deadlineIsAfter ?
                  <Row className="d-inline float-left">
                    <Col style={{color: "red"}}>Atrasado ({timeago(data.prazo)}) </Col>
                    <Col><p>{dayMonthYear(data.prazo)}</p></Col>
                  </Row> :
                  <Row className="d-inline float-left">
                    <Col style={{color: "#29cf29"}}>Dentro do prazo ({timeago(data.prazo)}) </Col>
                    <Col><p>{dayMonthYear(data.prazo)} </p></Col>
                  </Row>
                }
              </Col>
              <Col>
                {!data.prorrogacao?.prorrogado && <TableButton to={formatByIdAndMatch({id: data?.id}, ROUTE_PATH.PRORROGACAO_FORM)} title="Prorrogar" classNameButton="w-50"/>}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="p-2 mt-3 border-bottom">
          <Col sm={5}>
            Responsável
          </Col>
          <Col>
            <Row>
              <Col>
                {data?.responsavel && <Row>
                  <Col>
                    <p>Nome: </p>
                    <p>Setor: </p>
                  </Col>
                  <Col sm={9}>
                    <p>{data?.responsavel?.nome}</p>
                    <p>{data?.responsavel?.cargo?.nome}</p>
                  </Col>
                </Row>
                }
                {!data?.responsavel && <p>Nenhum responsável definido</p>}
              </Col>
              <Col>
                <p>
                  <TableButton title="Encaminhar"
                               to={formatByIdAndMatch({id: data?.id}, ROUTE_PATH.ENCAMINHAR_SOLICITACAO_FORM)} classNameButton="w-50"/>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </fieldset>

    <fieldset style={{border: "1px solid #dee2e6", borderRadius: "2px"}}>
      <legend className="pl-2 pr-2" style={{
        border: "2px", width: "auto",
        textAlign: "center"
      }}>
        Histórico
      </legend>
      <div className="m-4">
        <HistoricoList historicos={data?.historicos}/>
      </div>
    </fieldset>

  </form>
}
