import {applyMiddleware, compose, createStore} from 'redux';
import reducer from "./reducer";
import thunkMiddleware from 'redux-thunk';

const defaultMiddlewares = [
  // axiosMiddlewarFe(axios.create({
  //   baseURL: SERVER_API_URL,
  //   responseType: 'json'
  // })),
  thunkMiddleware,
  // errorMiddleware,
  // notificationMiddleware,
  // promiseMiddleware(),
  // loadingBarMiddleware(),
  // loggerMiddleware,
  // createReactNavigationReduxMiddleware(
  //     "root",
  //     state => state.nav,
  // )
];

const composedMiddlewares = middlewares => compose(
  applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState, middlewares = []) => createStore(
  reducer, initialState, composedMiddlewares(middlewares));

export default initialize;
