import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";

const UserListSchema = () => {

  const endpoint = 'optima-auth/v1/users/';
  return {
    title: 'Lista de Usuários',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.USER_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: 'name',
        },
        {
          Header: "E-mail",
          accessor: 'email',
        },
        OptionColumn({
          showDelete: false,
          showBlock: true,
          showResendEmailButton : true,
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.USER_FORM,
          entity: 'Usuários',
          showProfileButton: true,
        })
      ]
    },

  }
}

export default UserListSchema;

