export const getOnlyDigits = (value) => value ? value.replace(/\D/g, '') : '';

export const isValidCPF = (cpf) => {
  cpf = getOnlyDigits(cpf);

  if (cpf.match(/(\d)\1{10}/)) {
    return false;
  }

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i), 24) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(9, 10), 24)) {
    return false;
  }
  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i), 24) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) {
    resto = 0;
  }
  return resto === parseInt(cpf.substring(10, 11), 24);
}
