import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const CargoSelector = ({
  required = false,
  defaultValue,
  title = "Cargo",
  setValue = null,
  showCreateButton = true,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `optima-lgpd/v1/cargos?sort=nome,ASC&nome=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="nome"
    getUrl={getUrl}
    setValue={setValue}
    showCreateButton={showCreateButton}
    pathToCreate={ROUTE_PATH.CARGO_FORM}
    {...props}
  />

}

export default CargoSelector;
