import {LinkButton} from "../../../components";

export const OrganogramaButton = ({
  to,
  children
}) => {

  return (<LinkButton id="new_button" className="btn-primary" size="sm" to={to}>{children}</LinkButton>)

}
