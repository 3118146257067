import {FileUploadWithProgress} from "../index";
import Notify from "../../shared/common/notify/Notify";
import HttpRequest from "../../shared/common/http/HttpRequest";

const ArquivoUpload = ({
  ...props
}) => {

  const getSignedUrl = async (file, callback) => {
    try {
      const name = file.name;
      const type = file.type;
      console.log(name)
      const data = await HttpRequest.get(`optima-lgpd/v1/arquivos/url-to-upload?name=${name}&type=${type}`);
      callback({
        signedUrl: data.url,
        fullPath: data.fullPath,
        tipo: type,
        nomeOriginal: name

      });
    } catch (e) {
      Notify.error('Falha ao buscar url para upload', e);
    }
  }

  console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');

  return <FileUploadWithProgress
    {...props}
    getSignedUrl={getSignedUrl}
  />

}

export default ArquivoUpload;
