import {Form, ProgressBar} from "react-bootstrap";
import {useState} from "react";
import ReactS3Uploader from "react-s3-uploader";
import Notify from "../../shared/common/notify/Notify";
import {RemoveButton} from "../button/RemoveButton";

const FileUploadWithProgress = ({
  label,
  getSignedUrl, accept, setValue,
  progressBar = true,
  value
}) => {

  const [percentage, setPercentage] = useState(0);

  const onError = (e, a) => {
    console.log('onError', e, a);
    Notify.error('Falha ao enviar arquivo');
  }

  const onProgress = (percent, message) => {
    setPercentage(percent);
  }

  const onFinish = (a) => {
    setValue && setValue(a);
  }

  const remove = () => {
    setValue(null);
  }

  return <Form.Group>
    <Form.Label>{label}</Form.Label>
    {!value && <ReactS3Uploader
      getSignedUrl={getSignedUrl}
      accept={accept}
      onFinish={onFinish}
      onError={onError}
      onProgress={onProgress}
      uploadRequestHeaders={{}}
      contentDisposition="auto"
    />}

    {value && value.nomeOriginal && <p>{value.nomeOriginal}</p>}

    {!value && !value?.nomeOriginal && progressBar && <ProgressBar label={percentage === 100 ? 'Enviado' : `Enviando ${percentage}%`} striped={true}
                                                                   now={percentage}/>}

    {value && <RemoveButton onClick={remove}>Remover</RemoveButton>}

  </Form.Group>
}

export default FileUploadWithProgress;
