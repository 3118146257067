import {Gravatar} from "./Gravatar";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

export const CurrentUser = () => {

  const {isLoading, currentUser} = useSelector(({authState}) => ({
    isLoading: authState.isLoading,
    currentUser: authState.currentUser
  }));

  if (isLoading || !currentUser) {
    return null;
  }

  return (
    <div className="user-panel mt-3 pb-2 mb-3 d-flex">
      <div className="image mb-2">
        <Gravatar email={currentUser.username} className="img-circle elevation-2" alt="Avatar do Usuário"/>
      </div>
      <div className="info d-hidden-mini">
        <Link to={ROUTE_PATH.USER_PROFILE} className="d-flex text-light text-wrap pr-3 text-center">
          {currentUser.name} {currentUser.company.name ? currentUser.company.name : ''}
        </Link>
      </div>
    </div>
  )
}
