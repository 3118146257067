import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";
import {Arquivo} from "../../components/arquivo/Arquivo";

const CursoListSchema = () => {

  const endpoint = 'optima-lgpd/v1/cursos';

  return {
    title: 'Lista de Cursos',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.CURSOS_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        {
          Header: "Arquivos",
          sortable: false,
          export: false,
          Filter: false,
          Cell: ({row}) => {
            return row.original.arquivos ? row.original.arquivos.map((x) => <li key={x?.arquivo?.id}><Arquivo arquivo={x?.arquivo}/></li>) : null
          }
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.CURSOS_FORM
        })
      ]
    }
  }
}

export default CursoListSchema;
