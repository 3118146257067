import {Link} from "react-router-dom";

export const LinkButton = ({
  id,
  to,
  className = 'btn-primary',
  size = "lg",
  children,
  onClick
}) => {
  return (<Link id={id} className={`mr-1 btn btn-${size} ${className}`} onClick={onClick} size={size} to={to}>
      {children}
    </Link>
  )
}
