import Editor from "../../components/editor/Editor";

const ModeloProrrogacaoFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Modelo de Prorrogação' : 'Editar Modelo de Prorrogação';
    },

    getUrlFindById(id) {
      console.log(id)
      return `optima-lgpd/v1/modelos-prorrogacoes/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/modelos-prorrogacoes/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Modelo de Prorrogação",
        type: "object",
        required: ["titulo", "mensagem"],
        properties: {
          titulo: {
            type: "string",
            title: "Título",
          },
          mensagem: {
            type: "string",
            title: "Conteúdo"
          }
        }
      };
    },

    getUiSchema: () => {
      return {
        "titulo": {
          "ui:autofocus": true,
        },
        "mensagem": {
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title='Justificativa'
                initialValue={props.formData}
                onChange={props.onChange}
              />
            );
          }
        }
      }
    }
  }
}

export default ModeloProrrogacaoFormSchema;
