import CargoSelector from "./component/CargoSelector";

const CargoFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Cargo' : 'Editar Cargo';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/cargos/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/cargos/${id || ''}`;
    },

    validate(formData, errors) {
      if (formData.cargo?.id === formData.id && formData.id) {
        errors.cargo.addError("O cargo pai não pode ser igual ao filho!")
      }
      return errors
    },

    getSchema() {
      return {
        title: "Cargo",
        type: "object",
        required: ["nome"],
        properties: {
          nome: {type: "string", title: "Nome"},
          cargo: {
            type: 'object',
            properties: {
              id: {type: "string", title: "Setor"},
            }
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:help": "Nome do cargo a ser cadastrado na empresa",
          "ui:autofocus": true,
        },
        "cargo": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<CargoSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={props.formData}
                                   showCreateButton={false}
                                   setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default CargoFormSchema;

