import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {dayMonthYear, dayMonthYearAtTime} from "../../shared/utils/dates";
import OptionColumn from "../../components/table/OptionColumn";
import {DateColumnFilter, DateTimeRangeColumnFilter} from "../../components/table/TableFilter";


const IncidenteListSchema = () => {

  const endpoint = 'optima-lgpd/v1/incidentes';

  return {
    title: 'Lista de Incidentes',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.INCIDENTES_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Título",
          accessor: "titulo"
        },
        {
          Header: "Data",
          accessor: "data",
          Filter: DateColumnFilter,
          Cell: ({row}) => row.original.data && dayMonthYear(row.original.data)
        },
        {
          Header: "Criado em",
          accessor: "createdAt",
          Filter: DateTimeRangeColumnFilter,
          Cell: ({row}) => row.original.createdAt && dayMonthYearAtTime(row.original.createdAt)
        },
        OptionColumn({endpointToDelete: endpoint, editTo: ROUTE_PATH.INCIDENTES_FORM})
      ]
    },

  }
}

export default IncidenteListSchema;

