import {useExpanded, useFilters, useFlexLayout, useGlobalFilter, usePagination, useResizeColumns, useSortBy, useTable} from "react-table";
import React, {useEffect, useState} from "react";
import {Col, Form, Row, Table} from "react-bootstrap";
import {DefaultColumnFilter} from "./TableFilter";
import {Loading} from "../loading/Loading";
import TablePageSelector from "./TablePageSelector";
import {TablePagination} from "./TablePagination";

export const ReactTable = ({
  fetchData, loading, data, tableColumns,
  pageCount: controlledPageCount, renderRowSubComponent
}) => {

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [fetchData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,

    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // useFlexLayout,
    setFilter,
    setPageSize,
    // Get the state from the instance
    state: {pageIndex, pageSize, sortBy, globalFilter, filters}
  } = useTable(
    {
      columns: tableColumns,
      defaultColumn,
      data,
      initialState: {pageIndex: 0, refresh: -1}, // Pass our hoisted table state
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
    // useAbsoluteLayout,
    // useBlockLayout
  )

  const [_filters, setInternalFilter] = useState(filters);
  const [_globalFilters, setInternalGlobalFilter] = useState(globalFilter);

  useEffect(() => {
    if (!globalFilter) {
      return;
    }

    if (_globalFilters !== globalFilter) {
      setInternalGlobalFilter(globalFilter);
      setInternalFilter([{global: false}]);
    }

  }, [globalFilter])

  useEffect(() => {

    if (!filters) {
      return;
    }

    if (JSON.stringify(_filters) !== JSON.stringify(filters)) {
      setInternalFilter(filters);
    }

  }, [filters])

  useEffect(() => {
    fetchData({pageIndex, pageSize, sortBy, filters: _filters})
  }, [fetchData, pageIndex, pageSize, sortBy, _filters])

  const Filter = () => {
    return <Row>
      {headerGroups.map(headerGroup => headerGroup.headers.filter(x => x.filter !== false && x.render)
      .map(x => {
        let item = x.render('Filter');
        if (!item) {
          return null;
        }
        return (<Col key={x.Header}>
          {x.CustomSelector ? item :
            <Form.Group>
              {item}
            </Form.Group>
          }
        </Col>)
      }).filter(x => x))}
    </Row>
  }

  return (
    <>
      {Filter()}
      <Table responsive striped bordered hover {...getTableProps()} >
        <thead className="thead-dark">
        {headerGroups.map(headerGroup => (
          <tr style={{
            display: "flex", flex: "1 0 auto", minWidth: "120px", height: "7rem",
          }}{...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          const rowProps = row.getRowProps();
          return (<React.Fragment key={rowProps.key}>
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
              {row.isExpanded && renderRowSubComponent({row, rowProps})}
            </React.Fragment>
          )
        })}
        <tr>
          {loading ? (
            <td colSpan="100"><Loading isLoading={loading}/></td>
          ) : (
            <td colSpan="100" className="pb-0">
              <Row>
                <Col sm={12} md={7}>
                  Apresentando {page.length} de {controlledPageCount * pageSize}{' '} resultados
                </Col>
                <Col sm={8} md={2} xs={8}>
                  <TablePageSelector value={pageSize} setValue={page => setPageSize(page.id)}/>
                </Col>
                <Col sm={12} md={3}>
                  <TablePagination previousPage={previousPage} pageCount={pageCount} canNextPage={canNextPage}
                                   canPreviousPage={canPreviousPage} gotoPage={gotoPage}
                                   nextPage={nextPage} pageIndex={pageIndex} pageSize={pageSize}
                  />
                </Col>
              </Row>
            </td>
          )}
        </tr>
        </tbody>
      </Table>
    </>
  )
}

