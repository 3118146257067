import {FileSchemaForm} from "../../shared/common/FileSchemaForm";
import Editor from "../../components/editor/Editor";
import ArquivoSelector from "../arquivos/component/ArquivoSelector";

const PoliticaFormSchema = () => {
  return {
    save(e) {
      e.preventDefault();
      e.stopPropagation();
      /*saveFileContent(this.props.fileId, this.content).then(
          () => {
            Notify.success("Salvo com sucesso");
            alert('Salvo');
          }, err => {
            alert('Falha ao salvar');
            Notify.error("Problema ao salvar", err);
          });*/
      return false;
    },

    getTitle(isNew) {
      return isNew ? 'Nova Política' : 'Editar Política';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/politicas/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/politicas/${id || ''}`;
    },

    getSchema() {
      return {
        "definitions": {
          "Arquivos": FileSchemaForm
        },
        title: "Política",
        type: "object",
        required: ["nome", "politicaText"],
        properties: {
          nome: {type: "string", title: "Nome"},
          public: {
            type: "boolean",
            title: "Permitir consulta Pública?"
          },
          arquivos: {
            type: "array",
            properties: {
              "id": {
                type: 'string',
                title: 'Arquivos'
              },
            }
          },
          politicaText: {
            type: "string",
            title: "Política"
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
          "ui:help": "Nome da política que está sendo mapeada"
        },
        "arquivos": {
          "ui:field": (props) => {
            console.log('props', props);
            return (
              <ArquivoSelector  {...props}
                                disabled={props.disabled} isMulti={true}
                                required={props.required}
                                value={props.formData}
                                setValue={props.onChange}/>
            );
          }
        },
        "politicaText": {
          "ui:autofocus": true,
          "ui:field": (props) => {
            return (
              <Editor
                {...props}
                title={props.schema.title}
                initialValue={props.formData}
                onChange={props.onChange}
                initProps={{placeholder: "Descreva a política, passo a passo de como deve ser exercida. Seja o mais detalhado possível."}}
              />
            );
          }
        }
      }
    }
  }
}

export default PoliticaFormSchema;

