export const ROUTE_PARAM = {
  COMPANY_ID: '{companyId}'
}

export const formatByIdAndMatch = ({match, id}, route) => {
  return route.replace(':id', id ? id : (match?.id));
}

export const ROUTE_PATH = {

  /// -------- LAI ------ ///
  PORTAL_LAI: `/lai#/company/{companyId}/list/portal-lai`,

  SOLICITACAO_LIST: '/lai/list/solicitacoes/',

  MODELO_PRORROGACAO_LIST: `/lai/list/modelos-prorrogacoes/`,
  MODELO_PRORROGACAO_FORM: `/lai/list/modelos-prorrogacoes/form/`,

  ENCAMINHAR_SOLICITACAO_FORM: `/lai/list/encaminhar-solicitacoes/form/:id`,

  LIST_LAI_PAGE: `/lai/list/:entity/`,
  DETALHES_SOLICITACAO: `/lai/codSolicitacao/:id`,

  SOLICITACAO_COMENTARIO: '/lai/list/solicitacao-comentario/form/:id',

  FORM_LAI_PAGE: `/lai/list/:entity/form/`,
  FORM_ID_LAI_PAGE: `/lai/list/:entity/form/:id`,

  ///----- SELF PATHS ----////

  HOME: `/home`,

  KANBAN_BOARD: `/kanban`,

  ALERTAS: `/list/alertas`,

  USER_LIST: `/list/users/`,
  USER_FORM: `/list/users/form/`,
  USER_PERFIL: `/list/users-perfis/form/`,

  LIST_CONTATO_SUPORTE_PAGE: `/list/contato-suportes/`,
  FORM_CONTATO_SUPORTE_PAGE: `/list/contato-suportes/form/`,

  ///--- PATH TREINAMENTOS ---///

  ARQUIVOS_LIST: `/treinamentos/list/arquivos`,
  ARQUIVOS_FORM: `/treinamentos/list/arquivos/form/`,

  QUESTIONARIO_MENU_LIST: '/controles/list/questionario',
  TREINAMENTO_MENU_LIST: `/treinamentos`,
  CADASTROS_MENU_LIST: `/cadastros`,
  TEMPLATES_MENU_LIST: `/templates`,
  VARIAVEIS_MENU_LIST: `/cadastros/list/variaveis`,
  LEVANTAMENTO_MENU_LIST: `/controles/list/levantamentos`,

  TREINAMENTO_LIST: `/treinamentos/list/treinamentos/`,
  TREINAMENTO_FORM: `/treinamentos/list/treinamentos/form/`,

  CERTIFICADO_TEMPLATE_FORM: `/treinamentos/list/certificado-template/form/1`,

  CURSOS_LIST: `/treinamentos/list/cursos/`,
  CURSOS_FORM: `/treinamentos/list/cursos/form/`,

  CERTIFICADO_LIST: `/treinamentos/list/capacitacoes/`,

  ///--- PATH CONTROLES QUESTIONARIO ---///

  PERGUNTAS_LIST: `/controles/list/questionario/perguntas/`,
  PERGUNTAS_FORM: `/controles/list/questionario/perguntas/form/`,

  ///--- PATH CONTROLES QUESTIONARIO ---///

  FORNECEDORES_LIST: '/controles/list/fornecedores-parceiros/',
  FORNECEDORES_FORM: '/controles/list/fornecedores-parceiros/form/',

  QUESTIONARIO_LIST: `/controles/list/questionario/questionarios/`,
  QUESTIONARIO_FORM: `/controles/list/questionario/questionarios/form/`,

  CONTRATOS_VIGENTES_LIST: '/controles/list/contratos-vigentes/',
  CONTRATOS_VIGENTES_FORM: '/controles/list/contratos-vigentes/form/',

  ///--- PATH CONTROLES LEVANTAMENTO ---///

  CAMPANHAS_LIST: `/controles/list/levantamentos/campanhas/`,
  CAMPANHAS_FORM: `/controles/list/levantamentos/campanhas/form/`,

  ///--- PATH CONTROLES LGPD ---///

  CONTROLES_MENU_LIST: `/controles`,

  PROCESSO_LIST: `/controles/list/processos/`,
  PROCESSO_FORM: `/controles/list/processos/form/`,

  ORGANOGRAMA_PROCESSO_PAGE: '/controles/list/processos/organograma',

  AUDITORIAS_LIST: `/controles/list/auditorias/`,
  AUDITORIAS_FORM: `/controles/list/auditorias/form/`,

  GESTAO_RISCO_LIST: `/controles/list/gestao-risco/`,
  GESTAO_RISCO_FORM: `/controles/list/gestao-risco/form/`,

  RIPD_LIST: `/controles/list/relatorios/`,
  RIPD_FORM: `/controles/list/relatorios/form/`,

  POLICY_LIST: `/controles/list/policies/`,
  POLICY_FORM: `/controles/list/policies/form/`,
  POLICY_FILES_FORM: `/controles/list/policies-files/form/`,

  LIST_LEVANTAMENT0_DADOS_PAGE: `/controles/list/levantamentos/:entity/`,
  FORM_LEVANTAMENTO_DADOS_PAGE: `/controles/list/levantamentos/:entity/form/`,
  FORM_ID_LEVANTAMENTO_DADOS_PAGE: `/controles/list/levantamentos/:entity/form/:id`,

  LIST_QUESTIONARIO_PAGE: `/controles/list/questionario/:entity/`,
  FORM_QUESTIONARIO_PAGE: `/controles/list/questionario/:entity/form/`,
  FORM_ID_QUESTIONARIO_PAGE: `/controles/list/questionario/:entity/form/:id`,

  LIST_CONTROLES_PAGE: `/controles/list/:entity/`,
  FORM_CONTROLES_PAGE: `/controles/list/:entity/form/`,
  FORM_ID_CONTROLES_PAGE: `/controles/list/:entity/form/:id`,

  INCIDENTES_LIST: `/controles/list/incidentes/`,
  INCIDENTES_FORM: `/controles/list/incidentes/form/`,

  PROCEDIMENTOS_LIST: `/controles/list/procedimentos/`,

  ///----- CADASTROS -------///

  FUNCIONARIO_LIST: `/cadastros/list/funcionarios/`,
  FUNCIONARIO_FORM: `/cadastros/list/funcionarios/form/`,

  RESPONSAVEL_EMPRESA_LIST: `/cadastros/list/responsaveis-empresas/`,
  RESPONSAVEL_EMPRESA_FORM: `/cadastros/list/responsaveis-empresas/form/`,

  FONTE_DE_DADOS_LIST: `/cadastros/list/variaveis/fonte-de-dados/`,
  FONTE_DE_DADOS_FORM: `/cadastros/list/variaveis/fonte-de-dados/form/`,

  BASE_LEGAL_LIST: `/cadastros/list/variaveis/bases-legais/`,
  BASE_LEGAL_FORM: `/cadastros/list/variaveis/bases-legais/form/`,

  DIREITOS_DO_TITULAR_LIST: `/cadastros/list/variaveis/direitos-dos-titulares/`,
  DIREITOS_DO_TITULAR_FORM: `/cadastros/list/variaveis/direitos-dos-titulares/form/`,

  CARGO_LIST: `/cadastros/list/cargos/`,
  CARGO_FORM: `/cadastros/list/cargos/form/`,

  ORGANOGRAMA_CARGO_PAGE: '/cadastros/list/cargos/organograma',

  COMPANY_LIST: `/cadastros/list/entidades/`,
  COMPANY_FORM: `/cadastros/list/entidades/form/`,

  EMPLOYER_LIST: `/cadastros/list/employers/`,
  EMPLOYER_FORM: `/cadastros/list/employers/form/`,
  EMPLOYER_IMPORT: `/cadastros/list/employers-import/form/`,

  INTEGRATION_USERS_LIST: `/cadastros/list/integration-users/`,
  INTEGRATION_USERS_FORM: `/cadastros/list/integration-users/form/`,

  CATEGORIA_LIST: `/cadastros/list/categorias/`,
  CATEGORIA_FORM: `/cadastros/list/categorias/form/`,

  TIPO_PROCESSO_LIST: `/cadastros/list/variaveis/tipo-processos/`,
  TIPO_PROCESSO_FORM: `/cadastros/list/variaveis/tipo-processos/form/`,

  TIPO_POLITICA_LIST: `/cadastros/list/variaveis/tipo-politica/`,
  TIPO_POLITICA_FORM: `/cadastros/list/variaveis/tipo-politica/form/`,

  DADOS_SENSIVEIS_LIST: `/cadastros/list/variaveis/dados-sensiveis`,
  DADOS_SENSIVEIS_FORM: `/cadastros/list/variaveis/dados-sensiveis/form/`,

  TIPO_ARMAZENAMENTO_LIST: `/cadastros/list/variaveis/tipo-armazenamento/`,
  TIPO_ARMAZENAMENTO_FORM: `/cadastros/list/variaveis/tipo-armazenamento/form/`,

  TIPO_SEGURANCA_LIST: `/cadastros/list/variaveis/tipo-seguranca/`,
  TIPO_SEGURANCA_FORM: `/cadastros/list/variaveis/tipo-seguranca/form/`,

  SISTEMAS_LIST: `/cadastros/list/variaveis/sistemas/`,
  SISTEMAS_FORM: `/cadastros/list/variaveis/sistemas/form/`,

  SETOR_LIST: `/cadastros/list/setores/`,
  SETOR_FORM: `/cadastros/list/setores/form/`,

  TEMPLATE_BASE_LEGAL_LIST: `/templates/list/templates-bases-legais/`,
  TEMPLATE_BASE_LEGAL_FORM: `/templates/list/templates-bases-legais/form/`,

  TEMPLATE_DIREITOS_DO_TITULAR_LIST: `/templates/list/templates-direitos-dos-titulares/`,
  TEMPLATE_DIREITOS_DO_TITULAR_FORM: `/templates/list/templates-direitos-dos-titulares/form/`,

  TEMPLATE_FONTE_DE_DADOS_LIST: `/templates/list/templates-fonte-de-dados/`,
  TEMPLATE_FONTE_DE_DADOS_FORM: `/templates/list/templates-fonte-de-dados/form/`,

  //--------///--- BASE PATHS ---///------------//

  LIST_PAGE: `/list/:entity/`,

  LIST_TEMPLATES_PAGE: `/templates/list/:entity/`,
  FORM_TEMPLATES_PAGE: `/templates/list/:entity/form/`,
  FORM_ID_TEMPLATES_PAGE: `/templates/list/:entity/form/:id`,

  LIST_CADASTROS_PAGE: `/cadastros/list/:entity/`,
  FORM_CADASTROS_PAGE: `/cadastros/list/:entity/form/`,
  FORM_ID_CADASTROS_PAGE: `/cadastros/list/:entity/form/:id`,

  LIST_CADASTROS_VARIAVEIS_PAGE: `/cadastros/list/variaveis/:entity/`,
  FORM_CADASTROS_VARIAVEIS_PAGE: `/cadastros/list/variaveis/:entity/form/`,
  FORM_ID_CADASTROS_VARIAVEIS_PAGE: `/cadastros/list/variaveis/:entity/form/:id`,

  LIST_TREINAMENTOS_PAGE: `/treinamentos/list/:entity/`,
  FORM_TREINAMENTOS_PAGE: `/treinamentos/list/:entity/form/`,
  FORM_ID_TREINAMENTOS_PAGE: `/treinamentos/list/:entity/form/:id`,

  FORM_PAGE: `/list/:entity/form/`,
  FORM_ID_PAGE: `/list/:entity/form/:id`,

  USER_PROFILE: `/me/profile`,

  PORTAL_COMPLIANCE: `/questionario#/company/{companyId}/home`,

  PROCEDIMENTO_FORM: `/controles/list/procedimentos/form/`,

  FORM_ID_AUDITORIAS_PAGE: `/cadastros/list/:entity/form/:id`,

  PRORROGACAO_LIST: `/list/prorrogacoes/`,
  PRORROGACAO_FORM: `/list/prorrogacoes/form/:id`,
};
