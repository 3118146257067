import {useState} from "react";
import {LoadingButton} from "../loading/LoadingButton";

export const RemoveButton = ({
  text = 'Remover linha',
  title = 'Remover',
  icon = 'fas fa-trash',
  onClick,
  ...props
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const _onClick = async (e) => {
    setIsLoading(true);
    onClick && await onClick(e);
  }

  return (<LoadingButton {...props} size="sm" text={text} icon={icon} title={title} onClick={_onClick} isLoading={isLoading}
                         variant="danger"/>)
}
