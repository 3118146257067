import {Card, Col, Row, Tab} from "react-bootstrap";
import {dayMonthYearAtTime} from "../../../../shared/utils/dates";
import {AnexosPedido} from "./AnexosPedido";

export const RespostaTab = ({eventKey, data}) => {
  if (!data.respostaSolicitacao) {
    return <></>
  }

  return <Tab.Pane eventKey={eventKey} key={eventKey}>
    <Card>
      <Card.Body>
        <fieldset style={{border: "1px solid #dee2e6", borderRadius: "2px"}}>
          <legend className="pl-2 pr-2" style={{
            border: "2px", width: "auto",
            textAlign: "center"
          }}>
            Resposta
          </legend>
          <div className="m-4">
            <Row className="p-2 border-bottom">
              <Col>
                Categoria
              </Col>
              <Col sm={7}>
                <p>{data.respostaSolicitacao.categoria && data.respostaSolicitacao.categoria.nome}</p>
              </Col>
            </Row>
            <Row className="p-2 border-bottom">
              <Col>
                Data
              </Col>
              <Col sm={7}>
                <p>{dayMonthYearAtTime(data.respostaSolicitacao.createdAt)}</p>
              </Col>
            </Row>
            <Row className="p-2 border-bottom">
              <Col>
                Responsável
              </Col>
              <Col sm={7}>
                <p>{data.respostaSolicitacao.createdBy}</p>
              </Col>
            </Row>
            <Row className="p-2 border-bottom">
              <Col>
                Resposta
              </Col>
              <Col sm={7}>
                <p dangerouslySetInnerHTML={{__html: data.respostaSolicitacao.conteudo}}/>
              </Col>
            </Row>
          </div>
        </fieldset>

        <AnexosPedido arquivos={data.respostaSolicitacao.anexos} title="Anexos da Resposta"/>

      </Card.Body>
    </Card>
  </Tab.Pane>
}
