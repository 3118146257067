import {TipoAlerta} from "./TipoEnum";
import {EditButton} from "../../../components/button/EditButton";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

export const TipoAlertaAcao = ({tipo}) => {

  switch (tipo) {
    case TipoAlerta.SEM_DPO:
      return (<EditButton title="Cadastrar um DPO" to={ROUTE_PATH.RESPONSAVEL_EMPRESA_LIST}/>)
    default:
      return <p title={`Sem opção para ${tipo}`}> Sem opção </p>;
  }

}
