import React, {useEffect, useState} from 'react'
import BarChart from "../../../components/chart/BarChart";
import {Loading} from "../../../components/loading/Loading";

function PersonByCargoChart({data}) {

  const [personsByPositionsPieChartData, setPersonsByPositionsPieChartData] = useState(null);

  const mountPersonByCargoPieChart = (cargoByPessoa) => {
    const chartData = {
      labels: [],
      chartTitle: 'Quantidade de pessoas por cargos',
      datasets: [
        {
          data: [],
          label: 'Cargo X Pessoas',
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
        }
      ]
    };
    cargoByPessoa.forEach(x => {
      chartData.labels.push(x.name);
      chartData.datasets[0].data.push(x.params?.total || 0);
    });

    setPersonsByPositionsPieChartData(
      chartData
    )
  }

  useEffect(() => {
    if (data) {
      mountPersonByCargoPieChart(data.cargoByPessoa.sort((a, b) => b.params.total - a.params.total));
    }
  }, [data])

  return (
    <Loading isLoading={!personsByPositionsPieChartData}>
      <BarChart chartData={personsByPositionsPieChartData?.datasets} xAxisLabels={personsByPositionsPieChartData?.labels}/>
    </Loading>
  )
}

export default PersonByCargoChart
