import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";
import moment from "moment";

const ResponsavelEmpresaFormSchema = () => {
  return {

    getTitle() {
      return 'Novo Responsável';
    },

    getUrlDFindById(id) {
      return `optima-lgpd/v1/responsaveis-empresas/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/responsaveis-empresas/${id || ''}`;
    },

    validate(formData, errors) {
      if (moment(formData.inicioVigencia).isBefore(formData.fimDaVigencia) === false) {
        errors.fimDaVigencia.addError("Data final da vigência deve ser maior que a data inicial!")
      }
      return errors
    },

    getSchema() {
      return {
        title: "Setores",
        type: "object",
        required: ["responsavel", "inicioVigencia", "fimDaVigencia"],
        properties: {
          responsavel: {
            type: 'object',
            title: 'Responsável',
            properties: {
              id: {type: "string", title: "Responsável"},
            }
          },
          inicioVigencia: {
            type: "string",
            format: "date",
            title: "Inicio da vigência"
          },
          fimDaVigencia: {
            type: "string",
            format: "date",
            title: "Fim da vigência"
          }
        }
      }
    },

    getUiSchema: () => {
      return {
        "inicioVigencia": {},
        "fimDaVigencia": {},
        "responsavel": {
          "ui:autofocus": true,
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<FuncionarioSelector disabled={props.disabled} required={props.required}
                                         title={props.schema.title}
                                         value={props.formData} setValue={props.onChange}/>
            );
          }
        }
      }
    },

  }
}

export default ResponsavelEmpresaFormSchema;

