import {Page} from "../../components/page/Page";
import {Card, Col, Row} from "react-bootstrap";
import PoliticaCount from "../politicas/component/PoliticaCount";
import FuncionarioCount from "../pessoas/component/FuncionarioCount";
import ProcessoCount from "../processo/component/ProcessoCount";
import React, {useEffect, useState} from "react";
import HttpRequest from "../../shared/common/http/HttpRequest";
import SpeedometerChart from "./component/VelocimeterChart";
import CertificadosCount from "../certificados/CertificadosCount";
import FonteDeDadosWizard from "../fonte-de-dados/component/FonteDeDadosWizard";
import DireitosDoTitularWizard from "../direito-dos-titulares/component/DireitosDoTitularWizard";
import BaseLegalWizard from "../base-legal/component/BaseLegalWizard";
import HorizontalBarChart from "../../components/chart/HorizontalBarChart";
import PieChart from "../../components/chart/PieChart";
import Notify from "../../shared/common/notify/Notify";
import PersonByCargoChart from "./component/PersonByCargoChart";
import PessoaCargoSetoresChart from "./component/PessoaCargoSetoresChart";
import ProcessoExpiradoChart from "./component/ProcessoExpiradoChart";

const DashboardPage = () => {

  const [formData, setFormData] = useState({});
  const [graphData, setGraphData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [companyHorizontalBarChartData, setCompanyHorizontalBarChartData] = useState({labels: ['Treinamentos', 'Políticas', 'Mapeamento de dados', 'Questionários']});
  const [questionPieChartData, setQuestionPieChartData] = useState({labels: []});

  const findData = async () => {
    try {
      let data = await HttpRequest.get('optima-lgpd/v1/questionarios/risk-table');
      setFormData(data);
    } catch (e) {
      // Notify.error('Falha ao carregar dados', e);
    } finally {
      setIsLoading(false);
    }
  }

  const mountEngajamentoPieChart = ({totalCampanha, campanhaRespondida = 0}) => {
    console.log('campanhaRespondida', campanhaRespondida);
    console.log('totalCampanha', totalCampanha);
    setQuestionPieChartData({
      labels: ['Respondidos', 'Não Respondidos'],
      chartTitle: 'Engajamento dos questionários',
      datasets: [
        {
          label: 'Engajamento dos questionários',
          data: [campanhaRespondida === 0 ? 1 : campanhaRespondida, (totalCampanha - campanhaRespondida)],
          backgroundColor: [
            'rgba(0,255,0,0.4)',
            'rgba(255,0,0,0.4)'
          ],
          borderColor: [
            'rgb(0,255,0)',
            'rgb(255,0,0)'
          ],
        }
      ]
    })
  }

  const mountCompanyHorizontalBarChartData = (questionarios, politicas, mapeamentoDeDados, treinamento) => {
    setCompanyHorizontalBarChartData({
      ...companyHorizontalBarChartData,
      chartTitle: "Treinamentos / Políticas / Mapeamento de dados / Questionários",
      datasets: [
        {
          data: [treinamento, politicas, mapeamentoDeDados, questionarios],
          borderColor: 'rgb(88,0,138)',
          backgroundColor: 'rgba(88,0,138,0.4)',
        }
      ]
    })
  }

  const getHorizontalBarChartData = async () => {
    try {
      let data = await HttpRequest.get('optima-lgpd/v1/dashboards/totals');
      mountCompanyHorizontalBarChartData(data.totalCursos, data.totalPoliticas, data.totalProcessos, data.totalTreinamentos);
      mountEngajamentoPieChart(data);
      setGraphData(data);
    } catch (error) {
      Notify.error('Falha ao carregar dados', error);
    }
  }

  const handleEditCompanyChartYAxesLabels = (index) => {
    return companyHorizontalBarChartData.labels[index]
  }

  useEffect(() => {
    findData();
    getHorizontalBarChartData()
  }, []);

  return (
    <Page title="Dashboard">
      <Row>
        <Col sm={3} md={3}>
          <FuncionarioCount/>
        </Col>
        <Col sm={3} md={3}>
          <ProcessoCount/>
        </Col>
        <Col sm={3} md={3}>
          <PoliticaCount/>
        </Col>
        <Col sm={3} md={3}>
          <CertificadosCount/>
        </Col>
      </Row>
      <Row>
        <FonteDeDadosWizard/>
        <DireitosDoTitularWizard/>
        <BaseLegalWizard/>
      </Row>
      <Row>
        <Col md={6} className="mb-3 mt-3">
          <PessoaCargoSetoresChart data={graphData}/>
        </Col>
        <Col md={6} className="mb-5 mt-3">
          <HorizontalBarChart
            {...companyHorizontalBarChartData}
            callbackToEditYAxesLabels={handleEditCompanyChartYAxesLabels}
          />
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <Card.Title className="text-center pt-3">
              <h3>Engajamento da LGPD</h3>
            </Card.Title>
            <Card.Body>
              <SpeedometerChart percent={formData.percentageResponde} textColor={'black'} colors={['#EA4228', '#F5CD19', '#5BE12C']} hideText/>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <Card.Title className="text-center pt-3">
              <h3>Nível de Risco</h3>
            </Card.Title>
            <Card.Body>
              <SpeedometerChart percent={formData.percentageRisco} textColor={'black'} nrOfLevels={8} arcPadding={0.08} hideText/>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <Card.Title className="text-center pt-3">
              <h3>Nível de Impacto</h3>
            </Card.Title>
            <Card.Body>
              <SpeedometerChart textColor={'black'} percent={formData.percentageImpacto}
                                nrOfLevels={15} cornerRadius={0.1} hideText/>
            </Card.Body>
          </Card>
        </Col>
      </Row>


      <Row>
        <Col md={12} className="mb-5 mt-3">
          <PersonByCargoChart data={graphData}/>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mb-3 mt-3">
          <PieChart {...questionPieChartData} />
        </Col>
        <Col md={6} className="mb-5 mt-3">
          <ProcessoExpiradoChart data={graphData}/>
        </Col>
      </Row>

    </Page>
  );

}

export default DashboardPage;
