import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";

const TipoPoliticaListSchema = () => {
  const endpoint = 'optima-lgpd/v1/tipo-politicas';
  const formUrl = ROUTE_PATH.TIPO_POLITICA_FORM;
  return {
    title: 'Tipos de Políticas',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: formUrl
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: formUrl
        })
      ]
    },

  }
}

export default TipoPoliticaListSchema;

