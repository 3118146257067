import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import {NavLink} from "react-router-dom";
import {getMenuByPath} from "../../shared/routes/Menus";

const listToReplacec = [
  '/cadastros/list',
  '/treinamentos/list',
  '/list',
  '/home'
];

export const Breadcrumbs = withBreadcrumbs()(({breadcrumbs}) => {

  return (<ol className="breadcrumb float-sm-right">
    {breadcrumbs.filter(x => !listToReplacec.includes(x.key)).map(({match, breadcrumb}) => {
      const menu = getMenuByPath(breadcrumb.key);
      return menu && (
        <li key={match.url} className="breadcrumb-item">
          <NavLink exact={true} to={match.url}>{menu.name}</NavLink>
        </li>
      )
    })}
  </ol>);
});
