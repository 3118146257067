const CategoriaFormSchema = () => {
  return {
    getTitle(isNew) {
      return isNew ? 'Nova Categoria' : 'Editar Categoria';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/categorias/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/categorias/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Categoria",
        type: "object",
        required: ["nome"],
        properties: {
          nome: {type: "string", title: "Nome"},
        }
      };
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        }
      }
    }
  }
}

export default CategoriaFormSchema;

