import CargoSelector from "../cargo/component/CargoSelector";
import SetorSelector from "../setor/component/SetorSelector";

const FuncionarioFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Pessoa' : 'Editar Pessoa';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/funcionarios/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/funcionarios/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Funcionário",
        type: "object",
        required: ["nome", "email"],
        properties: {
          nome: {type: "string", title: "Nome"},
          email: {type: "string", title: "E-mail", format: 'email'},
          cargo: {
            type: "object", title: "Cargo", required: ["id"],
            properties: {
              id: {
                type: 'string',
              },
              name: {
                type: 'string',
              }
            }
          },
          setor: {
            type: "object", title: "Setor", required: ["id"],
            properties: {
              id: {
                type: 'string',
              },
              name: {
                type: 'string',
              }
            }
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
          "ui:help": "Nome da pessoa a ser cadastrada na empresa"
        },
        "email": {
          "ui:placeholder": "exemplo@email.com"
        },
        "cargo": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<CargoSelector disabled={props.disabled} required={props.required}
                                   value={props.formData} setValue={props.onChange}/>
            );
          }
        },
        "setor": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<SetorSelector disabled={props.disabled} required={props.required}
                                   value={props.formData} setValue={props.onChange}/>
            );
          }
        }
      }
    }
  }
}

export default FuncionarioFormSchema;

