import {Bar} from 'react-chartjs-2';

const BarChart = ({chartData, xAxisLabels, callbackToEditYAxesLabels, style}) => {

  const data = {
    labels: xAxisLabels,
    datasets: chartData
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: callbackToEditYAxesLabels
        }
      },
    },
  };

  return (
    <Bar style={style} data={data} options={options}/>
  );
}

export default BarChart
