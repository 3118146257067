import React, {useCallback} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {useHistory, useLocation} from "react-router-dom";

export const customStyles = {
  option: (provided) => (
    {
      ...provided,
      flexWrap: "nowrap",
      display: "flex",
    }),
  placeholder: (provided) => (
    {
      ...provided,
      whiteSpace: "nowrap",
    })
}

const BaseSelectorReadonlyToInputV2 = ({
  required = false,
  items = [],
  label = null,
  title = '',
  filterItems = null,
  rowId = 'id',
  getUrl = null,
  setValue = null,
  setValueId,
  value = null,
  disabled = false,
  isMulti = false,
  showCreateButton = true,
  pathToCreate = '',
  showTitle = true,
  rowStyle = {},
  sm,
  placeholder = "-- selecione ou digite para buscar --"
}) => {

  const location = useLocation();
  const history = useHistory();
  const getOptionLabel = useCallback((row) => row[label], [label])
  const getOptionValue = useCallback((row) => row[rowId], [rowId])

  const fetchData = (input, callback) => {
    if (items && items.length > 0) {
      const itemsFiltered = items
      .filter(x =>
        x[label].toLowerCase().includes(input.toLowerCase())
      );
      callback(itemsFiltered);
      return;
    }

    loadData(input, callback);
  }

  const loadData = async (input, callback) => {
    let options = [];
    try {
      if (!getUrl) {
        console.warn('cannot call getUrl beacuase is null')
        return;
      }
      const data = await HttpRequest.get(getUrl(input));
      options = data.content;
      if (filterItems) {
        options = options.filter(filterItems);
      }
    } catch (e) {
      Notify.error('Falha ao buscar dados', e);
    } finally {
      callback(options);
    }
  }

  const _onChange = (value) => {
    setValueId && setValueId(value?.id);
    setValue && setValue(value);
  };

  const onClickToAdd = (e) => {
    e.preventDefault();
    const currentPath = location.pathname + '?useLocalData=true';
    history.replace(currentPath);
    history.push(pathToCreate);
    return false;
  }

  const noOp = () => {
  }

  return (<Row className="align-items-center" style={{...rowStyle}}>
      <Col md={11} sm={sm || 10} xs={10} onClick={e => e.stopPropagation()}>
        <Form.Group>
          {showTitle && <Form.Label>{title}{required ? '*' : ''}</Form.Label>}
          <AsyncSelect
            styles={customStyles}
            isClearable
            isDisabled={disabled}
            required={required}
            cacheOptions
            defaultOptions
            value={value && Object.keys(value).length > 0 ? value : null}
            isMulti={isMulti}
            loadOptions={fetchData}
            onChange={_onChange}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            placeholder={placeholder}
          />
          {!disabled && (
            <input
              onClick={e => e.stopPropagation()}
              tabIndex={-1}
              autoComplete="off"
              style={{opacity: 0, height: 0}}
              onChange={noOp}
              value={value && Object.keys(value).length > 0 ? value : ''}
              required={required}
            />
          )}
        </Form.Group>
      </Col>
      <Col md={1} sm={2} xs={2} className="text-center pb-2">
        {showCreateButton && <Button title="Criar um novo item" size="sm" onClick={onClickToAdd}>
          <i className="fas fa-plus"> </i>
        </Button>}
      </Col>
    </Row>
  );
};

export default BaseSelectorReadonlyToInputV2;
