import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {Loading} from "../loading/Loading";

export const TableButton = ({
  to,
  title,
  classNameButton,
  variant = 'primary',
  textHelp,
  icon,
  redirectTo,
  loading = false,
  onClick,
  disabled,
  target,
  href,

}) => {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(loading);
  const _onClick = useCallback(async () => {

    if (onClick) {
      setIsLoading(true);
      await onClick();
      return;
    }

    if (href) {
      window.open(href, target)
      return;
    }
    const _to = to ? to : redirectTo;
    history.push(_to)

  }, [onClick, to, redirectTo, history, href, target]);

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  if (isLoading) {
    return <Loading isLoading={isLoading}/>
  }

  return (
    <Button disabled={disabled} className={classNameButton}
            size="sm" title={title || textHelp} variant={variant} onClick={_onClick}>
      {icon && <i className={icon}></i>}
      &nbsp;&nbsp;{title && title}
    </Button>
  )
}
