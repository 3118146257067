import MyModal from "../../modal/Modal";
import {Button, Col, ListGroup, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import {dayMonthYearAtTime} from "../../../shared/utils/dates";

const RevisionModal = ({
  show,
  id,
  handleClose,
  onSelected
}) => {

  const [revisions, setRevisions] = useState([]);

  const loadRevisions = async () => {
    const data = await HttpRequest.get(`optima-lgpd/v1/revisoes?objectId=${id}&size=20`);
    setRevisions(data.content);
  };

  useEffect(() => {
    if (show) {
      loadRevisions();
    }
  }, [id, show]);

  return (<MyModal closeButton handleClose={handleClose} show={show} title="Revisões" body={<RevisionModalBody onSelected={onSelected} revisoes={revisions}/>}/>);
}

const RevisionModalBody = ({revisoes, onSelected}) => {

  const onClick = (item) => {
    onSelected && onSelected(item)
  }
  return (<ListGroup>
    {revisoes.map((x) => {
      const {id, createdBy, createdAt, content} = x;
      return (<ListGroup.Item>
        <Row>
          <Col sm={8} md={8}>
            {dayMonthYearAtTime(createdAt)} - {createdBy}
          </Col>
          <Col sm={4} md={4} className="text-right">
            <Button size="sm" title="Usar está versão" onClick={() => onClick(x)}>
              Usar
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>)
    })}
  </ListGroup>);
}

export default RevisionModal;
