import React, {useEffect, useRef, useState} from "react";
import {Editor as TinyMCE} from "@tinymce/tinymce-react";
import {Button, Col, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import RevisionModal from "./revision/RevisionModal";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";

const Editor = ({
  onChange,
  title,
  initialValue,
  initProps, ...props
}) => {

  let {id} = useParams();

  const editorRef = useRef(null);
  const [_initialValue, setInitValue] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
      if (_initialValue === null) {
        setInitValue(initialValue);
      }
    }
    , [initialValue]);

  const _onChange = () => {
    const content = editorRef.current.getContent();
    newVersion(content);
    onChange && onChange(content);
  };

  const newVersion = async (content) => {
    if (!content || content === '') {
      return;
    }

    if (content.length < 20) {
      return;
    }

    const object = {objectId: id, content};
    try {
      await HttpRequest.post(`optima-lgpd/v1/revisoes`, object);
    } catch (e) {
      Notify.error('Falha ao criar versão');
    }
  };

  const handleClose = () => {
    setShowModal(false);
  }

  const onSelectedRevision = (item) => {
    setInitValue(item.content);
    handleClose();
  }

  return (
    <div className="d-flex flex-column">
      <RevisionModal show={showModal} id={id} handleClose={handleClose} onSelected={onSelectedRevision}/>
      <Row>
        <Col md={6}>
          <p className="text-bold">
            {title}
          </p>
        </Col>
        {id && <Col className="text-right mb-1">
          <Button title="Revisões" onClick={() => setShowModal(true)}>
            Revisões
          </Button>
        </Col>
        }
      </Row>

      <Col style={{zIndex: 0}}>
        <TinyMCE
          {...props}
          apiKey="mo4honc1iqr1ernzbam4q30da9abqvgz893uevvpptn5hcwn"
          initialValue={_initialValue}
          onChange={_onChange}
          tagName='div'
          textareaName='textareaName'
          onInit={(evt, editor) => editorRef.current = editor}
          init={{
            powerpaste_allow_local_images: true,
            image_advtab: true,
            height: window.screen.height - 150,
            width: '100%',
            menubar: false,
            valid_children: '+body[style]',
            plugins: ['print preview paste searchreplace code autosave save directionality visualblocks visualchars fullscreen image link media table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists imagetools textpattern noneditable charmap quickbars'],
            toolbar: 'code | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons |  preview save print | insertfile image template | ltr rtl',
            ...initProps
          }}
        />
      </Col>
    </div>
  );
};

export default Editor;
