import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";
import {Arquivo} from "../../components/arquivo/Arquivo";

const ProcedimentoListSchema = () => {

  const endpoint = 'optima-lgpd/v1/processos';

  return {
    title: 'Lista de Procedimentos',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.PROCEDIMENTO_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "nome"
        },
        {
          Header: "Arquivos",
          sortable: false,
          export: false,
          Cell: ({row}) => {
            return row.original.arquivos && row.original.arquivos.map(x => <li><Arquivo arquivo={x}/></li>)
          }
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.PROCEDIMENTO_FORM
        })
      ]
    },

  }
}

export default ProcedimentoListSchema;

