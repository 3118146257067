import SetorSelector from "../setor/component/SetorSelector";
import CursoSelector from "../cursos/component/PoliticaSelector";
import FuncionarioSelector from "../pessoas/component/FuncionarioSelector";

const TreinamentoFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Aplicar novo curso' : 'Editar curso aplicado';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/treinamentos/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/treinamentos/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Treinamento",
        type: "object",
        required: ["curso"],
        properties: {
          funcionarios: {
            type: 'array',
            title: "Pessoas",
            items: {
              type: "object",
              properties: {
                id: {type: "string", title: "Pessoa"},
              }
            }
          },
          curso: {
            type: 'object',
            properties: {
              id: {type: "string", title: "Curso"},
            }
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
        "funcionarios": {
          "ui:field": (props) => {
            return (<FuncionarioSelector isMulti={true} disabled={props.disabled}
                                         required={props.required}
                                         title={props.schema.title}
                                         value={props.formData} setValue={props.onChange}/>
            );
          }
        },
        "curso": {
          "ui:field": (props) => {
            return (<CursoSelector disabled={props.disabled} required={props.required}
                                   title={props.schema.title}
                                   value={props.formData} setValue={props.onChange}/>
            );
          }
        }
      }
    }
  }
}

export default TreinamentoFormSchema;

