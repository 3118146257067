import {Col, Row} from "react-bootstrap";
import {LinkButton} from "../../../components";

const ListHeader = ({headerConfig}) => {

  const {showNewButton = true, customButtons = null, linkNewUrl = '#', customButton} = headerConfig;

  return (
    <Row className="mb-3">
      <Col className="offset-sm-6 text-right" sm={{span: 6, offset: 6}}>
        {customButton && customButton()}
        {showNewButton && <LinkButton id="new_button" className="btn-primary" size="sm" to={linkNewUrl}>
          <i className="far fa-file-alt"></i>&nbsp;&nbsp;Novo</LinkButton>}
        {customButtons}
      </Col>
    </Row>);
}

export default ListHeader;
