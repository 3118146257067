import ArquivoSelector from "../arquivos/component/ArquivoSelector";

const CursoFormSchema = () => {

  return {

    getTitle(isNew) {
      return isNew ? 'Novo Curso' : 'Editar Curso';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/cursos/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/cursos/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Treinamento",
        type: "object",
        required: ["nome", "arquivos"],
        properties: {
          nome: {type: "string", title: "Nome do treinamento"},
          descricao: {
            type: "string", title: "Breve descrição do treinamento"
          },
          arquivos: {
            type: "array",
            title: "Arquivo de Capacitação",
            minItems: 1,
            items: {
              type: 'object',
              required: ["titulo", "arquivo"],
              properties: {
                titulo: {
                  type: 'string',
                  title: 'Título do conteudo'
                },
                arquivo: {
                  type: 'object',
                  properties: {
                    id: {type: "string"},
                  }
                }
              },
            }
          },
        }
      };
    },

    getUiSchema: () => {
      return {
        "nome": {
          "ui:autofocus": true,
          "ui:placeholder": "Nome do Curso"
        },
        "descricao": {
          "ui:autofocus": true,
          "ui:placeholder": "Descrição rápida do curso"
        },
        "arquivos": {
          "ui:options": {
            label: false
          },
          "items": {
            "arquivo": {
              "ui:field": (props) => {
                return (
                  <ArquivoSelector  {...props}
                                    title={props.schema.title}
                                    value={props.formData} setValue={props.onChange}
                  />
                );
              }
            }
          }
        }
      }
    }
  }
}

export default CursoFormSchema;
