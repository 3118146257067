import CargoListSchema from "../cargo/CargoListSchema";
import CompanyListSchema from "../company/CompanyListSchema";
import FuncionarioListSchema from "../pessoas/FuncionarioListSchema";
import PoliticaListSchema from "../politicas/PoliticaListSchema";
import UserListSchema from "../user/UserListSchema";
import ProcessoListSchema from "../processo/ProcessoListSchema";
import SetorListSchema from "../setor/SetorListSchema";
import ResponsavelEmpresaListSchema from "../responsavel-empresa/ResponsavelEmpresaListSchema";
import AlertaListSchema from "../alerta/AlertaListSchema";
import QuestionarioListSchema from "../questionario/QuestionarioListSchema";
import CampanhaListSchema from "../campanhas/CampanhaListSchema";
import PerguntasListSchema from "../perguntas/PerguntasListSchema";
import IncidenteListSchema from "../incidentes/IncidenteListSchema";
import CursoListSchema from "../cursos/CursoListSchema";
import TreinamentoListSchema from "../treinamento/TreinamentoListSchema";
import RelatorioListSchema from "../relatorio-page/RelatorioListSchema";
import CapacitacaoListSchema from "../certificados/CapacitacaoListSchema";
import TipoProcessoListSchema from "../tipo-processo/TipoProcessoListSchema";
import TipoArmazenamentoListSchema from "../tipo-armazenamento/TipoArmazenamentoListSchema";
import TipoSegurancaListSchema from "../seguranca/TipoSegurancaListSchema";
import SistemasListSchema from "../sistemas/SistemasListSchema";
import FornecedoresParceirosListSchema from "../fornecedores-parceiros/FornecedoresParceirosListSchema";
import ContratosVigentesListSchema from "../contratos-vigentes/ContratosVigentesListSchema";
import ArquivosListSchema from "../arquivos/ArquivosListSchema";
import GestaoRiscoListSchema from "../gestao-risco/GestaoRiscoListSchema";
import TipoPoliticaListSchema from "../tipo-politica/TipoPoliticaListSchema";
import CategoriaListSchema from "../categoria/CategoriaListSchema";
import ProcedimentoListSchema from "../procedimentos/ProcedimentoListSchema";
import AuditoriaListSchema from "../auditoria/AuditoriaListSchema";
import ContatoSuporteListSchema from "../mensagem/ContatoSuporteListSchema";
import DireitosDoTitularListSchema from "../direito-dos-titulares/DireitosDoTitularListSchema";
import BaseLegalListSchema from "../base-legal/BaseLegalListSchema";
import FonteDeDadosListSchema from "../fonte-de-dados/FonteDeDadosListSchema";
import DadosSensiveisListSchema from "../dados-pessoais/DadosSensiveisListSchema";
import ProrrogacaoListSchema from "../prorrogacao/ProrrogacaoListSchema";
import SolicitacaoDeInformacaoListSchema from "../lai/solicitacao/SolicitacaoDeInformacaoListSchema";
import ModeloProrrogacaoListSchema from "../modelo-prorrogacao/ModeloProrrogacaoListSchema";
import TemplateBaseLegalListSchema from "../templates/template-base-legal/TemplateBaseLegalListSchema";
import TemplateDireitosDoTitularListSchema from "../templates/template-direito-dos-titulares/TemplateDireitosDoTitularListSchema";
import TemplateFonteDeDadosListSchema from "../templates/template-fonte-de-dados/TemplateFonteDeDadosListSchema";

const entitiesSchemas = {
  'bases-legais': BaseLegalListSchema,
  'direitos-dos-titulares': DireitosDoTitularListSchema,
  'contato-suportes': ContatoSuporteListSchema,
  'auditorias': AuditoriaListSchema,
  'procedimentos': ProcedimentoListSchema,
  'categorias': CategoriaListSchema,
  'contratos-vigentes': ContratosVigentesListSchema,
  'fornecedores-parceiros': FornecedoresParceirosListSchema,
  'cursos': CursoListSchema,
  'entidades': CompanyListSchema,
  'users': UserListSchema,
  'policies': PoliticaListSchema,
  'funcionarios': FuncionarioListSchema,
  'cargos': CargoListSchema,
  'processos': ProcessoListSchema,
  'setores': SetorListSchema,
  'responsaveis-empresas': ResponsavelEmpresaListSchema,
  'alertas': AlertaListSchema,
  'questionarios': QuestionarioListSchema,
  'campanhas': CampanhaListSchema,
  'perguntas': PerguntasListSchema,
  'incidentes': IncidenteListSchema,
  'treinamentos': TreinamentoListSchema,
  'relatorios': RelatorioListSchema,
  'capacitacoes': CapacitacaoListSchema,
  'tipo-processos': TipoProcessoListSchema,
  'dados-sensiveis': DadosSensiveisListSchema,
  'tipo-armazenamento': TipoArmazenamentoListSchema,
  'tipo-seguranca': TipoSegurancaListSchema,
  'sistemas': SistemasListSchema,
  'arquivos': ArquivosListSchema,
  'gestao-risco': GestaoRiscoListSchema,
  'tipo-politica': TipoPoliticaListSchema,
  'fonte-de-dados': FonteDeDadosListSchema,
  'modelos-prorrogacoes': ModeloProrrogacaoListSchema,
  'prorrogacoes': ProcessoListSchema,
  'solicitacoes': SolicitacaoDeInformacaoListSchema,
  'templates-bases-legais': TemplateBaseLegalListSchema,
  'templates-direitos-dos-titulares': TemplateDireitosDoTitularListSchema,
  'templates-fonte-de-dados': TemplateFonteDeDadosListSchema
};

export const DefaultListSchema = {
  defaultSort: {id: 'createdAt', desc: true},
}

export const getEntityListSchema = (entityName) => {
  const result = entitiesSchemas[entityName];
  if (!result) {
    throw new Error('Entity not found: ' + entityName);
  }
  return result;
}
