import React, {useCallback, useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";

const CreatableReadonlyToInputV2 = ({
    required = false,
    setValue = null,
    value = null,
    title = null,
    disabled = false,
    getUrl = null,
    path = null,
    rowId = 'id',
    label = null,
    isMulti = false
  }) => {

    const [items, setItems] = useState(null);
    const getOptionLabel = useCallback((row) => row[label], [label])
    const getOptionValue = useCallback((row) => row[rowId], [rowId])

    const fetchData = (input, actionMeta) => {
      if (actionMeta.action === "menu-close" && input === "") {
        loadData(input);
      }
      if (actionMeta.action !== "input-blur" && actionMeta.action
        !== "menu-close") {
        loadData(input);
      }
    }

    const loadData = useCallback(async (input) => {
      let options = [];
      try {
        if (!getUrl) {
          console.warn('cannot call getUrl because is null')
          return;
        }
        const data = await HttpRequest.get(getUrl(input));
        options = data.content;
        const newOptions = options.map(
          option => ({...option, value: option.id, label: option.nome}))
        setItems(newOptions);
      } catch (e) {
        Notify.error('Falha ao buscar dados', e);
      }
    }, [getUrl])

    const _onChange = (value1, value2) => {
      if (value2?.action === "remove-value" || value2?.action === "pop-value") {
        if (isMulti && value) {
          return setValue && setValue(
            value.filter(x => x.id !== value2.removedValue.id));
        }
        return setValue && setValue(null);
      }
      if (value2?.action === "select-option") {
        if (isMulti) {
          return setValue && setValue(value1);
        }
        return setValue && setValue(value1);
      }
      if (value2?.action === "clear") {
        return setValue && setValue(null);
      }
    };

    const _onCreateOption = async (inputValue) => {
      const createOption = {nome: inputValue}
      try {
        const data = await HttpRequest.post(path, createOption);
        Notify.success(`Opção  ${inputValue} criada com sucesso`);
        const newCreated = {...data, label: data.nome, value: data.id}
        if (isMulti) {
          const newValue = Array.isArray(value) ? value.concat(newCreated)
            : [newCreated];
          return setValue && setValue(newValue);
        }
        setValue && setValue(newCreated);
      } catch (e) {
        Notify.error('Falha ao criar ' + title);
      }
    }

    useEffect(() => {
      loadData("")
    }, [loadData]);

    return (
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        <CreatableSelect
          isMulti={isMulti}
          isClearable
          isDisabled={disabled}
          required={required}
          formatCreateLabel={(inputValue) => `Criar opção: ${inputValue} `}
          getNewOptionData={(inputValue, optionLabel) => {
            const data = {'__isNew__': true};
            data[label] = optionLabel;
            data[rowId] = inputValue;
            return data;
          }}
          value={value}
          onCreateOption={_onCreateOption}
          onChange={_onChange}
          onInputChange={fetchData}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          options={items}
          placeholder="-- selecione ou digite para buscar/criar --"
        />
      </Form.Group>
    );
  }
;

export default CreatableReadonlyToInputV2;
