import Editor from "../../components/editor/Editor";
import ModeloProrrogacaoSelector from "../modelo-prorrogacao/component/ModeloProrrogacaoSelector";
import moment from "moment";

const ProrrogacaoFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Nova Prorrogação' : 'Editar Prorrogação';
    },

    getUrlFindById(id) {
      return `optima-lgpd/v1/solicitacoes/${id}`;
    },

    getSaveUrl(id) {
      return `optima-lgpd/v1/solicitacoes/${id || ''}/prorrogar`;
    },

    formatObjectToGet(formData) {
      let prazo = {
        prazo: moment(formData.prazo).add(10, 'days').format('YYYY-MM-DD')
      }
      return prazo;
    },

    formatObjectToSave(formData) {
      return formData;
    },

    getSchema() {
      return {
        title: "Prorrogação",
        type: "object",
        required: [],
        properties: {
          prazo: {
            type: "string",
            format: "date",
            title: "Novo prazo"
          },
          prorrogationModel: {
            type: "object",
            properties: {
              id: {
                type: 'string',
              },
              name: {
                type: 'string',
              }
            }
          },
          content: {
            type: "string",
            title: 'Justificativa'
          }
        },
      };
    },

    getUiSchema: () => {
      return {
        "prazo": {
          "ui:readonly": true
        },
        "content": {
          "ui:field": (props) => {
            return <Editor
              {...props}
              title={props.schema.title}
              initialValue={props.formData}
              onChange={props.onChange}
            />
          }
        },
        "prorrogationModel": {
          "ui:field": (props) => {
            return (<ModeloProrrogacaoSelector
                setValue={(e) => {
                  props?.formContext?.putCustomField && props?.formContext?.putCustomField({prorrogationModel: e, content: e?.mensagem});
                }}
                title={props.title}
                value={props.formData}
              />
            );
          }
        }
      }
    }
  }
}

export default ProrrogacaoFormSchema;
