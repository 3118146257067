import React from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function HorizontalBarChart({labels, datasets = [], callbackToEditYAxesLabels, chartTitle}) {

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: chartTitle,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: callbackToEditYAxesLabels
        }
      }
    }
  };

  const data = {
    labels,
    datasets,
  };
  return <Bar options={options} data={data}/>;
}

export default HorizontalBarChart
