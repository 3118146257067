import {NavDropdown} from "react-bootstrap";
import {useState} from "react";
import {Loading} from "../loading/Loading";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";

export const NavItemCompany = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      const data = await HttpRequest.get('optima-auth/v1/user-company-accesses');
      setData(data);
      setIsLoading(false);
    } catch (e) {
      Notify.error("Falha ao carregar entidades");
    }
  };

  const changeCompany = async (target) => {
    const {company} = target;
    console.log('company change to targget', target);
    try {
      await HttpRequest.post(`optima-auth/v1/login/companies/${company.id}`, {})
      window.location.reload();
    } catch (e) {
      Notify.error('Falha ao trocar de entidade', e);
    }
  }

  let boxItems = document.getElementsByClassName('dropdown-menu show')[0]
  if (boxItems) {
    boxItems.style.overflow = 'hidden scroll'
    boxItems.style.height = '50vh'
  }

  return (<NavDropdown onClick={loadData}
                       title={<i title="Trocar de entidade" className="fa fa-building"/>}>
    <Loading isLoading={isLoading}/>

    {data.map(x => <NavDropdown.Item key={x.id} onClick={() => changeCompany(x)}>{x.company.name}</NavDropdown.Item>)}
    {/*<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
    {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
    {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
    {/*<NavDropdown.Divider/>*/}
    {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
  </NavDropdown>)
}
