import axios from 'axios';

export default class HttpRequest {

  static  _basePath = '/api/';

  static post(url, data) {
    if (data && !data.company) {
      data.company = {id: 'web'}
    }
    return axios.post(HttpRequest._basePath + url, data)
    .then((data) => {
      return data.data;
    });
  }

  static put(url, data) {
    if (data && !data.company) {
      data.company = {id: 'web'}
    }
    return axios.put(HttpRequest._basePath + url, data)
    .then(
      (data) => {
        return data.data;
      });
  }

  static delete(url) {
    return axios.delete(HttpRequest._basePath + url)
    .then((data, error) => {
      if (error) {
        return error;
      }
      return data.data;
    })
  }

  static get(url) {
    return axios.get(HttpRequest._basePath + url)
    .then((data, error) => {
      if (error) {
        return error;
      }
      return data.data;
    })
  }
}

/*
export const saveFileContent = async (fileId, content) => {
    const response = await axios.post(HttpRequest._basePath + url,
        {content: content},
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
    );
    console.log('Response', response);
  }*/



