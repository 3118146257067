import {useSelector} from "react-redux";
import React, {useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {APP_ROUTES} from "../../shared/routes/Routes";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const anyMath = (userRoles, pathRoles) => {
  if (!pathRoles) {
    return true;
  }

  if (typeof pathRoles === 'string') {
    return userRoles.indexOf(pathRoles) >= 0;
  }

  for (let pathRole of pathRoles) {
    if (userRoles.indexOf(pathRole) >= 0) {
      return true;
    }
  }

  return false;
}

const mountSwitch = (roles, _routes = []) => {
  return _routes.filter(x => anyMath(roles, x.roles)).map(({routes, path, component}) => {
    return ([
      component && <Route key={path} path={path} exact={true} render={props => (React.createElement(component, props))}/>,
      mountSwitch(roles, routes)
    ])
  });
}
const Routes = () => {

  const [roles, setRoles] = useState(null);
  const currentUser = useSelector(({authState}) => authState.currentUser);

  useState(() => {
    if (currentUser) {
      const {authorities} = currentUser;
      setRoles(authorities.map(x => x.authority));
    }
  }, [currentUser])

  if (!roles) {
    return null;
  }

  return <Switch>

    <Route exact path="/">
      <Redirect to={ROUTE_PATH.HOME}/>
    </Route>

    {mountSwitch(roles, APP_ROUTES)}
  </Switch>

};
export default Routes;
