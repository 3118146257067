import {SmallBox} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";

const PoliticaCount = () => {

  const [total, setTotal] = useState('-');

  const loadTotal = async () => {
    try {
      const result = await HttpRequest.get('optima-lgpd/v1/politicas/count');
      setTotal(result.total);
    } catch (e) {
      Notify.error('Falha ao contar políticas', e);
    }
  }

  useEffect(() => {
    loadTotal();
  }, [])

  return <SmallBox
    type="secondary"
    icon="fa fa-tasks"
    count={total} title="Políticas de Dados Disponíveis"
    titlebox={"Políticas"}
    navigateTo={ROUTE_PATH.POLICY_LIST}/>

}

export default PoliticaCount;
