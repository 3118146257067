const DUPLICATE_DATA_KEY = 'duplicate-data';

export const saveFormData = (entityName, data) => {

  saveData(entityName + '-form-data', data);
}

export const saveDuplicateData = (data) => {
  saveData(DUPLICATE_DATA_KEY, data);
}

export const saveData = (entityName, data) => {
  console.log('save-data', entityName, data)

  window.localStorage[entityName] = JSON.stringify(data);
}

export const getDuplicateData = (defaultValue) => {
  let value = window.localStorage[DUPLICATE_DATA_KEY];
  if (value) {
    delete window.localStorage[DUPLICATE_DATA_KEY];
    return JSON.parse(value);
  }
  return defaultValue;
}

export const getFormData = (entityName, defaultValue) => {
  let value = window.localStorage[entityName + '-form-data'];
  if (value) {
    return JSON.parse(value);
  }
  return defaultValue;
}
